import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import cx from "classnames";
import React from "react";

function MultiItemCarousel(
  {
    elems,
    defaultSelectedIdx,
    onChange,
    renderElem,
    className,
    sx,
    ...restProps
  },
  ref
) {
  const [selectedIdx, setSelectedIdx] = React.useState(defaultSelectedIdx ?? 0);
  const selectedElemRef = React.useRef(null);

  const setSelectedIdxWrapper = (newIdx) => {
    setSelectedIdx(newIdx);
    onChange && onChange(newIdx);
  };

  React.useEffect(() => {
    if (!selectedElemRef.current) return;
    selectedElemRef.current.scrollIntoView({
      behavior: "smooth",
      inline: "center",
    });
  }, [selectedIdx]);

  return (
    <Box
      ref={ref}
      className={cx("multi-item-carousel", className)}
      sx={{
        display: "flex",
        "& .multi-item-carousel-browse-button": {
          flexShrink: 0,
          color: "grey.light",
          padding: "0.5rem",
          "&:disabled": { color: "grey.main" },
        },
        ...sx,
      }}
      {...restProps}
    >
      <IconButton
        disableRipple
        className="multi-item-carousel-browse-button prev-button"
        onClick={() => setSelectedIdxWrapper(selectedIdx - 1)}
        disabled={selectedIdx === 0}
      >
        <ChevronLeftIcon />
      </IconButton>

      <Box
        className="multi-item-carousel-elems"
        sx={{
          flexGrow: 1,
          overflow: "hidden",
          display: "flex",
          alignItems: "flex-end",
          gap: "0.125rem",
        }}
      >
        {elems.map((elem, idx) =>
          renderElem(
            elem,
            {
              className: idx === selectedIdx ? "selected" : "",
              onClick: () => setSelectedIdxWrapper(idx),
            },
            idx === selectedIdx ? selectedElemRef : null
          )
        )}
      </Box>

      <IconButton
        disableRipple
        className="multi-item-carousel-browse-button next-button"
        onClick={() => setSelectedIdxWrapper(selectedIdx + 1)}
        disabled={selectedIdx === elems.length - 1}
      >
        <ChevronRightIcon />
      </IconButton>
    </Box>
  );
}

export default React.forwardRef(MultiItemCarousel);
