import React from "react";
import { useSnackbar } from "notistack";

import { setWeddingCoverPhoto } from "@@services/fotobot-api.service";

export default React.forwardRef(({ weddingId, setWedding }, ref) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <input
      id="wedding-cover-photo-input"
      type="file"
      accept="image/jpeg,image/png"
      style={{ display: "none" }}
      onChange={handleWeddingCoverPhotoInputChange}
      ref={ref}
    />
  );

  async function handleWeddingCoverPhotoInputChange(e) {
    const file = e.target.files[0];
    if (!file) return;

    try {
      const { data: newCoverPhotoUrl } = await setWeddingCoverPhoto(
        weddingId,
        file
      );
      setWedding((w) => ({ ...w, coverPhotoUrl: newCoverPhotoUrl }));
      enqueueSnackbar(`Cover photo changed`, { variant: "success" });
    } catch (e) {
      enqueueSnackbar(
        e.response?.data?.error ??
          `Sorry, there was an error updating the cover photo`,
        { variant: "error" }
      );
      throw e;
    } finally {
      // ensures that the change event of the file input is triggered
      //   even if the user selects the same file twice in a row, which
      //   may happen if there was an api error, or if the user forgot
      //   that they just set the cover photo
      e.target.value = "";
    }
  }
});
