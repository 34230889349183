import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import cx from "classnames";
import { Set as ImmutableSet } from "immutable";
import _ from "lodash";
import React from "react";

import { secondsTommss } from "@@utils";

export default function WeddingVideoPlayerContainer({
  video,
  videoRef,
  className,
  sx,
  ...restProps
}) {
  const [isPaused, setIsPaused] = React.useState(true);
  const [isMuted, setIsMuted] = React.useState(false);
  const [isFullscreen, setIsFullscreen] = React.useState(false);
  const [curTimeSeconds, setCurTimeSeconds] = React.useState(0);
  const [selectedFaceIds, setSelectedFaceIds] = React.useState(ImmutableSet());

  const seekbarOverlayRef = React.useRef(null);
  const timeUpdateIntervalRef = React.useRef(null);

  React.useEffect(() => {
    const videoNode = videoRef.current;

    videoNode.addEventListener("play", playPauseChangeHandler);
    videoNode.addEventListener("pause", playPauseChangeHandler);
    videoNode.addEventListener("timeupdate", currentTimeChangeHandler);

    videoNode.parentNode.addEventListener(
      "fullscreenchange",
      fullscreenChangeHandler
    );

    timeUpdateIntervalRef.current = setInterval(currentTimeChangeHandler, 30);

    return () => {
      videoNode.removeEventListener("play", playPauseChangeHandler);
      videoNode.removeEventListener("pause", playPauseChangeHandler);
      videoNode.removeEventListener("timeupdate", currentTimeChangeHandler);

      videoNode.parentNode.removeEventListener(
        "fullscreenchange",
        fullscreenChangeHandler
      );

      clearInterval(timeUpdateIntervalRef.current);
    };

    function playPauseChangeHandler(e) {
      if (e.type === "play") setIsPaused(false);
      if (e.type === "pause") setIsPaused(true);
    }

    function currentTimeChangeHandler() {
      setCurTimeSeconds(videoNode.currentTime);
    }

    function fullscreenChangeHandler() {
      setIsFullscreen(!!document.fullscreenElement);
    }
  }, [videoRef]);

  React.useEffect(() => {
    setIsPaused(true);

    // commenting out so we retain the value of isMuted
    // setIsMuted((isMuted) => true);

    setIsFullscreen(false);

    setCurTimeSeconds(0);
    setSelectedFaceIds(ImmutableSet());
  }, [video]);

  if (!video) return null;

  // WARN: a video may not have had the fac-reg workflow completed yet,
  //   in which case, video.otherData.facesRecognized will be undefined
  const { durationSeconds } = video.otherData;
  const facialRecognitionCompleted = !!video.otherData.facesRecognized;
  const nFacesRecognized = video.otherData.facesRecognized?.length ?? 0;
  const facesRecognizedByFaceId = _.keyBy(
    video.otherData.facesRecognized,
    "faceId"
  );
  const timeRangesToHighlightInSeekbar = _.flatten(
    Array.from(selectedFaceIds)
      .map((fId) => facesRecognizedByFaceId[fId]?.timeRanges)
      .filter((x) => x)
  )
    .sort((a, b) => a[0] - b[0] || a[1] - b[1])
    .reduce((acc, cur) => {
      if (acc.length === 0) return acc.concat([cur]);

      const [curStart, curEnd] = cur;
      const [prevStart, prevEnd] = acc.at(-1);
      if (curStart > prevEnd) return acc.concat([cur]);

      acc.splice(-1, 1, [prevStart, Math.max(curEnd, prevEnd)]);
      return acc;
    }, []);

  return (
    <Box
      className={cx("wedding-video-player-container", className)}
      sx={{ backgroundColor: "grey.extralight", ...sx }}
      {...restProps}
    >
      <Box
        className="video-player"
        {...(isFullscreen
          ? {
              sx: {
                height: "100vw",
                backgroundColor: "grey.extradark",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              },
            }
          : {})}
      >
        <Box
          ref={videoRef}
          component="video"
          preload="metadata"
          poster={video.otherData.thumbnailUrl}
          src={video.url}
          sx={{
            width: "100%",
            height: isFullscreen
              ? "auto"
              : { xs: "14rem", sm: "20rem", md: "24rem" },
            objectFit: "contain",
            backgroundColor: "grey.extradark",
          }}
          onClick={playPauseToggle}
        />

        <Box
          className="video-controls-container"
          sx={{
            marginTop: "-0.5rem",
            color: "grey.dark",
            ...(isFullscreen
              ? {
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  color: "grey.extralight",
                }
              : {}),
          }}
        >
          <Box
            className="seekbar"
            sx={{
              position: "relative",
              flexGrow: 1,
              height: "0.5rem",
              marginBottom: "0.5rem",
              backgroundColor: "grey.light",
              cursor: "pointer",
            }}
            onClick={handleSeekbarClick}
          >
            <Box
              className="overlay seekbar-curtime-overlay"
              ref={seekbarOverlayRef}
              sx={{
                bottom: 0,
                width: `${(curTimeSeconds / durationSeconds) * 100}%`,
                backgroundColor: "red",
              }}
            />

            {timeRangesToHighlightInSeekbar.map(
              ([startSeconds, endSeconds]) => {
                const left = `${(startSeconds / durationSeconds) * 100}%`;
                const width = `${
                  ((endSeconds - startSeconds) / durationSeconds) * 100
                }%`;
                return (
                  <Box
                    key={`${startSeconds}-${endSeconds}`}
                    className="overlay seekbar-person-present-overlay"
                    sx={{
                      top: "50%",
                      left,
                      width,
                      height: "50%",
                      backgroundColor: "green",
                      opacity: 0.5,
                    }}
                  />
                );
              }
            )}
          </Box>

          <Box
            className="controls"
            sx={{
              margin: "0 0.5rem",
              paddingBottom: "0.5rem",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              "& .control": { color: "inherit", padding: 0 },
            }}
          >
            <IconButton
              className="control"
              disableRipple
              onClick={playPauseToggle}
            >
              {isPaused ? <PlayArrowIcon /> : <PauseIcon />}
            </IconButton>

            <IconButton
              className="control"
              disableRipple
              onClick={muteUnmuteToggle}
            >
              {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
            </IconButton>

            <Typography variant="caption">
              {secondsTommss(curTimeSeconds)} / {secondsTommss(durationSeconds)}
            </Typography>

            <IconButton
              className="control"
              disableRipple
              sx={{ marginLeft: "auto" }}
              onClick={fullscreenToggle}
            >
              {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Accordion
        className="people-in-video"
        square
        disableGutters
        sx={{
          backgroundColor: "transparent",
          borderTop: 0,
          boxShadow: "none",
          "& .MuiAccordionSummary-content": { margin: 0 },
          "& .MuiAccordionDetails-root": { padding: "0 1rem 1rem 1rem" },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            variant="caption"
            component="h1"
            sx={{ margin: "0.5rem 0", whiteSpace: "pre" }}
          >
            {!facialRecognitionCompleted
              ? "Facial recognition in progress ...\nPlease check back in a while"
              : nFacesRecognized === 0
              ? "Found no people in video"
              : nFacesRecognized === 1
              ? "Found 1 person in video"
              : `Found ${nFacesRecognized} people in video`}
          </Typography>
        </AccordionSummary>

        <AccordionDetails
          className="people-in-video-face-thumbs"
          sx={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}
        >
          {video.otherData.facesRecognized?.map(({ faceId, faceThumbUrl }) => (
            <Box
              key={faceId}
              sx={{
                position: "relative",
                borderRadius: "50%",
                overflow: "hidden",
                cursor: "pointer",
              }}
              onClick={() =>
                setSelectedFaceIds(
                  selectedFaceIds.has(faceId)
                    ? selectedFaceIds.delete(faceId)
                    : selectedFaceIds.add(faceId)
                )
              }
            >
              <Avatar
                key={faceId}
                src={faceThumbUrl}
                sx={{ height: "4rem", width: "4rem" }}
              />
              {selectedFaceIds.has(faceId) ? (
                <Box
                  className="abs-cover-parent flex-center-content"
                  sx={{ backgroundColor: "#8a8a8a6a", color: "white" }}
                >
                  <CheckIcon />
                </Box>
              ) : null}
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );

  function playPauseToggle() {
    videoRef.current.paused
      ? videoRef.current.play()
      : videoRef.current.pause();
  }

  function muteUnmuteToggle() {
    const newVal = !videoRef.current.muted;
    videoRef.current.muted = newVal;
    setIsMuted(newVal);
  }

  async function fullscreenToggle() {
    if (videoRef.current.parentNode === document.fullscreenElement) {
      await document.exitFullscreen();
    } else {
      await videoRef.current.parentNode.requestFullscreen();
    }
  }

  function handleSeekbarClick(e) {
    const { left, width } = e.currentTarget.getBoundingClientRect();
    videoRef.current.currentTime =
      ((e.clientX - left) / width) * durationSeconds;
  }
}
