import React from "react";

import { WeddingMediaUploadStatsDispatchContext } from "@@contexts/WeddingMediaUploadStatsContextsWrapper";
import { addWeddingMedia } from "@@services/wedding-media.service";

function WeddingMediaUploadInput({ weddingId }, ref) {
  const uploadStatsDispatch = React.useContext(
    WeddingMediaUploadStatsDispatchContext
  );

  return (
    <input
      id="wedding-media-upload-input"
      type="file"
      accept="image/*,video/*"
      multiple
      style={{ display: "none" }}
      onChange={handleUploadWeddingMediaInputChange}
      ref={ref}
    />
  );

  async function handleUploadWeddingMediaInputChange(e) {
    const files = Array.from(e.target.files);
    e.target.value = "";

    if (files.length === 0) return;
    await addWeddingMedia(weddingId, files, {}, uploadStatsDispatch);
  }
}

export default React.forwardRef(WeddingMediaUploadInput);
