import React from "react";
import { useSnackbar } from "notistack";

import SentryContext from "@@contexts/SentryContext";
import { loginWithOTP } from "@@services/auth.service";
import { getUser } from "@@services/fotobot-api.service";

export const LoggedInUserInitialFetchDoneContext = React.createContext(false);
LoggedInUserInitialFetchDoneContext.displayName =
  "LoggedInUserInitialFetchDoneContext";

export const LoggedInUserContext = React.createContext(null);
LoggedInUserContext.displayName = "LoggedInUserContext";

export const SetLoggedInUserContext = React.createContext(() => {});
SetLoggedInUserContext.displayName = "SetLoggedInUserContext";

export default function LoggedInUserContextsWrapper({ children }) {
  const Sentry = React.useContext(SentryContext);
  const { enqueueSnackbar } = useSnackbar();

  const [loggedInUser, setLoggedInUser] = React.useState(null);
  const [initialFetchComplete, setInitialFetchComplete] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      let user;

      // if there's a loginToken in the url on initial page load,
      //   i.e. the user got here by clicking a magic link,
      //   then we should use that to log the user in
      const { searchParams } = new URL(window.location.href);
      const b64LoginToken = searchParams.get("loginToken");
      if (b64LoginToken) {
        try {
          const { phoneNumber, verificationCode, name } = JSON.parse(
            atob(b64LoginToken)
          );
          ({ data: user } = await loginWithOTP({
            phoneNumber,
            verificationCode,
            name,
          }));

          // analytics
          window.dataLayer.push({
            event: "login",
            login_method: "magic_link",
          });
        } catch (e) {
          if (e.response?.status === 400) {
            // 400 occurs if user came to the site with an expired magic-link
            // do nothing
          } else {
            throw e;
          }
        }
      }

      if (!user) {
        // either there was no loginToken in the url, or the loginToken
        //   was expired/invalid
        // we'll act as if there was no loginToken in the url, meaning if the
        //   current user is already logged in, they'll stay that way
        try {
          ({ data: user } = await getUser("me"));
        } catch (e) {
          if (e.response?.status === 400) {
            // 400 occurs (in staging only) if a legacy token w. domain
            //   set to 'fotobot.app' was set on logging in to the prod. site
            // when this happens, the user should delete cookies and try again
            enqueueSnackbar(`Please delete cookies and try again`, {
              variant: "error",
            });
          } else if ([401, 404].includes(e.response?.status)) {
            // 401 occurs if cookie expired
            // 404 occurs if database was reset, and user record is
            //   no longer in db (happens in dev/staging only)
            // in all these cases, we want the app to behave as if the user has
            //   been logged out, so nothing needs to be done in this catch clause
          } else {
            throw e;
          }
        }
      }

      // NOTE: reversing the order of the two setState calls below
      //   causes a race condition bug on WeddingPage, where the
      //   effect responsible for retrieving wedding data runs
      //   twice: once after initialFetch is complete but loggedInUser
      //   has not been set (an API call), and then again once
      //   loggedInUser has been set (another API call)
      // If the first API call completes *after* the second API call,
      //   weird things happen, like the user seeing the 'guest'
      //   view of the wedding, even if they are a client
      setLoggedInUser(user);
      setInitialFetchComplete(true);

      // show user a notification if loginToken is expired/invalid, and they are
      //   not logged-in
      if (!user && b64LoginToken) {
        enqueueSnackbar("Your magic link has expired", { variant: "info" });
      }
    })();
  }, [enqueueSnackbar]);

  React.useEffect(() => {
    if (!initialFetchComplete) return;

    window.dataLayer.push({
      event: "userData",
      user_id: loggedInUser ? String(loggedInUser.id) : null,
    });

    Sentry.setUser(loggedInUser ? { id: loggedInUser.id } : null);

    // NOTE: can show the user a toast here if desired
  }, [loggedInUser, initialFetchComplete, Sentry]);

  return (
    <LoggedInUserInitialFetchDoneContext.Provider value={initialFetchComplete}>
      <LoggedInUserContext.Provider value={loggedInUser}>
        <SetLoggedInUserContext.Provider value={setLoggedInUser}>
          {children}
        </SetLoggedInUserContext.Provider>
      </LoggedInUserContext.Provider>
    </LoggedInUserInitialFetchDoneContext.Provider>
  );
}
