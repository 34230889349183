import Box from "@mui/material/Box";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import cx from "classnames";
import React from "react";
import { useSnackbar } from "notistack";

import EditableTypography from "@@components/common/EditableTypography";
import { LoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";
import WeddingMediaDispatchContext from "@@contexts/WeddingMediaDispatchContext";
import {
  WeddingMediaSelectedFiltersContext,
  WeddingMediaSelectedFiltersDispatchContext,
} from "@@contexts/WeddingMediaFiltersContexts";
import { updateFaceLabel } from "@@services/wedding-media.service";
import { getWidthFromResizedImageUrl } from "@@utils";
import { countFilters } from "@@utils/mediaFiltersUtils";
import { extractFaceThumbnailAsDataUrl } from "@@utils/webApiUtils";

export default function FaceBoundingBox({
  data,
  imgRef,
  weddingId,
  media,
  PopperProps,
  sx,
  className,
  ...restProps
}) {
  const { faceId, label, labeler, recognizedUser, position } = data;
  const { sx: sxPopperProps, ...restPopperProps } = PopperProps ?? {};

  const { enqueueSnackbar } = useSnackbar();
  const loggedInUser = React.useContext(LoggedInUserContext);
  const mediaDispatch = React.useContext(WeddingMediaDispatchContext);
  const selectedMediaFilters = React.useContext(
    WeddingMediaSelectedFiltersContext
  );
  const selectedMediaFiltersDispatch = React.useContext(
    WeddingMediaSelectedFiltersDispatchContext
  );
  const bbRef = React.useRef(null);
  const [showMenu, setShowMenu] = React.useState(false);

  const nFiltersApplied = countFilters(selectedMediaFilters);
  const alreadyIncludedInFilters = selectedMediaFilters.featuring.data.some(
    ({ id, faceId: fId }) =>
      (id && id === recognizedUser?.id) || (fId && fId === faceId)
  );

  return (
    <Box
      ref={bbRef}
      className={cx("bounding-box", className)}
      onClick={() => setShowMenu(() => true)}
      sx={{
        border: "1px solid #cacacaaa",
        ":hover": {
          border: "1px solid #cacaca",
          cursor: "pointer",
        },
        ...sx,
      }}
      {...restProps}
    >
      {showMenu ? (
        <ClickAwayListener
          onClickAway={() => setShowMenu(false)}
          // not specifying these causes the e.stopPropagation() call in the
          //   onClick handler of WeddingPhotoCarouselElement to prevent
          //   the popper from closing on clickaway
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
        >
          {/*
            forced to use popper here since we want the menu to appear above the
              left/right controls of the carousel, which is in a different z-stacking
              context
          */}
          <Box
            component={Popper}
            anchorEl={bbRef.current}
            open={showMenu}
            sx={{ ...sxPopperProps }}
            {...restPopperProps}
          >
            <MenuList
              sx={{
                width: "8rem",
                backgroundColor: "#3e3e3ebb",
                borderRadius: "0.5rem",
                color: "white",
                overflow: "hidden",
                padding: 0,
                "& .MuiMenuItem-root": {
                  minHeight: "auto",
                  fontSize: { xs: "0.7rem", sm: "0.8rem" },
                  padding: "0.5rem 0.75rem",
                  ":hover": {
                    backgroundColor: "#00000088",
                  },
                },
              }}
            >
              <MenuItem
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                  gap: "0.25rem",
                }}
                onClickCapture={(e) => {
                  if (loggedInUser) return;

                  e.stopPropagation();
                  enqueueSnackbar(
                    `Sorry, you must log in (or sign up) to add labels`,
                    { preventDuplicate: true, variant: "info" }
                  );
                }}
              >
                <EditableTypography
                  onChange={handleChangeFaceLabel}
                  sx={{ width: "100%", "& button": { color: "white" } }}
                  TextFieldProps={{
                    InputProps: {
                      sx: {
                        height: "1.25rem",
                        color: "white",
                        fontSize: { xs: "0.7rem", sm: "0.8rem" },
                      },
                    },
                  }}
                  TypographyProps={{
                    sx: { fontSize: { xs: "0.7rem", sm: "0.8rem" } },
                  }}
                  value={
                    labeler && labeler?.id === loggedInUser?.id
                      ? label
                      : recognizedUser?.firstName ?? label
                  }
                  placeholder="<Add name>"
                />

                {/* only show this message if displaying a label added by
                  someone else */}
                <Typography variant="caption" sx={{ fontSize: "0.7rem" }}>
                  {labeler && labeler.id !== loggedInUser?.id && !recognizedUser
                    ? `(labeled by ${labeler.firstName})`
                    : ""}
                </Typography>
              </MenuItem>

              <MenuItem
                sx={{ whiteSpace: "normal" }}
                onClick={handleFaceSearchButtonClick}
                disabled={alreadyIncludedInFilters}
              >
                {alreadyIncludedInFilters
                  ? "Already in selected filters"
                  : nFiltersApplied > 0
                  ? "Also find photos of this person"
                  : "Find photos of this person"}
              </MenuItem>
            </MenuList>
          </Box>
        </ClickAwayListener>
      ) : null}
    </Box>
  );

  async function handleChangeFaceLabel({ value: newLabel }) {
    try {
      const { data } = await updateFaceLabel(weddingId, media.id, {
        faceId,
        label: newLabel,
        faceThumbDataUrl: extractFaceThumb(),
        applyLabelToSimilarFaces: { overwriteExistingLabels: true },
      });
      mediaDispatch({
        type: "UPDATE_FACE_LABELS",
        data: { mediaAndFaceIdsToUpdate: data, newLabel },
      });
      enqueueSnackbar(
        `Success. We've added this label to other photos of ${newLabel} as well.`,
        { variant: "success" }
      );
    } catch (e) {
      const errMsg = e.response?.data?.error;
      enqueueSnackbar(
        `Failed to update face label` + (errMsg ? `: ${errMsg}` : ""),
        { variant: "error" }
      );
      throw e;
    }
  }

  async function handleFaceSearchButtonClick() {
    const dataUrl = extractFaceThumb();
    selectedMediaFiltersDispatch({
      type: "ADD_TO_FEATURING_FILTER",
      data: {
        faceId,
        label: label || recognizedUser?.firstName,
        faceThumbUrl: dataUrl,
      },
    });

    enqueueSnackbar(
      `Added this person to current filter. Don't forget to apply it from the filters pane!`,
      { variant: "info" }
    );

    //analytics
    window.dataLayer.push({
      event: "person_filter_applied",
      user_action: "select-face-from-photo-in-gallery",
    });
  }

  function extractFaceThumb() {
    // The drawImage() method uses the source element's intrinsic size in
    //   CSS pixels when drawing.
    // See https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage#understanding_source_element_size
    let intrinsicWidth = getWidthFromResizedImageUrl(imgRef.current.currentSrc);
    if (Number.isNaN(intrinsicWidth)) {
      intrinsicWidth = media.otherData.widthPixels;
    }
    const scalingFactor = intrinsicWidth / imgRef.current.width;
    return extractFaceThumbnailAsDataUrl(imgRef.current, {
      left: position.left * scalingFactor,
      top: position.top * scalingFactor,
      width: position.width * scalingFactor,
      height: position.height * scalingFactor,
    });
  }
}
