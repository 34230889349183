import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import SearchIcon from "@mui/icons-material/Search";
import _ from "lodash";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { Link } from "react-router-dom";

import WeddingCardComponent from "./WeddingCard";
import AppBar from "@@components/common/AppBar/AppBar";
import BottomNavigation from "@@components/common/BottomNavigation";
import { PRIVILEGED_WEDDING_ROLES } from "@@config";
import ResponsiveGrid from "@@components/common/ResponsiveGrid";
import SplashContent from "@@components/common/SplashContent";
import { LoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";
import {
  TopLevelModalsOpenStateSettersContext,
  CreateWeddingModalOpenStateContext,
  FindWeddingModalOpenStateContext,
} from "@@contexts/TopLevelModalsWrapper";
import useWeddingsList from "@@hooks/useWeddingsList";

export default function WeddingsListPage({ sx, ...restProps }) {
  const loggedInUser = React.useContext(LoggedInUserContext);
  const { setCreateWeddingModalOpen, setFindWeddingModalOpen } =
    React.useContext(TopLevelModalsOpenStateSettersContext);
  const createWeddingModalOpen = React.useContext(
    CreateWeddingModalOpenStateContext
  );
  const findWeddingModalOpen = React.useContext(
    FindWeddingModalOpenStateContext
  );
  const theme = useTheme();
  const viewportIsMdPlus = useMediaQuery(theme.breakpoints.up("md"));
  const { weddings, loading: loadingWeddings } = useWeddingsList();

  const [weddingsCreatedOrHostedByCurUser, otherWeddings] = _.partition(
    weddings,
    (w) =>
      w.creatingUserId === loggedInUser.id ||
      PRIVILEGED_WEDDING_ROLES.includes(w.role)
  );

  const availableActions = [
    {
      icon: <AutoFixHighIcon />,
      label: "Create",
      onClick: () => setCreateWeddingModalOpen(true),
    },
    {
      icon: <SearchIcon />,
      label: "Find",
      onClick: () => setFindWeddingModalOpen(true),
    },
  ];

  return (
    <Box
      className="page-container"
      sx={{
        display: "flex",
        flexDirection: "column",
        "& .weddings-list-page-content": {
          // the hand-crafted margins below are to ensure the content
          //   is properly aligned / centered on both mobile and laptop
          //   screens
          // on mobile screens, there's an app header occuping 5rem height,
          //   and a bot nav bar of height 3.5rem
          // on laptop screens, there's a sidebar of 4rem width
          marginTop: { xs: 0, md: "1rem" },
          marginBottom: {
            xs: "5rem",
            md: 0,
          },
          marginLeft: { xs: 0, sm: "1rem", md: "5rem" },
          marginRight: { xs: 0, sm: "1rem" },
        },
        ...sx,
      }}
      {...restProps}
    >
      <AppBar actions={viewportIsMdPlus ? availableActions : []} />

      <BottomNavigation
        actions={availableActions}
        value={createWeddingModalOpen ? 0 : findWeddingModalOpen ? 1 : -1}
      />

      {loadingWeddings ? (
        <Box
          className="weddings-list-page-content flex-center-content"
          sx={{ flexGrow: 1 }}
        >
          <SplashContent />
        </Box>
      ) : weddings.length === 0 ? (
        <Box
          className="weddings-list-page-content flex-center-content"
          sx={{ flexGrow: 1 }}
        >
          <Paper
            elevation={0}
            sx={{
              maxWidth: "21rem",
              margin: "0 auto",
              backgroundColor: "grey.extralight",
              padding: "2rem",
            }}
          >
            <Typography variant="h5" color="primary">
              What do you want to do?
            </Typography>

            <List
              // dense
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                "& .MuiListItem-root": {
                  backgroundColor: "#ececec",
                  borderBottom: "1px solid #b0b0b0",
                  borderRadius: "0.5rem",
                  padding: 0,
                },
                "& .MuiListItemButton-root": {
                  padding: "1rem 1.25rem",
                },
                "& .MuiListItemIcon-root": {
                  minWidth: "auto",
                  marginRight: "1rem",
                },
              }}
            >
              <ListItem disableGutters>
                <ListItemButton
                  disableGutters
                  onClick={() => setCreateWeddingModalOpen(true)}
                >
                  <ListItemIcon>
                    <AutoFixHighIcon />
                  </ListItemIcon>

                  <ListItemText primaryTypographyProps={{ variant: "body2" }}>
                    See how Fotobot works by creating a wedding gallery
                  </ListItemText>
                </ListItemButton>
              </ListItem>

              <ListItem disableGutters>
                <ListItemButton
                  disableGutters
                  onClick={() => setFindWeddingModalOpen(true)}
                >
                  <ListItemIcon>
                    <SearchIcon />
                  </ListItemIcon>

                  <ListItemText primaryTypographyProps={{ variant: "body2" }}>
                    Find the gallery of a wedding you attended
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Paper>
        </Box>
      ) : (
        <Box
          className="weddings-list-page-content"
          sx={{ display: "flex", flexDirection: "column", gap: "1.25rem" }}
        >
          {weddingsCreatedOrHostedByCurUser.length > 0 ? (
            <WeddingsGroup
              groupName="Weddings you hosted"
              weddings={weddingsCreatedOrHostedByCurUser}
            />
          ) : null}

          {otherWeddings.length > 0 ? (
            <WeddingsGroup
              groupName="Weddings you attended"
              weddings={otherWeddings}
            />
          ) : null}
        </Box>
      )}
    </Box>
  );
}

function WeddingsGroup({ groupName, weddings }) {
  const theme = useTheme();

  return (
    <Box className="weddings-group">
      <Typography
        variant="body2"
        sx={{
          margin: {
            xs: "0.5rem 1rem",
            sm: "0.5rem 0",
            md: "1rem 0rem 0.5rem 0rem",
          },
          color: "gray",
          borderBottom: {
            xs: `1px solid ${theme.palette.grey.light}`,
            md: "none",
          },
        }}
      >
        {groupName}
      </Typography>

      <ResponsiveGrid className="weddings-grid">
        {weddings.map((wedding) => (
          <Box
            component={Link}
            key={wedding.id}
            to={`/weddings/${wedding.slug}`}
            sx={{ textDecoration: "none" }}
          >
            <WeddingCardComponent wedding={wedding} />
          </Box>
        ))}
      </ResponsiveGrid>
    </Box>
  );
}
