import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import cx from "classnames";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm } from "react-hook-form";

import CustomModal from "@@components/common/CustomModal";
import CustomTextField from "@@components/common/CustomTextField";
import { saveMediaFilters } from "@@services/wedding-media.service";

export default function SaveAppliedFiltersModal({
  weddingId,
  appliedFilters,
  onSave,
  open,
  onClose,
  className,
  ...restProps
}) {
  const { handleSubmit, register, reset } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const [submitting, setSubmitting] = React.useState(false);

  return (
    <CustomModal
      className={cx("save-applied-filters-modal", className)}
      open={open}
      onClose={onClose}
      {...restProps}
    >
      <Typography sx={{ marginBottom: "1rem" }}>
        Please provide a name for this set of filters
      </Typography>

      <Box component="form" onSubmit={handleSubmit(saveAppliedFilter)}>
        <CustomTextField
          placeholder="Ex: 'Photos of Priya', 'Group photos', etc."
          sx={{ marginBottom: "1rem" }}
          {...register("name")}
        />

        <LoadingButton type="submit" variant="contained" loading={submitting}>
          Save
        </LoadingButton>
      </Box>
    </CustomModal>
  );

  async function saveAppliedFilter({ name }) {
    setSubmitting(true);
    try {
      const { data } = await saveMediaFilters(
        weddingId,
        name,
        _.omit(appliedFilters, ["mediaPath", "lastId"])
      );

      onSave(data);
      reset();
    } catch (e) {
      enqueueSnackbar(`There was an error`, { variant: "error" });
      throw e;
    } finally {
      setSubmitting(false);
    }
  }
}
