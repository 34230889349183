import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import cx from "classnames";
import React from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

import CustomTextField from "@@components/common/CustomTextField";
import { isValidPhone } from "@@utils/inputValidationUtils";
import { capitalizeFirstLetter } from "@@utils/strUtils";

export default function KeyPeopleForm({
  defaultValues,
  onSubmit,
  BrideFieldsProps,
  GroomFieldsProps,
  SubmitButtonProps,
  className,
  sx,
  formMethodsRef,
  ...restProps
}) {
  const formMethods = useForm({
    defaultValues: {
      bride: { name: "", phoneNumber: "+91" },
      groom: { name: "", phoneNumber: "+91" },
      ...defaultValues,
    },
  });
  // allows code outside this component get the current values
  //   of this form's fields before form submission; this is useful
  //   when we want to 'save' the input fields' state while the form
  //   gets unmounted and remounted (for example in a multi-form
  //   workflow with back and forward buttons)
  if (formMethodsRef) formMethodsRef.current = formMethods;
  const { handleSubmit } = formMethods;

  const { sx: submitButtonSx, ...submitButtonRestProps } = SubmitButtonProps;

  return (
    <FormProvider {...formMethods}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        className={cx(className, "create-wedding-flow-key-people-form")}
        sx={{
          display: "flex",
          flexDirection: "column",
          "& .key-person-fields": { marginBottom: "1rem" },
          "& .key-person-fields h2": { fontWeight: "bold" },
          ...sx,
        }}
        {...restProps}
      >
        <KeyPersonFields role="bride" {...BrideFieldsProps} />

        <KeyPersonFields role="groom" {...GroomFieldsProps} />

        <LoadingButton
          variant="contained"
          type="submit"
          sx={{ alignSelf: "flex-start", ...submitButtonSx }}
          {...submitButtonRestProps}
        >
          Submit
        </LoadingButton>
      </Box>
    </FormProvider>
  );
}

function KeyPersonFields({ role, disabled }) {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  return (
    <Box className="key-person-fields">
      <Typography component="h2" variant="body2">
        {capitalizeFirstLetter(role)}
      </Typography>

      <CustomTextField
        size="normal"
        margin="normal"
        disabled={disabled}
        label="Name"
        error={!!errors?.[role]?.name}
        helperText={errors?.[role]?.name?.message ?? ""}
        {...register(`${role}.name`, {
          required: { value: true, message: "This field is required" },
          minLength: { value: 1, message: "Name cannot be empty" },
        })}
      />

      <CustomTextField
        size="normal"
        margin="normal"
        disabled={disabled}
        label="Phone Number"
        error={!!errors?.[role]?.phoneNumber}
        helperText={errors?.[role]?.phoneNumber?.message ?? ""}
        InputProps={{ placeholder: "Enter ph. no. with country code" }}
        {...register(`${role}.phoneNumber`, {
          required: { value: true, message: "This field is required" },
          validate: isValidPhone,
        })}
      />
    </Box>
  );
}
