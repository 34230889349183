import Typography from "@mui/material/Typography";
import cx from "classnames";

import CustomModal from "@@components/common/CustomModal";
import SignupForm from "@@components/common/SignupForm";

export default function SignupModal({
  open,
  onClose,
  className,
  ...restProps
}) {
  return (
    <CustomModal
      className={cx("signup-modal", className)}
      open={open}
      onClose={onClose}
      {...restProps}
    >
      <Typography variant="body2" style={{ marginBottom: "1rem" }}>
        Signup with your whatsapp phone number
      </Typography>

      <SignupForm
        onSuccess={() => {
          const urlSearchParams = new URLSearchParams(window.location.search);
          const redirectTo = urlSearchParams.get("from");
          if (redirectTo) {
            window.location.replace(decodeURIComponent(redirectTo));
          }
          onClose(null, "signupSuccess");
        }}
      />
    </CustomModal>
  );
}
