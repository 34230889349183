import Typography from "@mui/material/Typography";
import cx from "classnames";

export default function WeddingName({ children, className, sx, ...restProps }) {
  return (
    <Typography
      variant="h1"
      component="h1"
      className={cx(className, "wedding-heading")}
      sx={{
        overflowWrap: "break-word",
        fontFamily: "Young Serif",
        fontSize: { xs: "2.5rem", sm: "3rem", md: "2rem" },
        textAlign: "center",
        ...sx,
      }}
      {...restProps}
    >
      {children}
    </Typography>
  );
}
