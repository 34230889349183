import Autocomplete from "@mui/material/Autocomplete";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { useSnackbar } from "notistack";

import { LoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";
import { listPeopleAtWedding } from "@@services/fotobot-api.service";
import { getUserObjWithFullName } from "@@utils";

export default function ChooseFromPeopleAtWedding({
  wedding,
  selectedPersonIds,
  label,
  InputProps,
  InputLabelProps,
  onChange,
  ...restProps
}) {
  const loggedInUser = React.useContext(LoggedInUserContext);
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [peopleAtWedding, setPeopleAtWedding] = React.useState([]);

  React.useEffect(() => {
    if (!open) return;

    (async () => {
      setLoading(true);
      try {
        const { data: peopleAtWedding } = await listPeopleAtWedding(wedding.id);
        setPeopleAtWedding(peopleAtWedding);
      } catch (e) {
        enqueueSnackbar(
          `An error occurred when retrieving the list of people at this wedding`,
          { variant: "error" }
        );
        throw e;
      } finally {
        setLoading(false);
      }
    })();
  }, [open, wedding.id, enqueueSnackbar]);

  let autocompleteOptions = peopleAtWedding
    // currently logged-in user gets a separate entry
    .filter(
      ({ type, data }) => type === "labeledFace" || data.id !== loggedInUser?.id
    )
    .map(({ type, data }) => ({
      type,
      data: type === "fotobotUser" ? getUserObjWithFullName(data) : data,
    }))
    .sort((a, b) => {
      if (a.type === b.type) {
        return (a.data.name ?? a.data.label).localeCompare(
          b.data.name ?? b.data.label
        );
      }
      if (a.type === "fotobotUser") return -1;
      return 1;
    });
  if (loggedInUser) {
    autocompleteOptions.unshift({
      type: "fotobotUser",
      data: {
        id: loggedInUser.id,
        name: "me",
        profilePicUrl: loggedInUser.profilePicUrl,
        hasSelfie: !!loggedInUser.selfiePicUrl,
      },
    });
  }

  return (
    <Autocomplete
      id="wedding-media-featuring-filter-select"
      multiple
      fullWidth
      disableCloseOnSelect
      slotProps={{
        // allow the user to see more options at a time
        paper: { sx: { "& .MuiAutocomplete-listbox": { minHeight: "25rem" } } },
      }}
      loading={loading}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(ev, [option], reason) => onChange(option.data)}
      options={autocompleteOptions}
      groupBy={(option) => (option.type === "fotobotUser" ? "Users" : "Labels")}
      getOptionDisabled={(option) => {
        if (option.type === "fotobotUser" && !option.data.hasSelfie) {
          // users who haven't uploaded selfies should be disabled, BUT allow
          //   loggedInUser to select themselves even if they haven't uploaded
          //   a selfie yet, because we'll show them a CTA to do so after the
          //   filter has been applied
          const isLoggedInUser = option.data.id === loggedInUser?.id;
          if (!isLoggedInUser) return true;
        }

        return false;
      }}
      renderOption={({ sx, ...restProps }, option) => {
        const isSelected = selectedPersonIds.has(
          option.data.id ?? option.data.faceId
        );
        const isLoggedInUser =
          option.type === "fotobotUser" && option.data.id === loggedInUser?.id;

        return (
          <Box
            {...restProps}
            sx={{
              ...sx,
              backgroundColor: isSelected ? "lightyellow" : "transparent",
              display: "flex",
              alignItems: "center",
              paddingTop: "4px !important",
              paddingBottom: "4px !important",
            }}
            key={option.data.id ?? option.data.faceId}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body2">
                {option.data.name ?? option.data.label}
              </Typography>

              <Typography variant="caption" sx={{ color: "grey.main" }}>
                {isSelected
                  ? "Selected"
                  : option.type === "fotobotUser" && !option.data.hasSelfie
                  ? isLoggedInUser
                    ? ""
                    : "Has not uploaded a selfie"
                  : option.type === "labeledFace"
                  ? option.data.labeler.id === loggedInUser?.id
                    ? "Added by you"
                    : `Added by ${option.data.labeler.firstName}`
                  : ""}
              </Typography>
            </Box>

            <Avatar
              alt={option.data.name ?? option.data.label}
              src={option.data.profilePicUrl ?? option.data.faceThumbUrl}
              sx={{ marginLeft: "auto" }}
            />
          </Box>
        );
      }}
      getOptionLabel={(option) => option.data.name || option.data.label}
      value={[]}
      filterOptions={(options, { inputValue }) =>
        options.filter((o) =>
          (o.data.name ?? o.data.label)
            .toLowerCase()
            .includes(inputValue.toLowerCase())
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          variant="standard"
          label={label ?? "Find a person ..."}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
            ...InputProps,
          }}
          InputLabelProps={InputLabelProps}
        />
      )}
      {...restProps}
    />
  );
}
