import { useHistory } from "react-router-dom";

import CustomModal from "@@components/common/CustomModal";
import FindWeddingComponent from "@@components/common/FindWeddingComponent";

export default function FindWeddingModal({
  open,
  onClose,
  className,
  ...restProps
}) {
  const history = useHistory();

  return (
    <CustomModal
      className="find-wedding-modal"
      open={open}
      onClose={onClose}
      {...restProps}
    >
      <FindWeddingComponent
        onSelect={(selectedWedding) => {
          history.push(`/weddings/${selectedWedding.slug}`);
          onClose(null, "weddingSelected");
        }}
      />
    </CustomModal>
  );
}
