import Box from "@mui/material/Box";
import React from "react";

function ResponsiveGrid({ children, sx, ...restProps }, ref) {
  return (
    <Box
      sx={{
        margin: "0 auto",
        display: "grid",
        gridTemplateColumns: {
          xs: "1fr",
          sm: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)",
          xl: "repeat(4, 1fr)",
        },
        gap: "1rem",
        ...sx,
      }}
      {...restProps}
      ref={ref}
    >
      {children}
    </Box>
  );
}

export default React.forwardRef(ResponsiveGrid);
