import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import React from "react";

import Router from "@@components/Router";
import LoggedInUserContextsWrapper from "@@contexts/LoggedInUserContextsWrapper";
import WeddingMediaUploadStatsContextsWrapper from "@@contexts/WeddingMediaUploadStatsContextsWrapper";
import PWAInstallSupportContextsWrapper from "@@contexts/PWAInstallSupportContextsWrapper";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 2160,
    },
  },
  palette: {
    primary: {
      main: "#f2529a",
      contrastText: "#FFF",
      light: "#FFC0CB", // pink
      extralight: "#ffdbe1",
    },
    secondary: {
      main: "#871063",
      contrastText: "#FFF",
      light: "#ab47bc",
    },
    grey: {
      main: "#8a8a8a",
      dark: "#454545",
      extradark: "#1f1f1f",
      light: "#d3d3d3",
      extralight: "#f4f4f4",
    },
    text: {
      primary: "#454545",
    },
  },
});

function App() {
  return (
    <SnackbarProvider maxSnack={3} disableWindowBlurListener>
      <ThemeProvider theme={theme}>
        <PWAInstallSupportContextsWrapper>
          <LoggedInUserContextsWrapper>
            <WeddingMediaUploadStatsContextsWrapper>
              <CssBaseline />
              <Router />
            </WeddingMediaUploadStatsContextsWrapper>
          </LoggedInUserContextsWrapper>
        </PWAInstallSupportContextsWrapper>
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
