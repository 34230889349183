import TextField from "@mui/material/TextField";
import cx from "classnames";
import React from "react";

function CustomTextField(
  { label, error, helperText, InputLabelProps, className, ...restProps },
  ref
) {
  return (
    <TextField
      ref={ref}
      label={label}
      className={cx("custom-text-field", className)}
      size="small"
      variant="outlined"
      autoComplete="off"
      InputLabelProps={{ shrink: true, ...InputLabelProps }}
      fullWidth
      error={!!error}
      helperText={error?.message || helperText}
      {...restProps}
    />
  );
}

export default React.forwardRef(CustomTextField);
