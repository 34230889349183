import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm } from "react-hook-form";

import CustomTextField from "@@components/common/CustomTextField";
import { verifyWeddingPasscode } from "@@services/auth.service";

export default function WeddingPasscodeForm({ weddingId, onSuccess }) {
  const [submitting, setSubmitting] = React.useState(false);
  const { register, handleSubmit } = useForm({
    defaultValues: { passcode: "" },
  });
  const { enqueueSnackbar } = useSnackbar();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CustomTextField
        autoFocus
        label="Passcode *"
        helperText="Don't have the passcode? Ask your host!"
        size="normal"
        sx={{ marginBottom: "1rem" }}
        InputLabelProps={{ shrink: true }}
        {...register("passcode", {
          required: { value: true, message: "This field is required." },
        })}
      />

      <LoadingButton variant="contained" type="submit" loading={submitting}>
        Submit
      </LoadingButton>
    </form>
  );

  async function onSubmit({ passcode }) {
    setSubmitting(true);

    try {
      await verifyWeddingPasscode(weddingId, passcode);
      onSuccess();
    } catch (e) {
      if (e.response?.data?.error === "Token is invalid") {
        enqueueSnackbar(`Incorrect passcode`, { variant: "error" });
      } else {
        enqueueSnackbar(`An error occurred`, { variant: "error" });
        console.error(`Error: ${e.response?.data?.error ?? e.message}`);
      }
    } finally {
      setSubmitting(false);
    }
  }
}
