import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function PageFooter({ sx, ...restProps }) {
  return (
    <Box
      component="footer"
      sx={{
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "0.5rem",
        ...sx,
      }}
      {...restProps}
    >
      <Typography
        variant="body2"
        sx={{ color: "#adadad", textAlign: "center", fontSize: "0.8rem" }}
      >
        &copy; SortaSocial Software Private Limited. All rights reserved.
      </Typography>

      <Typography
        variant="body2"
        sx={{ color: "#adadad", textAlign: "center", fontSize: "0.8rem" }}
      >
        UG-006, MGF Metropolis, MG Road
        <br />
        Gurgaon, Haryana, India - 122002
      </Typography>

      <Typography
        variant="body2"
        sx={{ color: "#adadad", textAlign: "center", fontSize: "0.8rem" }}
      >
        Email: anirudh@fotobot.app
        <br />
        Phone: +91-99168-12170
      </Typography>

      <Box
        sx={{
          fontSize: "0.8rem",
          color: "#adadad",
          display: "flex",
          gap: "1rem",
        }}
      >
        <Box
          component="a"
          href="/privacy.html"
          target="_blank"
          sx={{ color: "#adadad" }}
        >
          Privacy Policy
        </Box>

        <Box
          component="a"
          href="/terms.html"
          target="_blank"
          sx={{ color: "#adadad" }}
        >
          Terms of Service
        </Box>
      </Box>
    </Box>
  );
}
