import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React from "react";
import Typography from "@mui/material/Typography";

import {
  LoggedInUserContext,
  SetLoggedInUserContext,
} from "@@contexts/LoggedInUserContextsWrapper";
import { TopLevelModalsOpenStateSettersContext } from "@@contexts/TopLevelModalsWrapper";

import LoggedInUserChangeableAvatar from "./LoggedInUserChangeableAvatar";
import UserProfileForm from "./UserProfileForm";

function UserProfile({ sx, ...restProps }, ref) {
  const loggedInUser = React.useContext(LoggedInUserContext);
  const { setGetUserSelfieModalOpen } = React.useContext(
    TopLevelModalsOpenStateSettersContext
  );
  const setLoggedInUser = React.useContext(SetLoggedInUserContext);

  return (
    <Box className="user-profile" {...restProps} ref={ref}>
      <Box
        className="user-pics"
        sx={{
          margin: "0 auto 2rem auto",
          display: "flex",
          justifyContent: "space-between",
          "& .user-pic-container": {
            flex: "1 0 0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "0.5rem",
          },
        }}
      >
        <Box className="user-pic-container">
          <LoggedInUserChangeableAvatar
            picType="profilePic"
            sx={{ height: "8rem", width: "8rem" }}
          />
          <Typography sx={{ fontSize: "0.8rem" }}>Profile Pic</Typography>
        </Box>

        <Box className="user-pic-container">
          <Box
            sx={{
              position: "relative",
              width: "8rem",
              height: "8rem",
              borderRadius: "50%",
              overflow: "hidden",
            }}
          >
            <Avatar
              alt="Your selfie"
              src={loggedInUser.selfiePicUrl}
              sx={{
                width: "100%",
                height: "100%",
                border: "2px solid rgba(0, 0, 0, 0.2)",
              }}
            />

            <Button
              sx={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                height: "50%",
                backgroundColor: "rgba(0, 0, 0, 0.33)",
                color: "white",
              }}
              onClick={() => setGetUserSelfieModalOpen(true)}
            >
              {loggedInUser.selfiePicUrl ? "Change" : "Add"}
            </Button>
          </Box>

          <Typography sx={{ fontSize: "0.8rem", textAlign: "center" }}>
            Your Selfie
          </Typography>
        </Box>
      </Box>

      <UserProfileForm user={loggedInUser} onUserUpdated={setLoggedInUser} />
    </Box>
  );
}

export default React.forwardRef(UserProfile);
