import Paper from "@mui/material/Paper";
import cx from "classnames";

export default function BottomBar({ className, sx, children, ...restProps }) {
  return (
    <Paper
      className={cx("bottom-bar", className)}
      sx={{
        position: "fixed",
        bottom: "1rem",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 2000,
        width: { xs: "90%", sm: "30rem" },
        ...sx,
      }}
      {...restProps}
    >
      {children}
    </Paper>
  );
}
