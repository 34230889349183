import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { Link } from "react-router-dom";

import { LoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";
import { getUserFullName } from "@@utils";

import ActionsMenuList from "./ActionsMenuList";
import AppBarMenu from "./AppBarMenu";
import logo from "@@img/logo64.png";

export default function AppSidebar({ actions, children, sx, ...restProps }) {
  const theme = useTheme();
  const loggedInUser = React.useContext(LoggedInUserContext);

  const [expanded, setExpanded] = React.useState(false);

  const menuAnchorElRef = React.useRef(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const openMenu = () => setMenuOpen(true);
  const closeMenu = () => setMenuOpen(false);

  return (
    <AppBar
      sx={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        width: "max-content",
        backgroundColor: "transparent",
        boxShadow: "none",
        "& .MuiToolbar-root": {
          width: expanded ? "15rem" : "4rem",
          height: "100%",
          backgroundColor: "white",
          border: 0,
          borderRight: `1px solid ${theme.palette.grey.light}`,
          borderTopRightRadius: "4px",
          borderBottomRightRadius: "4px",
          padding: "2rem 0.5rem !important",
          display: "flex",
          flexDirection: "column !important",
          gap: "1rem",
          justifyContent: "flex-start !important",
          alignItems: "flex-start",
          transition: "padding 0.1s linear, width 0.1s linear",
          "& .actions-menu-list": {
            alignSelf: "stretch",
            "& .MuiListItemIcon-root": {
              // horizontally align text in the app bar
              paddingLeft: "0.75rem",
              paddingRight: "0.75rem",
            },
            "& .MuiListItemText-root": {
              display: expanded ? "flex" : "none",
              opacity: expanded ? 1 : 0,
              transition: "opacity 0.1s linear",
            },
          },
        },
        ...sx,
      }}
      // when debugging, you might find it useful to comment out the
      //   onMouse* handlers, and uncomment the onClick handler
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
      // onClick={() => setExpanded((x) => !x)}
      {...restProps}
    >
      <Toolbar>
        <Box
          component={Link}
          to="/"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            textDecoration: "none",
          }}
        >
          <Box
            component="img"
            src={logo}
            alt="Fotobot logo"
            sx={{ width: "3rem", height: "3rem" }}
          />

          <Typography
            variant="h1"
            component="h1"
            sx={{
              // center against logo
              position: "relative",
              top: "2px",

              display: expanded ? "block" : "none",
              color: "grey.dark",
              fontFamily: "Young Serif",
              fontSize: "2rem",
              opacity: expanded ? 1 : 0,
              transition: "opacity 0.1s linear",
            }}
          >
            Fotobot
          </Typography>
        </Box>

        <Divider
          sx={{
            marginTop: "1rem",
            width: "100%",
            borderBottomWidth: "initial",
          }}
        />

        {children}

        <ActionsMenuList actions={actions} />

        <Box
          className="app-bar-menu-container"
          sx={{ marginTop: "auto", width: "100%" }}
        >
          <Box
            ref={menuAnchorElRef}
            className="app-bar-menu-toggle"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              ":hover": { cursor: "pointer" },
            }}
            onClick={openMenu}
          >
            {loggedInUser ? (
              <Avatar
                alt={loggedInUser ? getUserFullName(loggedInUser) : ""}
                src={loggedInUser?.profilePicUrl}
                sx={{ width: "3rem", height: "3rem", cursor: "pointer" }}
              />
            ) : (
              <MenuIcon
                sx={{
                  margin: "0 12px",
                  width: "1.5rem",
                  color: theme.palette.grey.main,
                }}
              />
            )}

            <Typography
              variant="body1"
              sx={{
                display: expanded ? "block" : "none",
                color: "grey",
                whiteSpace: "nowrap",
                transition: "opacity 2s 1s",
              }}
            >
              {loggedInUser ? `Hi ${loggedInUser.firstName}!` : "Menu"}
            </Typography>
          </Box>

          <AppBarMenu
            anchorEl={menuAnchorElRef.current}
            open={menuOpen}
            onClose={closeMenu}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "bottom", horizontal: "left" }}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
AppSidebar.defaultProps = {
  actions: [],
};
