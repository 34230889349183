import cx from "classnames";

import CustomModal from "@@components/common/CustomModal";
import UserProfile from "@@components/common/UserProfile";

export default function UserProfileModal({
  open,
  onClose,
  className,
  ...restProps
}) {
  return (
    <CustomModal
      className={cx(className, "user-profile-modal")}
      open={open}
      onClose={onClose}
      {...restProps}
    >
      <UserProfile />
    </CustomModal>
  );
}
