import AddToHomeScreenIcon from "@mui/icons-material/AddToHomeScreen";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PersonIcon from "@mui/icons-material/Person";
import { useTheme } from "@mui/material/styles";
import React from "react";

import {
  LoggedInUserContext,
  SetLoggedInUserContext,
} from "@@contexts/LoggedInUserContextsWrapper";
import { BeforeInstallPromptEventContext } from "@@contexts/PWAInstallSupportContextsWrapper";
import { TopLevelModalsOpenStateSettersContext } from "@@contexts/TopLevelModalsWrapper";
import { logout } from "@@services/auth.service";
import { handleGetHelpButtonClick } from "@@utils/siteUtils";

export default function AppBarMenu({ anchorEl, open, onClose, ...restProps }) {
  const theme = useTheme();
  const loggedInUser = React.useContext(LoggedInUserContext);
  const setLoggedInUser = React.useContext(SetLoggedInUserContext);
  const {
    setLoginModalOpen,
    setSignupModalOpen,
    setPwaInstallModalOpen,
    setUserProfileModalOpen,
  } = React.useContext(TopLevelModalsOpenStateSettersContext);
  const beforeInstallPromptEvent = React.useContext(
    BeforeInstallPromptEventContext
  );

  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      disableScrollLock
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      MenuListProps={{
        sx: {
          padding: 0,
          "& .MuiMenuItem-root": {
            padding: { xs: "0.5rem 1rem", sm: "1rem 1rem" },
          },
          "& .MuiDivider-root": {
            width: "100%",
            margin: "0 !important",
          },
        },
      }}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            marginTop: "0.5rem",
            overflow: "visible",
            // have to use filter here instead of simply setting
            //   Paper elevation because the arrow (see below) doesn't
            //   look good otherwise
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            [theme.breakpoints.down("md")]: {
              // arrow
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: "1.25rem",
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        },
      }}
      {...restProps}
    >
      {/*
          Passing a React.Fragment as a child to Menu, as we would do here
            normally, causes a super-annoying warning to be logged to the
            console in non-prod envs (see https://github.com/mui/material-ui/issues/16181)
          Hence the annoying plurality of ternary operators below
        */}

      {/* menu for logged-in-users */}
      {loggedInUser ? (
        <MenuItem
          onClick={() => {
            setUserProfileModalOpen(true);
            onClose();
          }}
        >
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText>View Profile</ListItemText>
        </MenuItem>
      ) : null}
      {loggedInUser && beforeInstallPromptEvent ? (
        <MenuItem onClick={() => setPwaInstallModalOpen(true)}>
          <ListItemIcon>
            <AddToHomeScreenIcon />
          </ListItemIcon>
          <ListItemText>Install Fotobot</ListItemText>
        </MenuItem>
      ) : null}
      {loggedInUser ? (
        <MenuItem onClick={handleGetHelpButtonClick}>
          <ListItemIcon>
            <WhatsAppIcon />
          </ListItemIcon>
          <ListItemText>Get Help</ListItemText>
        </MenuItem>
      ) : null}
      {loggedInUser ? <Divider /> : null}
      {loggedInUser ? (
        <MenuItem
          onClick={() => {
            onClose();
            logout();
            setLoggedInUser(null);
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      ) : null}

      {/* menu for anon-users */}
      {loggedInUser ? null : (
        <MenuItem onClick={handleGetHelpButtonClick}>
          <ListItemIcon>
            <WhatsAppIcon />
          </ListItemIcon>
          <ListItemText>Get Help</ListItemText>
        </MenuItem>
      )}
      {loggedInUser ? null : (
        <MenuItem onClick={() => setLoginModalOpen(true)}>
          <ListItemText>Login</ListItemText>
        </MenuItem>
      )}
      {loggedInUser ? null : (
        <MenuItem onClick={() => setSignupModalOpen(true)}>
          <ListItemText>Signup</ListItemText>
        </MenuItem>
      )}
    </Menu>
  );
}
