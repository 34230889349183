import _ from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import {
  defaultWeddingMediaFilters,
  nMediaPerApiRequest,
  standardApiResponses,
} from "@@config";
import mediaFiltersReducer from "@@reducers/mediaFiltersReducer";
import weddingMediaReducer from "@@reducers/weddingMediaReducer";
import {
  checkForNewMedia,
  getWeddingMedia,
} from "@@services/wedding-media.service";
import { delayMs } from "@@utils";
import { atPageBottom } from "@@utils/webApiUtils";

// custom hook to fetch media and provide infinite scroll
//   effect; for use in WeddingPhotos and WeddingVideos components
export default function useWeddingMedia({
  loggedInUser,
  weddingId,
  initialFilters,
  fetchAllSimilarMediaAtOnce,
}) {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const [error, setError] = React.useState(null);
  const [initialFetchDone, setInitialFetchDone] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [media, mediaDispatch] = React.useReducer(weddingMediaReducer, []);
  const [mediaFilters, mediaFiltersDispatch] = React.useReducer(
    mediaFiltersReducer,
    { ...defaultWeddingMediaFilters, ...initialFilters }
  );

  // can't just compute this from the media array because we are
  //   asking the api to return 'similar media' in the response,
  //   even if they would not normally be in the current 'page' of
  //   results
  const [lastIdFetched, setLastIdFetched] = React.useState(null);

  const [thereIsMoreMedia, setThereIsMoreMedia] = React.useState(true);
  const [thereIsNewMedia, setThereIsNewMedia] = React.useState(false);

  const fetchMediaAbortController = React.useRef();
  const newMediaCheckAbortControllerRef = React.useRef();
  const newMediaCheckTimeoutRef = React.useRef(null);

  const reset = React.useCallback(() => {
    // cancel in-flight requests
    fetchMediaAbortController.current?.abort();
    newMediaCheckAbortControllerRef.current?.abort();

    // reset state
    setError(null);
    setInitialFetchDone(false);
    setFetching(false);
    mediaDispatch({ type: "SET", data: [] });
    setLastIdFetched(null);
    setThereIsMoreMedia(false);
    setThereIsNewMedia(false);
  }, []);

  const fetchMedia = React.useCallback(
    async (opts) => {
      const defaultOpts = {
        nItemsPerRequest: nMediaPerApiRequest.DEFAULT,
        nRequestsToMake: 1,
        delayBetweenRequestsMs: 1_000,
        includeSimilarMedia: fetchAllSimilarMediaAtOnce,
      };
      opts = { ...defaultOpts, ...opts };

      fetchMediaAbortController.current?.abort();
      fetchMediaAbortController.current = new AbortController();

      const {
        nItemsPerRequest,
        nRequestsToMake,
        delayBetweenRequestsMs,
        startId, // useful when 'fetching more/remaining media'
        stopId, // useful when 'fetching all new media'
        ...restOpts
      } = opts;

      const allFetchedMedia = [];
      let lastIdForPagination;
      let nRequestsMade = 0;
      setFetching(true);

      try {
        do {
          const {
            media: fetchedMedia,
            thereIsMoreMedia,
            lastId,
          } = await getWeddingMedia(
            weddingId,
            {
              ...mediaFilters,
              nItems: nItemsPerRequest,
              lastId: lastIdForPagination ?? startId,
              ...restOpts,
            },
            { signal: fetchMediaAbortController.current.signal }
          );
          allFetchedMedia.push(...fetchedMedia);
          setThereIsMoreMedia(thereIsMoreMedia);
          lastIdForPagination = lastId;

          if (!thereIsMoreMedia || lastIdForPagination <= stopId) {
            break;
          }

          await delayMs(delayBetweenRequestsMs); // allow other stuff to get done
        } while (++nRequestsMade < nRequestsToMake);
      } catch (e) {
        if (
          e.response?.status === 400 &&
          e.response?.data?.error ===
            standardApiResponses.UNAUTHENTICATED_USED_APPLIED_FEATURING_FILTER_ON_SELF
        ) {
          setFetching(false);
          return [];
        }

        if (
          ["canceled", "Request aborted"].some((x) => e.message.includes(x))
        ) {
          throw new Error(`REQUEST_ABORTED`);
        }

        e.message = `Error fetching media: ${
          e.response?.data?.error ?? e.message
        }`;
        setError(e);
      }

      setFetching(false);
      setLastIdFetched(lastIdForPagination);
      return allFetchedMedia;
    },
    [weddingId, mediaFilters, fetchAllSimilarMediaAtOnce]
  );

  const refreshMedia = React.useCallback(async () => {
    // prevents 'flash of stale results' when props change and new results
    //   are being fetched
    reset();

    try {
      const fetchedMedia = await fetchMedia();
      mediaDispatch({ type: "SET", data: fetchedMedia });
      setInitialFetchDone(true);
    } catch (e) {
      if (e.message !== "REQUEST_ABORTED") throw e;
    }
  }, [reset, fetchMedia]);

  // doesn't stop until all new media has been fetched
  const fetchAllNewMedia = React.useCallback(async () => {
    const fetchedMedia = await fetchMedia({
      nRequestsToMake: Infinity,
      nItemsPerRequest: nMediaPerApiRequest.MAX,
      stopId: media[0]?.id,
    });
    mediaDispatch({ type: "ADD", data: fetchedMedia });
    setThereIsNewMedia(false);
  }, [media, fetchMedia]);

  const fetchMoreMedia = React.useCallback(
    async (opts) => {
      const fetchedMedia = await fetchMedia({
        nRequestsToMake: Infinity,
        nItemsPerRequest: nMediaPerApiRequest.MAX,
        startId: lastIdFetched,
        ...opts,
      });
      mediaDispatch({ type: "ADD", data: fetchedMedia });
    },
    [fetchMedia, lastIdFetched]
  );

  // On initial load, log in/out, or when filters change
  // wipe out whatever photos are being shown right now, and
  //   show a fresh batch
  React.useEffect(() => {
    refreshMedia();
  }, [loggedInUser, refreshMedia]);

  // Infinite scroll effect - load more media when bottom of page reached
  React.useEffect(() => {
    const debouncedHandleScroll = _.debounce(handleScroll, 250);
    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };

    async function handleScroll() {
      if (atPageBottom(1200) && thereIsMoreMedia && !fetching) {
        await fetchMoreMedia({
          nRequestsToMake: 1,
          nItemsPerRequest: nMediaPerApiRequest.DEFAULT,
        });
      }
    }
  }, [fetchMoreMedia, thereIsMoreMedia, fetching]);

  // check for new media using long-polling
  React.useEffect(() => {
    if (!initialFetchDone) return;

    // if current user wants own media, but hasn't uploaded selfie,
    //   no point polling
    if (
      mediaFilters.featuring.data.some(({ id }) =>
        ["me", loggedInUser?.id].filter((x) => x).includes(id)
      ) &&
      (mediaFilters.featuring.type === "allOf" ||
        mediaFilters.featuring.data.length === 1) &&
      !loggedInUser?.selfiePicUrl
    ) {
      return;
    }

    newMediaCheckTimeoutRef.current = setTimeout(helper, 1_000);
    return () => {
      newMediaCheckAbortControllerRef.current?.abort();
      clearTimeout(newMediaCheckTimeoutRef.current);
    };

    async function helper() {
      if (thereIsNewMedia) {
        // we know there's new media, so no point checking again just yet
        newMediaCheckTimeoutRef.current = setTimeout(helper, 1_000);
        return;
      }

      try {
        // failing to abort a request-in-progress here will cause a duplicate
        //   timeout to be set once that request ends
        newMediaCheckAbortControllerRef.current?.abort();
        newMediaCheckAbortControllerRef.current = new AbortController();

        // long-poll request; takes up to 30s to return
        const newMediaIsAvailable = await checkForNewMedia(
          weddingId,
          { ...mediaFilters, newestMediaIdAlreadySeen: media[0]?.id },
          { signal: newMediaCheckAbortControllerRef.current.signal }
        );
        setThereIsNewMedia(newMediaIsAvailable);
        newMediaCheckTimeoutRef.current = setTimeout(helper, 1_000);
      } catch (e) {
        // TODO: are these error messages consistent across browsers?
        if (["canceled", "Request aborted"].includes(e.message)) {
          // do not set another timeout
        } else if ([401, 403].includes(e.response?.status)) {
          // do not set another timeout
        } else if (e.message === "Network Error") {
          newMediaCheckTimeoutRef.current = setTimeout(helper, 30_000);
        } else {
          // most often a server error; happens often during development
          newMediaCheckTimeoutRef.current = setTimeout(
            helper,
            process.env.REACT_APP_TARGET_ENV === "development" ? 15_000 : 60_000
          );

          e.message = "Failed to check for new uploads. Reason: " + e.message;
          console.error(e);
        }
      }
    }
  }, [
    weddingId,
    media,
    mediaFilters,
    enqueueSnackbar,
    closeSnackbar,
    initialFetchDone,
    thereIsNewMedia,
    loggedInUser,
  ]);

  return {
    fetching,
    error,
    media,
    mediaDispatch,
    mediaFilters,
    mediaFiltersDispatch,
    reset,
    refreshMedia,
    fetchAllNewMedia,
    fetchMoreMedia,
    thereIsMoreMedia,
    thereIsNewMedia,
  };
}
