import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import React from "react";

import {
  WeddingMediaAppliedFiltersContext,
  WeddingMediaAppliedFiltersDispatchContext,
  WeddingMediaSelectedFiltersDispatchContext,
} from "@@contexts/WeddingMediaFiltersContexts";
import { countFilters } from "@@utils/mediaFiltersUtils";

export default function SavedFilterPills({ mediaType, savedFilters }) {
  const selectedMediaFiltersDispatch = React.useContext(
    WeddingMediaSelectedFiltersDispatchContext
  );
  const appliedMediaFilters = React.useContext(
    WeddingMediaAppliedFiltersContext
  );
  const appliedMediaFiltersDispatch = React.useContext(
    WeddingMediaAppliedFiltersDispatchContext
  );

  return (
    <Box
      className={`saved-${mediaType}-filters`}
      sx={{
        padding: "0 1rem",
        display: "flex",
        gap: "0.5rem",
        overflowX: "auto",
      }}
    >
      <Chip
        variant={
          appliedMediaFilters.savedFilterUuid ||
          countFilters(appliedMediaFilters) > 0
            ? "outlined"
            : "filled"
        }
        label={`All ${mediaType}`}
        onClick={resetMediaFilters}
      />
      {savedFilters.map((sf) => {
        const isTheCurrentlyAppliedFilter =
          appliedMediaFilters.savedFilterUuid === sf.uuid;

        return (
          <Chip
            key={sf.uuid}
            variant={isTheCurrentlyAppliedFilter ? "filled" : "outlined"}
            label={sf.name}
            onClick={() => {
              const action = {
                type: "SET_FILTERS",
                data: { ...sf.filters, savedFilterUuid: sf.uuid },
              };
              selectedMediaFiltersDispatch(action);
              appliedMediaFiltersDispatch(action);
            }}
            {...(isTheCurrentlyAppliedFilter
              ? { onDelete: resetMediaFilters }
              : {})}
          />
        );
      })}
    </Box>
  );

  function resetMediaFilters() {
    const action = {
      type: "RESET_FILTERS",
      data: { retainThese: ["mediaPath", "mediaType"] },
    };
    selectedMediaFiltersDispatch(action);
    appliedMediaFiltersDispatch(action);
  }
}
