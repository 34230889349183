import MoreVertIcon from "@mui/icons-material/MoreVert";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ToggleButton from "@mui/material/ToggleButton";
import React from "react";

import SquareIconButton from "@@components/common/Buttons/SquareIconButton";
import CustomToggleButton from "@@components/common/Buttons/ToggleButton";

export default function WeddingPageActions({
  availableActions: actions,
  currentTab,
  curUserIsHost,
  folderViewIsOn,
  sx,
  ...restProps
}) {
  const moreActionsMenuToggleRef = React.useRef(null);
  const [moreActionsMenuOpen, setMoreActionsMenuOpen] = React.useState(false);

  const addAction =
    currentTab.name === "photos"
      ? actions.addPhotosAction
      : currentTab.name === "videos"
      ? actions.addVideosAction
      : currentTab.name === "people"
      ? actions.addGuestsAction
      : currentTab.name === "vendors"
      ? actions.addVendorsAction
      : null;

  return (
    <Box
      className="wedding-page-actions-bar"
      sx={{
        padding: "0 1rem",
        display: "flex",
        gap: "0.5rem",
        ...sx,
      }}
      {...restProps}
    >
      {addAction ? (
        <SquareIconButton onClick={addAction.onClick}>
          {addAction.icon}
        </SquareIconButton>
      ) : null}

      {/* we hope to add filters to the videos tab in future */}
      {["photos"].includes(currentTab.name) ? (
        <ToggleButton
          value="media-filters-drawer-open"
          selected={false}
          onChange={actions.filterMediaAction.onClick}
          size="small"
        >
          {actions.filterMediaAction.icon}
        </ToggleButton>
      ) : null}

      {["photos", "videos"].includes(currentTab.name) ? (
        <CustomToggleButton
          className="toggle-show-hide-folders"
          value="toggle-show-hide-folders"
          defaultSelected={folderViewIsOn}
          onChange={actions.toggleFoldersViewAction.onClick}
          onSelectMessage={`Now showing ${currentTab.name} folder-wise`}
          onDeselectMessage={`Now hiding folders`}
          size="small"
        >
          {actions.toggleFoldersViewAction.icon}
        </CustomToggleButton>
      ) : null}

      {["photos", "videos"].includes(currentTab.name) &&
      actions.downloadMediaAction.name === "download-ready" ? (
        <Button
          variant="contained"
          startIcon={actions.downloadMediaAction.icon}
          onClick={actions.downloadMediaAction.onClick}
        >
          {actions.downloadMediaAction.label}
        </Button>
      ) : null}

      <ToggleButton
        ref={moreActionsMenuToggleRef}
        value="more-actions-toggle"
        selected={moreActionsMenuOpen}
        onClick={() => setMoreActionsMenuOpen((x) => !x)}
        size="small"
        sx={{ border: 0 }}
      >
        <MoreVertIcon />
      </ToggleButton>

      <Menu
        open={moreActionsMenuOpen}
        onClose={() => setMoreActionsMenuOpen(false)}
        anchorEl={moreActionsMenuToggleRef.current}
        MenuListProps={{ dense: false }}
      >
        {actions.downloadMediaAction.name === "download-ready" ? null : [
            "photos",
            "videos",
          ].includes(currentTab.name) ? (
          <MenuItem onClick={actions.downloadMediaAction.onClick}>
            <ListItemIcon>{actions.downloadMediaAction.icon}</ListItemIcon>
            <ListItemText>{actions.downloadMediaAction.label}</ListItemText>
          </MenuItem>
        ) : null}

        {curUserIsHost ? (
          <MenuItem onClick={actions.changeWeddingCoverPhotoAction.onClick}>
            <ListItemIcon>
              {actions.changeWeddingCoverPhotoAction.icon}
            </ListItemIcon>
            <ListItemText>
              {actions.changeWeddingCoverPhotoAction.label}
            </ListItemText>
          </MenuItem>
        ) : null}

        {curUserIsHost ? (
          <MenuItem
            onClick={() => {
              actions.toggleWeddingSettingsPaneAction.onClick();
              setMoreActionsMenuOpen(false);
            }}
          >
            <ListItemIcon>
              {actions.toggleWeddingSettingsPaneAction.icon}
            </ListItemIcon>
            <ListItemText>
              {actions.toggleWeddingSettingsPaneAction.label}
            </ListItemText>
          </MenuItem>
        ) : null}
      </Menu>
    </Box>
  );
}
