import Box from "@mui/material/Box";
import cx from "classnames";
import React from "react";

import { arraysAreEqual } from "@@utils/arrayUtils";
import WeddingImageContainer from "./WeddingImageContainer";
import WeddingImagePile from "./WeddingImagePile";

function WeddingGalleryItem({
  photosInGroup,
  widthPx,
  onClick,
  className,
  ...restProps
}) {
  const aspectRatio =
    photosInGroup[0].otherData.widthPixels /
    photosInGroup[0].otherData.heightPixels;
  const orientation = aspectRatio >= 1 ? "landscape" : "portrait";

  return (
    <Box className={cx("wedding-gallery-item", className)} {...restProps}>
      {photosInGroup.length === 1 ? (
        <WeddingImageContainer
          media={photosInGroup[0]}
          showOverlay
          WeddingImageProps={{
            sizes: `${Math.ceil(widthPx)}px`,
            onClick: () => onClick(photosInGroup[0].id),
            style: { cursor: "pointer" },
            // not specifying a width and height here causes all images to be loaded
            //   at once even when lazy-loading is enabled, because the browser
            //   assumes the images are 0-dimensioned before they are loaded, and
            //   thinks every one of them is in the viewport
            width: Math.ceil(widthPx),
            height: Math.ceil(widthPx / aspectRatio),
          }}
        />
      ) : (
        <WeddingImagePile
          images={photosInGroup}
          sx={{
            height: Math.ceil(
              (widthPx / aspectRatio) *
                (orientation === "landscape" ? 1.2 : 0.8)
            ),
          }}
          WeddingImageProps={{
            sizes: "512px",
            onClick,
            style: { cursor: "pointer" },
          }}
        />
      )}
    </Box>
  );
}

export default WeddingGalleryItem;

export const MemoizedWeddingGalleryItem = React.memo(
  WeddingGalleryItem,
  (prevProps, newProps) =>
    prevProps.widthPx === newProps.widthPx &&
    arraysAreEqual(
      prevProps.photosInGroup,
      newProps.photosInGroup,
      (a, b) => a.id === b.id && a.isPublic === b.isPublic
    )
);
