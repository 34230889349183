import _ from "lodash";
import React from "react";

import CreateWeddingFlowKeyPeopleForm from "@@components/common/CreateWeddingFlow/KeyPeopleForm";
import { CreateWeddingFlowFsmStateContext } from "@@contexts/CreateWeddingFlowFsmContexts";

// wrapper that allows KeyPeopleForm to be independent of loggedInUserContext
//   and createWeddingFlowFsm's state/context
export default function KeyPeopleFormWrapper({ ...allProps }) {
  const fsmState = React.useContext(CreateWeddingFlowFsmStateContext);
  const role = fsmState.context.createWeddingData?.role;
  const additionalProps = {
    defaultValues: _.pick(fsmState.context.createWeddingData, [
      "bride",
      "groom",
    ]),
    BrideFieldsProps: { disabled: role === "BRIDE" },
    GroomFieldsProps: { disabled: role === "GROOM" },
  };

  return <CreateWeddingFlowKeyPeopleForm {...allProps} {...additionalProps} />;
}
