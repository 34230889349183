import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import cx from "classnames";
import React from "react";
import { useSnackbar } from "notistack";

import CustomModal from "@@components/common/CustomModal";
import { cookies as cookiesConfig } from "@@config";
import { SetLoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";
import { updateUserPic } from "@@services/fotobot-api.service";
import { dataUrlToFile } from "@@utils/webApiUtils";

import "animate.css";

export default function GetUserSelfieModal({
  open,
  onClose,
  className,
  ...restProps
}) {
  const setLoggedInUser = React.useContext(SetLoggedInUserContext);
  const { enqueueSnackbar } = useSnackbar();

  const [photoDataUrl, setPhotoDataUrl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const photoElemRef = React.useRef(null);
  const videoElemRef = React.useRef(null);

  const startCapturingVideo = React.useCallback(async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "user" },
        audio: false,
      });

      videoElemRef.current.srcObject = mediaStream;
      videoElemRef.current.play();
    } catch (e) {
      if (e.name === "NotFoundError") {
        enqueueSnackbar(
          `Sorry, we were unable to access a camera on this device ...`,
          { variant: "error" }
        );
      } else if (e.name === "NotAllowedError") {
        enqueueSnackbar(
          `We can't take your selfie without your permission to access the camera!`,
          { variant: "error" }
        );
      } else {
        enqueueSnackbar(`An error occurred`, { variant: "error" });
        console.error(e);
      }

      onClose();
    }
  }, [enqueueSnackbar, onClose]);

  const stopCapturingVideo = () => {
    if (!videoElemRef.current?.srcObject) return;

    videoElemRef.current.srcObject.getTracks().forEach((t) => t.stop());
    videoElemRef.current.srcObject = null;
  };

  const handleClose = (...args) => {
    onClose(...args);
    setPhotoDataUrl(null);
    stopCapturingVideo();
  };

  React.useEffect(() => {
    if (!open) return;

    if (photoDataUrl) stopCapturingVideo();
    else startCapturingVideo();
  }, [open, photoDataUrl, startCapturingVideo]);

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      className={cx(className, "get-user-selfie-modal")}
      hideBackdrop
      BoxProps={{
        className: "flex-center-content",
        sx: {
          width: "100vw",
          height: "100vh",
          backgroundColor: "black",
          borderRadius: 0,
          padding: 0,
          flexDirection: "column",
        },
      }}
      {...restProps}
    >
      <Box
        component="img"
        src={photoDataUrl}
        ref={photoElemRef}
        className="animate__animated"
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          display: photoDataUrl ? "inline" : "none",
        }}
      />

      <Box
        component="video"
        ref={videoElemRef}
        muted
        playsInline
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          display: photoDataUrl ? "none" : "inline",
        }}
      />

      <Box
        className="controls flex-center-content"
        sx={{
          position: "absolute",
          bottom: "2rem",
          gap: "1rem",
          "& button.MuiButtonBase-root": {
            backgroundColor: "#232323",
            color: loading ? "grey.main" : "primary.light",
          },
        }}
      >
        {photoDataUrl ? (
          <>
            <Button
              disableRipple
              size="large"
              variant="contained"
              startIcon={<CloseIcon />}
              onClick={() => setPhotoDataUrl(null)}
              disabled={loading}
            >
              Take another
            </Button>

            <LoadingButton
              loading={loading}
              loadingPosition="start"
              size="large"
              variant="contained"
              startIcon={<CheckIcon />}
              onClick={updateSelfie}
            >
              Accept
            </LoadingButton>
          </>
        ) : (
          <Button
            disableRipple
            size="large"
            variant="contained"
            startIcon={<CameraAltIcon />}
            onClick={takepicture}
          >
            Take photo
          </Button>
        )}
      </Box>
    </CustomModal>
  );

  function takepicture() {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    const width = videoElemRef.current.videoWidth;
    const height = videoElemRef.current.videoHeight;

    canvas.width = width;
    canvas.height = height;
    context.drawImage(videoElemRef.current, 0, 0, width, height);

    const data = canvas.toDataURL("image/jpeg");
    setPhotoDataUrl(data);
  }

  async function updateSelfie() {
    setLoading(true);

    try {
      const { data: user } = await updateUserPic(
        "me",
        dataUrlToFile(photoDataUrl),
        "selfiePic"
      );
      setLoggedInUser((loggedInUser) => ({
        ...loggedInUser,
        selfiePicUrl: user.selfiePicUrl,
      }));

      const { name, maxAge } = cookiesConfig.loggedInUserSelfieUpdated;
      document.cookie = `${name}=${+new Date()};max-age=${maxAge}`;

      enqueueSnackbar(`Selfie updated`, { variant: "success" });
      handleClose(null, "success");
    } catch (e) {
      if (e.response?.data?.error.startsWith("INVALID_SELFIE")) {
        enqueueSnackbar(e.response?.data?.error, {
          variant: "error",
        });
      } else {
        enqueueSnackbar(`There was an error.`, {
          variant: "error",
        });
        console.error(e);
      }
    } finally {
      setLoading(false);
    }
  }
}
