import Brightness1Icon from "@mui/icons-material/Brightness1";
import Brightness2Icon from "@mui/icons-material/Brightness2";
import Button from "@mui/material/Button";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import cx from "classnames";
import React from "react";

import { PRIVILEGED_WEDDING_ROLES } from "@@config";
import { LoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";

export default function WeddingGuestRoleControl({
  weddingGuest,
  switchWeddingRoleForUser,
  sx,
  className,
  ...restProps
}) {
  const loggedInUser = React.useContext(LoggedInUserContext);

  return (
    <Button
      className={cx("wedding-guest-role-control", className)}
      variant="outlined"
      color="primary"
      startIcon={
        PRIVILEGED_WEDDING_ROLES.includes(weddingGuest.role) ? (
          <Brightness1Icon />
        ) : weddingGuest.role === "INVITEE" ? (
          <Brightness2Icon />
        ) : (
          <NotInterestedIcon /> // UNINVITED
        )
      }
      size="small"
      disabled={
        ["BRIDE", "GROOM"].includes(weddingGuest.role) ||
        weddingGuest.id === loggedInUser?.id
      }
      onClick={async () => await switchWeddingRoleForUser(weddingGuest.id)}
      sx={{
        padding: "0.25rem 0",
        display: "flex",
        justifyContent: "flex-start",
        "& .MuiButton-startIcon": { margin: "0 0.375rem 0 0.5rem" },
        ...sx,
      }}
      {...restProps}
    >
      {PRIVILEGED_WEDDING_ROLES.includes(weddingGuest.role)
        ? "Full access"
        : weddingGuest.role === "INVITEE"
        ? "Limited access"
        : "No access"}
    </Button>
  );
}
