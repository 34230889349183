import Box from "@mui/material/Box";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Typography from "@mui/material/Typography";

export default function InfoTypography({ children, sx, ...restProps }) {
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", gap: "0.25rem", ...sx }}
    >
      <InfoOutlinedIcon
        sx={{
          position: "relative",
          top: "0.125rem",
          color: "primary.main",
          fontSize: "0.875rem",
        }}
      />
      <Typography variant="caption">{children}</Typography>
    </Box>
  );
}
