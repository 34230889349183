import { setIntersection } from "./setUtils";

// sends blanks to the back, instead of keeping them at the front
export function altStrCmp(a, b) {
  if (!a && b) return 1;
  if (a && !b) return -1;
  return a.localeCompare(b);
}

// combines a set of overlapping ranges into
//   a set of disjoint ranges
// examples:
//   combineRanges([1, 2] [3, 4]) => [[1, 2], [3, 4]]
//   combineRanges([1, 3] [2, 4]) => [[1, 4]]
export function combineRanges(...ranges) {
  const sorted = ranges.sort((a, b) => a[0] - b[0] || a[1] - b[1]);
  return sorted.reduce((acc, cur) => {
    if (acc.length === 0) return [cur];

    const last = acc.at(-1);

    // 'cur' starts at the same or a later index than 'last' starts

    // aaaa
    //  aa
    // aaaa
    if (cur[1] <= last[1]) {
      return acc;
    }

    // aaaa
    //    aaaaa
    // aaaaaaaa
    if (cur[1] > last[1] && cur[0] <= last[1]) {
      last[1] = cur[1];
      return acc;
    }

    // aaaa
    //      aaaa
    // aaaa aaaa
    if (cur[0] > last[1]) {
      return acc.concat([cur]);
    }

    // we'll never get here, but the linter's complaining
    return acc;
  }, []);
}

export function delayMs(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getUserFullName({ firstName, lastName }) {
  return `${firstName || ""} ${lastName || ""}`.trim();
}

export function getUserObjWithFullName(user) {
  const { firstName, lastName, ...restProps } = user;
  return { ...restProps, name: getUserFullName(user) };
}

export function getUserObjWithSplitName({ name, ...restProps }) {
  return {
    ...restProps,
    firstName: name.split(" ")[0],
    lastName: name.split(" ").slice(1).join(" "),
  };
}

export function getUserFirstNameAndInitial({ firstName, lastName }) {
  return [firstName ?? "", lastName ? lastName[0] + "." : ""]
    .filter((x) => x)
    .join(" ");
}

export function getUrlOfResizedImage(origUrl, dim) {
  const url = new URL(origUrl);
  const pathParts = url.pathname.split("/");
  const filename = pathParts[pathParts.length - 1];
  const nameOfResizedFile = filename.replace(
    /^(.+)\.([^.]+?)$/,
    (match, p1, p2) => `${p1}-${dim}.${p2}`
  );
  pathParts[pathParts.length - 1] = nameOfResizedFile;
  url.pathname = pathParts.join("/");
  return url.href;
}

export function getWidthFromResizedImageUrl(url) {
  return +url.match(/-(\d+)\.[^.]+$/)?.[1];
}

export function joinWithAnd(strs) {
  return [strs.slice(0, strs.length - 1).join(", "), strs[strs.length - 1]]
    .filter((x) => x)
    .join(" and ");
}

// turn a sorted array into an array of arrays, with divisions
//   based on splitFn
export function splitBy(elems, splitFn) {
  if (elems.length === 0) return [];

  const retval = [[elems[0]]];

  for (let i = 1; i < elems.length; i++) {
    if (!splitFn(elems[i - 1], elems[i])) {
      retval[retval.length - 1].push(elems[i]);
    } else {
      retval.push([elems[i]]);
    }
  }

  return retval;
}

export function getMainDateForWedding({ weddingEvents }) {
  weddingEvents = weddingEvents.sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );

  // will be undefined when weddingEvents have not yet been added
  const mainEvent =
    weddingEvents.find((we) => we.name.toLowerCase() === "wedding") ??
    weddingEvents[weddingEvents.length - 1];

  return mainEvent?.date;
}

export function objectsHaveSameValues(a, b, fields = null) {
  if (
    !(
      (Array.isArray(fields) &&
        fields.every((field) => typeof field === "string")) ||
      fields === null
    )
  ) {
    throw new Error("'fields' must be an array of strings, or null");
  }

  const aFields = new Set(Object.keys(a));
  const bFields = new Set(Object.keys(b));
  const fieldsToCompare =
    fields ?? Array.from(setIntersection(aFields, bFields));
  return fieldsToCompare
    .map((field) => a[field] === b[field])
    .every((x) => !!x);
}

export function combineObjects(a, b, combineFn) {
  const ret = { ...a, ...b };

  const commonKeys = setIntersection(
    new Set(Object.keys(a)),
    new Set(Object.keys(b))
  );
  for (const k of commonKeys) {
    ret[k] = combineFn(k, a[k], b[k]);
  }
  return ret;
}

export function getHumanFriendlyFolderName(folderName) {
  return folderName.split("/").at(-1);
}

export function getSubfolders(parentFolderName, allFolders) {
  parentFolderName ??= "";
  return allFolders.filter((f) =>
    parentFolderName === ""
      ? !f.folderName.includes("/")
      : f.folderName.startsWith(`${parentFolderName}/`) &&
        // only return immediate subfolders
        !f.folderName.slice(`${parentFolderName}/`.length).includes("/")
  );
}

export function leftPad(s, desiredLen, padChar) {
  return (
    new Array(Math.max(0, desiredLen - s.length)).fill(padChar).join("") + s
  );
}

export function secondsTommss(seconds) {
  return [
    leftPad(String(Math.floor(seconds / 60)), 2, "0"),
    leftPad(String(Math.floor(seconds % 60)), 2, "0"),
  ].join(":");
}

export function shallowEqual(obj1, obj2) {
  return (
    Object.keys(obj1).length === Object.keys(obj2).length &&
    Object.entries(obj1).every(
      ([k, v]) => obj2.hasOwnProperty(k) && obj2[k] === v
    )
  );
}

export function safeJsonParse(jsonStr, valIfError) {
  try {
    return JSON.parse(jsonStr);
  } catch (e) {
    return valIfError;
  }
}
