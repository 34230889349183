import Typography from "@mui/material/Typography";
import cx from "classnames";
import React from "react";

import CustomModal from "@@components/common/CustomModal";
import UserProfileForm from "@@components/common/UserProfileForm";
import {
  LoggedInUserContext,
  SetLoggedInUserContext,
} from "@@contexts/LoggedInUserContextsWrapper";

export default function GetUserNameModal({
  open,
  onClose,
  className,
  ...restProps
}) {
  const loggedInUser = React.useContext(LoggedInUserContext);
  const setLoggedInUser = React.useContext(SetLoggedInUserContext);

  return (
    <CustomModal
      className={cx("get-user-name-modal", className)}
      open={open}
      onClose={onClose}
      {...restProps}
    >
      <Typography variant="body2" style={{ marginBottom: "1rem" }}>
        What should we call you?
      </Typography>

      <UserProfileForm
        user={loggedInUser}
        onUserUpdated={setLoggedInUser}
        submitButtonText="Continue"
        sx={{ "& .phone-number-field": { display: "none" } }}
      />
    </CustomModal>
  );
}
