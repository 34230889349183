import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import cx from "classnames";
import React from "react";

function CloseButton({ className, IconProps, ...restProps }, ref) {
  return (
    <IconButton
      ref={ref}
      className={cx("close-button", className)}
      {...restProps}
    >
      <CloseIcon {...IconProps} />
    </IconButton>
  );
}

export default React.forwardRef(CloseButton);
