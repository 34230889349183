import React from "react";

export const WeddingPageModalsStateSettersContext = React.createContext(null);
WeddingPageModalsStateSettersContext.displayName =
  "WeddingPageModalsStateSettersContext";

export const WeddingPageSettingsPaneOpenContext = React.createContext(null);
WeddingPageSettingsPaneOpenContext.displayName =
  "WeddingPageSettingsPaneOpenContext";

export const WeddingPageMediaFiltersDrawerOpenContext =
  React.createContext(null);
WeddingPageMediaFiltersDrawerOpenContext.displayName =
  "WeddingPageMediaFiltersDrawerOpenContext";

export const WeddingPageAddMediaModalOpenContext = React.createContext(null);
WeddingPageAddMediaModalOpenContext.displayName =
  "WeddingPageAddMediaModalOpenContext";

export const WeddingPageAddGuestsModalOpenContext = React.createContext(null);
WeddingPageAddGuestsModalOpenContext.displayName =
  "WeddingPageAddGuestsModalOpenContext";

export const WeddingPageAddVendorModalOpenContext = React.createContext(null);
WeddingPageAddVendorModalOpenContext.displayName =
  "WeddingPageAddVendorModalOpenContext";

export default function WeddingPageLevelModalContextsWrapper({ children }) {
  const [weddingSettingsPaneOpen, setWeddingSettingsPaneOpen] =
    React.useState(false);
  const [mediaFiltersDrawerOpen, setMediaFiltersDrawerOpen] =
    React.useState(false);
  const [addMediaModalOpen, setAddMediaModalOpen] = React.useState(false);
  const [addGuestsModalOpen, setAddGuestsModalOpen] = React.useState(false);
  const [addVendorModalOpen, setAddVendorModalOpen] = React.useState(false);

  const setters = React.useMemo(
    () => ({
      setWeddingSettingsPaneOpen,
      setMediaFiltersDrawerOpen,
      setAddMediaModalOpen,
      setAddGuestsModalOpen,
      setAddVendorModalOpen,
    }),
    []
  );

  return (
    <WeddingPageModalsStateSettersContext.Provider value={setters}>
      <WeddingPageSettingsPaneOpenContext.Provider
        value={weddingSettingsPaneOpen}
      >
        <WeddingPageMediaFiltersDrawerOpenContext.Provider
          value={mediaFiltersDrawerOpen}
        >
          <WeddingPageAddMediaModalOpenContext.Provider
            value={addMediaModalOpen}
          >
            <WeddingPageAddGuestsModalOpenContext.Provider
              value={addGuestsModalOpen}
            >
              <WeddingPageAddVendorModalOpenContext.Provider
                value={addVendorModalOpen}
              >
                {children}
              </WeddingPageAddVendorModalOpenContext.Provider>
            </WeddingPageAddGuestsModalOpenContext.Provider>
          </WeddingPageAddMediaModalOpenContext.Provider>
        </WeddingPageMediaFiltersDrawerOpenContext.Provider>
      </WeddingPageSettingsPaneOpenContext.Provider>
    </WeddingPageModalsStateSettersContext.Provider>
  );
}
