import _ from "lodash";
import { fotobotApiAxiosInstance as axiosInstance } from "../utils/axiosInstances";

export async function sendVerificationCode(phoneNumber) {
  const { data } = await axiosInstance.post(`/sendVerificationCode`, {
    phoneNumber,
  });
  return data;
}

export async function loginWithOTP(payload) {
  if (!payload.phoneNumber || !payload.verificationCode) {
    throw new Error("Payload must contain phoneNumber and verificationCode");
  }

  const { data } = await axiosInstance.post(
    `/verifyPhoneNumber`,
    _.pickBy(payload)
  );

  return data;
}

export async function verifyWeddingPasscode(weddingId, passcode) {
  const { data } = await axiosInstance.post(`/verifyWeddingPasscode`, {
    weddingId,
    passcode,
  });
  return data;
}

export async function logout() {
  const { data } = await axiosInstance.post(`/logout`);
  return data;
}
