import StorefrontIcon from "@mui/icons-material/StorefrontRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import PrimaryButton from "@@components/common/Buttons/PrimaryButton";
import CardGrid from "@@components/common/CardGrid";
import ChangeableAvatar from "@@components/common/ChangeableAvatar";
import CustomModal from "@@components/common/CustomModal";
import { PRIVILEGED_WEDDING_ROLES } from "@@config";
import { CurrentWeddingContext } from "@@contexts/CurrentWeddingContexts";
import { LoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";
import {
  WeddingPageAddVendorModalOpenContext,
  WeddingPageModalsStateSettersContext,
} from "@@contexts/WeddingPageLevelModalContexts";
import {
  addWeddingVendor,
  changeVendorProfilePic,
  editWeddingVendorDetails,
  getWeddingVendors,
  removeWeddingVendor,
} from "@@services/fotobot-api.service";

import AddVendorForm from "./AddVendorForm";
import EditVendorForm from "./EditVendorForm";
import WeddingVendorCard from "./WeddingVendorCard";

const vendorEditableFields = [
  "name",
  "role",
  "adminPhoneNumber",
  "businessPhoneNumber",
  "website",
  "facebookUrl",
  "instagramUrl",
  "email",
];

export default function WeddingVendors({ onError }) {
  const theme = useTheme();

  const loggedInUser = React.useContext(LoggedInUserContext);
  const wedding = React.useContext(CurrentWeddingContext);
  const addVendorModalOpen = React.useContext(
    WeddingPageAddVendorModalOpenContext
  );
  const { setAddVendorModalOpen } = React.useContext(
    WeddingPageModalsStateSettersContext
  );
  const { enqueueSnackbar } = useSnackbar();

  const [vendors, setVendors] = React.useState([]);
  const [vawIdForEditing, setVawIdForEditing] = React.useState(null);
  const [vawIdForDeletion, setVawIdForDeletion] = React.useState(null);

  const closeAddVendorModal = () => setAddVendorModalOpen(false);
  const closeEditModal = () => setVawIdForEditing(null);
  const closeDeletionModal = () => setVawIdForDeletion(null);

  React.useEffect(() => {
    (async () => {
      try {
        const { data } = await getWeddingVendors(wedding.id);
        setVendors(data);
      } catch (e) {
        e.message = `Error fetching vendors: ${
          e.response?.data?.error ?? e.message
        }`;
        if (onError) {
          onError(e);
        } else {
          enqueueSnackbar(e.message, { variant: "error" });
        }
      }
    })();
  }, [wedding.id, onError, enqueueSnackbar]);

  const selectedVendor = vendors.find(
    (v) => v.vawId === (vawIdForEditing ?? vawIdForDeletion)
  );

  return (
    <Box
      className="wedding-vendors-container"
      sx={{
        margin: "0 1rem",
        // adding a `min-height: 100%` rule here instead of the conditional
        //   height rule below would be more elegant, but doesn't work: the
        //   browser is just ignoring the `height: 100%` on the child
        height: vendors.length === 0 ? "100%" : "auto",
      }}
    >
      {vendors.length === 0 ? (
        <Box
          className="no-vendors-cta flex-center-content"
          sx={{
            height: "100%",
            flexDirection: "column",
            gap: "1rem",
            color: "grey.main",
            "& p": { textAlign: "center" },
            [theme.breakpoints.down("md")]: {
              position: "relative",
              // micro-adjustments to make it look centered on xs/sm screens
              top: "-0.25rem",
            },
          }}
        >
          {PRIVILEGED_WEDDING_ROLES.includes(wedding.role) ? (
            <>
              <Typography>
                Use this tab to give a shout-out to the vendors helping to make
                your big event awesome :)
              </Typography>
              <PrimaryButton
                startIcon={<StorefrontIcon />}
                onClick={() => setAddVendorModalOpen(true)}
              >
                Add vendors
              </PrimaryButton>
            </>
          ) : (
            <Typography>Your host hasn't added any vendors yet ...</Typography>
          )}
        </Box>
      ) : (
        <CardGrid className="wedding-vendors">
          {vendors
            .sort((a, b) => a.role.localeCompare(b.role))
            .map((vendor) => (
              <WeddingVendorCard
                key={vendor.vawId}
                data={vendor}
                {...(loggedInUser?.phoneNumber === vendor.adminPhoneNumber ||
                PRIVILEGED_WEDDING_ROLES.includes(wedding.role)
                  ? {
                      showControls: true,
                      onDeleteButtonClick: () =>
                        setVawIdForDeletion(vendor.vawId),
                      onEditButtonClick: () => setVawIdForEditing(vendor.vawId),
                    }
                  : {})}
              />
            ))}
        </CardGrid>
      )}

      <CustomModal
        className="add-wedding-vendor-modal"
        open={addVendorModalOpen}
        onClose={closeAddVendorModal}
      >
        <Box>
          <Typography variant="h5" sx={{ marginBottom: "2rem" }}>
            Add a vendor
          </Typography>

          <AddVendorForm onSubmit={addVendor} />
        </Box>
      </CustomModal>

      <CustomModal
        className="edit-wedding-vendor-modal"
        open={!!vawIdForEditing}
        onClose={closeEditModal}
        BoxProps={{ sx: { borderRadius: 0 } }}
      >
        <Box>
          <Typography variant="h5" sx={{ marginBottom: "2rem" }}>
            Edit vendor information
          </Typography>

          <ChangeableAvatar
            src={selectedVendor?.profilePicUrl}
            alt="Vendor logo"
            sx={{
              width: "14rem",
              height: "14rem",
              margin: "0 auto 2rem auto",
            }}
            changeCtaText={"Change logo"}
            onChange={
              loggedInUser?.phoneNumber === selectedVendor?.adminPhoneNumber
                ? updateVendorProfilePic
                : null
            }
          >
            <StorefrontIcon sx={{ color: "grey.light", fontSize: "10rem" }} />
          </ChangeableAvatar>

          <EditVendorForm
            defaultData={_.pick(selectedVendor, vendorEditableFields)}
            onSubmit={updateVendorData}
          />
        </Box>
      </CustomModal>

      <CustomModal
        className="delete-wedding-vendor-modal"
        open={!!vawIdForDeletion}
        onClose={closeDeletionModal}
      >
        <Box>
          <Typography sx={{ marginBottom: "1rem" }}>
            Are you sure you want to remove this vendor relationship?
          </Typography>

          <Box sx={{ display: "flex", gap: "2rem" }}>
            <Button
              variant="contained"
              onClick={async () => {
                try {
                  await removeWeddingVendor(wedding.id, vawIdForDeletion);
                  setVendors((vendors) =>
                    vendors.filter(({ vawId }) => vawId !== vawIdForDeletion)
                  );
                  closeDeletionModal();
                } catch (e) {
                  enqueueSnackbar(e.message, { variant: "error" });
                }
              }}
            >
              Yes
            </Button>

            <Button variant="outlined" onClick={closeDeletionModal}>
              No
            </Button>
          </Box>
        </Box>
      </CustomModal>
    </Box>
  );

  async function addVendor(data) {
    try {
      const { data: addedVendor } = await addWeddingVendor(wedding.id, data);
      setVendors((v) => v.concat(addedVendor));
      closeAddVendorModal();

      enqueueSnackbar("Vendor added", { variant: "success" });
    } catch (e) {
      const errMsgFromBackend = e.response?.data?.error;
      enqueueSnackbar(
        `Error adding vendor` +
          (errMsgFromBackend ? `: ${errMsgFromBackend}` : ""),
        { variant: "error" }
      );
    }
  }

  async function updateVendorData(data) {
    const vawId = vawIdForEditing;
    const weddingId = wedding.id;
    const vendorId = selectedVendor.id;

    try {
      const { role, ...vendor } = data;

      const { data: updatedVendorData } = await editWeddingVendorDetails(
        weddingId,
        vawId,
        { role, vendor }
      );

      setVendors(
        vendors.map((v) =>
          v.vawId === vawId
            ? updatedVendorData
            : v.id === vendorId
            ? { ...updatedVendorData, role: v.role, vawId: v.vawId }
            : v
        )
      );
      closeEditModal();

      enqueueSnackbar("Vendor details updated", { variant: "success" });
    } catch (e) {
      enqueueSnackbar(
        `Error updating vendor information` +
          (e.response?.data?.error ? `: ${e.response.data.error}` : ""),
        { variant: "error" }
      );
      throw e;
    }
  }

  async function updateVendorProfilePic(file) {
    const vendorId = selectedVendor.id;

    try {
      const { data: updatedVendorData } = await changeVendorProfilePic(
        vendorId,
        file
      );
      const { profilePicUrl } = updatedVendorData;

      setVendors(
        vendors.map((v) => (v.id === vendorId ? { ...v, profilePicUrl } : v))
      );
    } catch (e) {
      enqueueSnackbar(
        `Error updating vendor logo` +
          (e.response?.data?.error ? `: ${e.response.data.error}` : ""),
        { variant: "error" }
      );
    }
  }
}
