import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import cx from "classnames";
import React from "react";
import { useForm } from "react-hook-form";

import CustomTextField from "@@components/common/CustomTextField";
import { isValidEmail, isValidPhone } from "@@utils/inputValidationUtils";

export default function EditVendorForm({
  defaultData,
  onSubmit,
  sx,
  className,
  ...restProps
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultData,
  });

  const [submitting, setSubmitting] = React.useState(false);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmitWrapper)}
      className={cx("edit-vendor-form", className)}
      sx={{ display: "flex", flexDirection: "column", gap: "1.5rem", ...sx }}
      {...restProps}
    >
      <CustomTextField
        label="Name *"
        error={errors.name}
        {...register("name", { required: true, minLength: 3 })}
      />

      <CustomTextField
        label="Role *"
        InputProps={{
          placeholder: "Ex: 'Photography', 'Event planning', 'Catering'",
        }}
        error={errors.role}
        {...register("role", { required: true, minLength: 3 })}
      />

      <CustomTextField
        label="Admin Phone *"
        helperText={`The phone number of the person from the vendor's side who should be able to manage the vendor's profile on Fotobot`}
        error={errors.adminPhoneNumber}
        InputProps={{ placeholder: "Phone no. with country code" }}
        {...register("adminPhoneNumber", {
          required: true,
          validate: isValidPhone,
        })}
      />

      <CustomTextField
        label="Business Phone"
        helperText={`The phone number people should call to place business enquiries with the vendor`}
        error={errors.businessPhoneNumber}
        InputProps={{ placeholder: "Phone no. with country code" }}
        {...register("businessPhoneNumber", {
          required: false,
          validate: isValidPhone,
        })}
      />

      <CustomTextField
        label="Website"
        error={errors.website}
        {...register("website")}
      />

      <CustomTextField
        label="Email"
        error={errors.email}
        helperText={errors.email?.message ?? ""}
        {...register("email", {
          required: false,
          validate: (email) => !email || isValidEmail(email),
        })}
      />

      <CustomTextField
        label="Facebook"
        error={errors.facebookUrl}
        {...register("facebookUrl")}
      />

      <CustomTextField
        label="Instagram"
        error={errors.instagramUrl}
        {...register("instagramUrl")}
      />

      <LoadingButton
        type="submit"
        variant="contained"
        loading={submitting}
        sx={{ alignSelf: "flex-start" }}
      >
        Submit
      </LoadingButton>
    </Box>
  );

  async function onSubmitWrapper(data) {
    setSubmitting(true);
    try {
      await onSubmit(data);
    } finally {
      setSubmitting(false);
    }
  }
}
