import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";

export default function NumPersonsFilter({
  mediaFilters,
  mediaFiltersDispatch,
}) {
  const [val, setVal] = React.useState(mediaFilters.numPersons.data);

  const word =
    mediaFilters.mediaType === "IMAGE"
      ? "photos"
      : mediaFilters.mediaType === "VIDEO"
      ? "videos"
      : "media";

  // slider value needs to be updated when a saved filter is applied
  React.useEffect(() => {
    setVal(mediaFilters.numPersons.data);
  }, [mediaFilters.numPersons.data]);

  return (
    <Box className="num-persons-filter">
      <Box sx={{ display: "flex", gap: "0.375rem" }}>
        <Typography color="grey.dark">Show {word} having </Typography>

        <TextField
          select
          variant="standard"
          size="small"
          value={mediaFilters.numPersons.type}
          sx={{
            position: "relative",
            top: "-1px",
            "& .MuiSelect-select": { paddingBottom: 0 },
            "& .MuiMenuItem-root": { color: "grey.dark" },
          }}
          onChange={(e) =>
            mediaFiltersDispatch({
              type: "UPDATE_FILTERS",
              data: {
                numPersons: {
                  type: e.target.value,
                  data: mediaFilters.numPersons.data,
                },
              },
            })
          }
        >
          <MenuItem value={"gte"}>at least</MenuItem>
          <MenuItem value={"eq"}>exactly</MenuItem>
        </TextField>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <Slider
          size="small"
          marks
          min={0}
          max={10}
          step={1}
          valueLabelDisplay="auto"
          value={val}
          onChange={(e, v) => setVal(v)}
          onChangeCommitted={(e, v) => {
            mediaFiltersDispatch({
              type: "UPDATE_FILTERS",
              data: {
                numPersons: {
                  type: mediaFilters.numPersons.type,
                  data: v,
                },
              },
            });
          }}
          sx={{
            "& .MuiSlider-thumb": {
              transform: `translate(${
                val === 0 ? 0 : val === 10 ? "-100%" : "-50%"
              }, -50%)`,
            },
          }}
        />

        <Typography sx={{ whiteSpace: "nowrap" }}>
          {" "}
          <span style={{ fontWeight: "lightbold" }}>{val}</span>{" "}
          {val === 1 ? "person" : "people"}
        </Typography>
      </Box>
    </Box>
  );
}
