import Box from "@mui/material/Box";
import cx from "classnames";

export default function CardGrid({ children, className, sx, ...restProps }) {
  return (
    <Box
      className={cx("card-grid", className)}
      sx={{
        display: "grid",
        gridTemplateColumns: {
          xs: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)",
          xl: "repeat(4, 1fr)",
        },
        justifyItems: { xs: "center", md: "start" },
        gap: "1rem",
      }}
      {...restProps}
    >
      {children}
    </Box>
  );
}
