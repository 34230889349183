import { Link, useRouteMatch } from "react-router-dom";

import BottomNavigation from "@@components/common/BottomNavigation";
import { weddingPageTabs as tabs } from "@@config";

export default function WeddingPageBottomNavigationBar() {
  const routeMatch = useRouteMatch("/weddings/:weddingId/:tabName?");

  const { tabName } = routeMatch.params;

  return (
    <BottomNavigation
      actions={tabs.map(({ name: label, icon }) => ({
        icon,
        label,
        component: Link,
        to: `/weddings/${routeMatch.params.weddingId}/${label}`,
      }))}
      value={tabs.findIndex((t) => t.name === tabName)}
    />
  );
}
