import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MailIcon from "@mui/icons-material/Mail";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LanguageIcon from "@mui/icons-material/Language";
import PhoneIcon from "@mui/icons-material/Phone";
import StorefrontIcon from "@mui/icons-material/StorefrontRounded";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import cx from "classnames";
import React from "react";

import Link from "@@components/common/AbsoluteLink";
import { PRIVILEGED_WEDDING_ROLES } from "@@config";
import { CurrentWeddingContext } from "@@contexts/CurrentWeddingContexts";
import { LoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";
import { prettyPhoneNumber } from "@@utils/strUtils";

export default function WeddingVendorCard({
  data,
  showControls,
  onEditButtonClick,
  onDeleteButtonClick,
  className,
  sx,
  ...restProps
}) {
  const wedding = React.useContext(CurrentWeddingContext);
  const loggedInUser = React.useContext(LoggedInUserContext);

  return (
    <Card
      variant="outlined"
      className={cx("wedding-vendor", className)}
      sx={{
        width: "100%",
        maxWidth: "25rem",
        backgroundColor: "grey.extralight",
        ...sx,
      }}
      {...restProps}
    >
      <CardContent
        sx={{
          padding: "1rem 1rem 0 1rem",
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Avatar
          alt={data.name}
          src={data.profilePicUrl}
          sx={{ width: "5rem", height: "5rem" }}
        >
          <StorefrontIcon sx={{ fontSize: "3rem" }} />
        </Avatar>

        <Box
          className="vendor-details"
          sx={{
            "& .vendor-info": {
              margin: "0.5rem 0",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              "& svg": {
                fontSize: "1.25rem",
                color: "grey.main",
              },
              "& p": {
                color: "grey.main",
              },
            },
          }}
        >
          <Typography
            component="h1"
            variant="body1"
            sx={{ fontWeight: "bold" }}
          >
            {data.name}
          </Typography>

          <Typography
            variant="body2"
            sx={{ marginBottom: "1rem", color: "grey.main" }}
          >
            {data.role}
          </Typography>

          {loggedInUser?.phoneNumber === data.adminPhoneNumber ||
          PRIVILEGED_WEDDING_ROLES.includes(wedding.role) ? (
            <Box className="vendor-info">
              <PhoneIcon />
              <Typography variant="body2">
                {data.adminPhoneNumber
                  ? prettyPhoneNumber(data.adminPhoneNumber)
                  : "-"}
              </Typography>
            </Box>
          ) : null}

          <Box className="vendor-info">
            <SupportAgentIcon />
            <Typography variant="body2">
              {data.businessPhoneNumber
                ? prettyPhoneNumber(data.businessPhoneNumber)
                : "-"}
            </Typography>
          </Box>
        </Box>
      </CardContent>

      <CardActions
        sx={{
          padding: "0.5rem 1rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          className="wedding-vendor-links"
          sx={{
            display: "flex",
            gap: "1rem",
            "& a": {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
            "& svg.disabled": {
              color: "grey.main",
              cursor: "not-allowed",
            },
          }}
        >
          {data.website ? (
            <Link href={data.website} target="_blank" disabled>
              <LanguageIcon />
            </Link>
          ) : (
            <LanguageIcon className="disabled" />
          )}

          {data.instagramUrl ? (
            <Link href={data.instagramUrl} target="_blank">
              <InstagramIcon />
            </Link>
          ) : (
            <InstagramIcon className="disabled" />
          )}

          {data.facebookUrl ? (
            <Link href={data.facebookUrl} target="_blank">
              <FacebookIcon sx={{ transform: "scale(1.05)" }} />
            </Link>
          ) : (
            <FacebookIcon
              className="disabled"
              sx={{ transform: "scale(1.05)" }}
            />
          )}

          {data.email ? (
            <Link href={`mailto:${data.email}`} target="_blank">
              <MailIcon sx={{ transform: "scale(1.1)" }} />
            </Link>
          ) : (
            <MailIcon className="disabled" sx={{ transform: "scale(1.1)" }} />
          )}
        </Box>

        {showControls ? (
          <Box className="wedding-vendor-controls" sx={{ display: "flex" }}>
            <IconButton color="primary" onClick={onEditButtonClick}>
              <EditIcon />
            </IconButton>

            <IconButton color="primary" onClick={onDeleteButtonClick}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ) : null}
      </CardActions>
    </Card>
  );
}
