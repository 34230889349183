import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import cx from "classnames";
import React from "react";

import MultiItemCarousel from "@@components/common/MultiItemCarousel";
import WeddingImageContainer from "./WeddingImageContainer";

function WeddingPhotoCarouselElement(
  {
    photos,
    selectedMediaId,
    setSelectedMediaId,
    onClose,
    className,
    sx,
    ...restProps
  },
  ref
) {
  const theme = useTheme();

  const mainPhotoIndex = Math.max(
    0,
    photos.findIndex((p) => p.id === selectedMediaId)
  );
  const mainPhoto = photos[mainPhotoIndex];

  return (
    <Box
      ref={ref}
      {...restProps}
      className={cx(className, "photos-group")}
      sx={{
        ...sx,
        width: "100%",
        height: "100%",
        position: "relative",
      }}
      onClick={onClose}
    >
      <WeddingImageContainer
        className="photos-group-selected-photo"
        media={mainPhoto}
        showBoundingBoxes
        // to make bounding box popper appear above carousel controls
        BoundingBoxProps={{ PopperProps: { sx: { zIndex: 1301 } } }}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: `translate(-50%, -50%)`,
        }}
        WeddingImageProps={{
          style: {
            // percentages don't work properly here, because the WeddingImageContainer
            //   component does not (and is not supposed to) have a width/height
            //   (see comment in WeddingImageContainer component defn. file for reason)
            maxWidth: "100vw",
            maxHeight: photos.length > 1 ? "80vh" : "100vh",
          },
        }}
        onClick={(e) => e.stopPropagation()}
      />

      {photos.length > 1 ? (
        <MultiItemCarousel
          className="photos-group-all-photos"
          elems={photos}
          defaultSelectedIdx={mainPhotoIndex}
          onChange={(idx) => setSelectedMediaId(photos[idx].id)}
          sx={{
            position: "absolute",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            maxWidth: { xs: "100%", sm: "40rem" },
          }}
          renderElem={(p, { className, sx, ...restProps }, ref) => (
            <WeddingImageContainer
              ref={ref}
              key={p.id}
              className={cx(className, "photos-group-photo")}
              media={p}
              sx={{
                "& .wedding-image:hover": {
                  border: `1px solid ${theme.palette.grey.light}`,
                },
                ...sx,
              }}
              WeddingImageProps={{
                style: {
                  maxHeight: className.includes("selected") ? "3.6rem" : "3rem",
                  minWidth: className.includes("selected") ? "3rem" : "2.5rem",
                  border: className.includes("selected")
                    ? `1px solid ${theme.palette.grey.light}`
                    : 0,
                  cursor: "pointer",
                },
              }}
              {...restProps}
            />
          )}
          onClick={(e) => e.stopPropagation()}
        />
      ) : null}
    </Box>
  );
}

export default React.forwardRef(WeddingPhotoCarouselElement);
