import _ from "lodash";

export default function weddingMediaUploadStatsReducer(state, action) {
  const { type, weddingId, data } = action;

  if (type === "REFRESH_STATS") {
    return {
      // preserving LATEST...TIME here prevents an unnecessary API call
      //   to refresh stats after all uploads are complete
      ..._.pick(state, ["LATEST_UPLOAD_REQUEST_TIME"]),
      // ensures we don't lose stats for weddings where the user has just
      //   started an upload request that hasn't completed yet
      ...Object.fromEntries(
        Object.entries(state).filter(
          ([key, val]) =>
            Number.isInteger(+key) && !_.isEmpty(val.SENDING_IN_PROGRESS)
        )
      ),
      ...Object.fromEntries(
        Object.entries(data).map(([weddingId, stats]) => [
          weddingId,
          {
            ...stats,
            ..._.pick(state[weddingId], ["SENDING_IN_PROGRESS"]),
          },
        ])
      ),
    };
  } else if (type === "DELETE_WEDDING") {
    return _.omit(state, [weddingId]);
  } else if (type === "SET_WEDDING") {
    return { ...state, [weddingId]: data };
  } else if (type === "SENDING_FILES") {
    const { uploadOperationId, nFiles, totalBytes } = data;

    return {
      ...state,
      [weddingId]: {
        ...state[weddingId],
        SENDING_IN_PROGRESS: {
          ...state[weddingId]?.SENDING_IN_PROGRESS,
          [uploadOperationId]: { nFiles, sentBytes: 0, totalBytes },
        },
      },
      LATEST_UPLOAD_REQUEST_TIME: +new Date(),
    };
  } else if (type === "SENDING_FILES_PROGRESS") {
    const { uploadOperationId, sentBytes, totalBytes } = data;
    return {
      ...state,
      [weddingId]: {
        ...state[weddingId],
        SENDING_IN_PROGRESS: {
          ...state[weddingId].SENDING_IN_PROGRESS,
          [uploadOperationId]: {
            ...state[weddingId].SENDING_IN_PROGRESS[uploadOperationId],
            sentBytes,
            totalBytes,
          },
        },
      },
    };
  } else if (type === "FINISHED_SENDING_FILES") {
    const { uploadOperationId } = data;

    const { nFiles } = state[weddingId].SENDING_IN_PROGRESS[uploadOperationId];

    return {
      ...state,
      [weddingId]: {
        ...state[weddingId],
        // if we don't set nReceived here, the 1st useEffect ends up
        //   clearing the upload-stats-refresh-interval
        nReceived: (state[weddingId].nReceived ?? 0) + nFiles,
        SENDING_IN_PROGRESS: _.omit(
          state[weddingId].SENDING_IN_PROGRESS,
          uploadOperationId
        ),
      },
    };
  } else {
    throw new Error(`Invalid action type: ${type}`);
  }
}
