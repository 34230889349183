import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import cx from "classnames";
import { useSnackbar } from "notistack";
import React from "react";

import WeddingMediaDispatchContext from "@@contexts/WeddingMediaDispatchContext";
import { deleteWeddingMedia } from "@@services/wedding-media.service";

export default function WeddingMediaDeleteButton({
  media,
  disabled,
  onClick,
  className,
  sx,
  ...restProps
}) {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const mediaDispatch = React.useContext(WeddingMediaDispatchContext);

  if (!media) return null;

  return (
    <IconButton
      disableRipple
      className={cx("wedding-media-delete-icon", className)}
      sx={{ color: theme.palette.primary.light, padding: 0, ...sx }}
      disabled={disabled}
      onClick={deleteMedia}
      {...restProps}
    >
      <DeleteForeverIcon />
    </IconButton>
  );

  async function deleteMedia() {
    mediaDispatch({ type: "DELETE", data: media });
    try {
      await deleteWeddingMedia(media.weddingId, media.id);
    } catch (e) {
      mediaDispatch({ type: "UNDO_DELETE", data: media });

      const errMsg = e.response?.data.error ?? e.message;
      enqueueSnackbar(`Error deleting media: ${errMsg}`, { variant: "error" });
    }
  }
}
