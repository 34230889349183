import React from "react";

import CreateWeddingModal from "@@components/common/CreateWeddingModal";
import FindWeddingModal from "@@components/common/FindWeddingModal";
import LoginModal from "@@components/common/LoginModal";
import SignupModal from "@@components/common/SignupModal";
import PwaInstallModal from "@@components/common/PwaInstallModal";
import GetUserNameModal from "@@components/common/GetUserNameModal";
import GetUserSelfieModal from "@@components/common/GetUserSelfieModal";
import UserProfileModal from "@@components/common/UserProfileModal";

import { LoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";

export default function TopLevelModalsWrapper({ children }) {
  const loggedInUser = React.useContext(LoggedInUserContext);

  const [loginModalOpen, setLoginModalOpen] = React.useState(false);
  const [signupModalOpen, setSignupModalOpen] = React.useState(false);
  const [createWeddingModalOpen, setCreateWeddingModalOpen] =
    React.useState(false);
  const closeCreateWeddingModal = () => setCreateWeddingModalOpen(false);
  const [findWeddingModalOpen, setFindWeddingModalOpen] = React.useState(false);
  const [pwaInstallModalOpen, setPwaInstallModalOpen] = React.useState(false);
  const [getUserSelfieModalOpen, setGetUserSelfieModalOpen] =
    React.useState(false);
  const [userProfileModalOpen, setUserProfileModalOpen] = React.useState(false);

  const setters = React.useMemo(
    () => ({
      setSignupModalOpen,
      setLoginModalOpen,
      setCreateWeddingModalOpen,
      setFindWeddingModalOpen,
      setPwaInstallModalOpen,
      setGetUserSelfieModalOpen,
      setUserProfileModalOpen,
    }),
    []
  );

  return (
    <TopLevelModalsOpenStateSettersContext.Provider value={setters}>
      <CreateWeddingModalOpenStateContext.Provider
        value={createWeddingModalOpen}
      >
        <FindWeddingModalOpenStateContext.Provider value={findWeddingModalOpen}>
          {children}

          <LoginModal
            open={loginModalOpen}
            onClose={(evt, reason) => {
              setLoginModalOpen(false);

              // analytics
              if (reason === "loginSuccess") {
                window.dataLayer.push({
                  event: "login",
                  login_method: "login_form",
                });
              } else {
                window.dataLayer.push({
                  event: "login_modal_closed_prematurely",
                });
              }
            }}
          />

          <SignupModal
            open={signupModalOpen}
            onClose={(evt, reason) => {
              setSignupModalOpen(false);

              // analytics
              if (reason === "signupSuccess") {
                window.dataLayer.push({
                  event: "signup",
                });
              } else {
                window.dataLayer.push({
                  event: "signup_modal_closed_prematurely",
                });
              }
            }}
          />

          <CreateWeddingModal
            open={createWeddingModalOpen}
            onClose={closeCreateWeddingModal}
          />

          <FindWeddingModal
            open={findWeddingModalOpen}
            onClose={() => setFindWeddingModalOpen(false)}
          />

          <PwaInstallModal
            open={pwaInstallModalOpen}
            onClose={() => setPwaInstallModalOpen(false)}
          />

          <GetUserNameModal
            open={Boolean(loggedInUser && !loggedInUser.firstName)}
            hideCloseButton
          />

          <GetUserSelfieModal
            open={getUserSelfieModalOpen}
            onClose={() => setGetUserSelfieModalOpen(false)}
          />

          <UserProfileModal
            open={userProfileModalOpen}
            onClose={() => setUserProfileModalOpen(false)}
          />
        </FindWeddingModalOpenStateContext.Provider>
      </CreateWeddingModalOpenStateContext.Provider>
    </TopLevelModalsOpenStateSettersContext.Provider>
  );
}

export const TopLevelModalsOpenStateSettersContext = React.createContext({});
TopLevelModalsOpenStateSettersContext.displayName =
  "TopLevelModalsOpenStateSettersContext";

export const CreateWeddingModalOpenStateContext = React.createContext(null);
CreateWeddingModalOpenStateContext.displayName =
  "CreateWeddingModalOpenStateContext";

export const FindWeddingModalOpenStateContext = React.createContext(null);
FindWeddingModalOpenStateContext.displayName =
  "FindWeddingModalOpenStateContext";
