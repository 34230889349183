import { fotobotApiAxiosInstance as axiosInstance } from "@@utils/axiosInstances";

export async function getFolders(weddingId) {
  const { data } = await axiosInstance.get(
    `/weddings/${weddingId}/weddingFolders`
  );
  return data;
}

export async function createFolder(weddingId, folderName, parentFolderId) {
  const { data } = await axiosInstance.post(
    `/weddings/${weddingId}/weddingFolders`,
    { folderName, parentFolderId }
  );
  return data;
}

export async function renameFolder(weddingId, weddingFolderId, folderName) {
  const { data } = await axiosInstance.patch(
    `/weddings/${weddingId}/weddingFolders/${weddingFolderId}`,
    { folderName }
  );
  return data;
}

export async function deleteFolder(weddingId, weddingFolderId) {
  const { data } = await axiosInstance.delete(
    `/weddings/${weddingId}/weddingFolders/${weddingFolderId}`
  );
  return data;
}
