import _ from "lodash";
import Box from "@mui/material/Box";
import cx from "classnames";
import React from "react";

import FaceBoundingBox from "./FaceBoundingBox";
import WeddingImage from "./WeddingImage";
import WeddingMediaOverlay from "../WeddingMediaOverlay/WeddingMediaOverlay";

function WeddingImageContainer(
  {
    media,
    showOverlay,
    showBoundingBoxes,
    className,
    sx,
    WeddingImageProps,
    BoundingBoxProps,
    ...restProps
  },
  ref
) {
  const [boundingBoxes, setBoundingBoxes] = React.useState([]);
  const imgRef = React.useRef(null);

  React.useEffect(() => {
    setBoundingBoxes([]);
  }, [media]);

  const computeBoundingBoxPositionAttrs = React.useCallback(() => {
    if (!imgRef.current) return;

    const paddingPx = 10;

    setBoundingBoxes(
      media.usersRecognizedInMedia.map(({ boundingBox: bb, ...restProps }) => ({
        ...restProps,
        position: {
          top: Math.max(0, bb.Top * imgRef.current.height - paddingPx),
          left: Math.max(0, bb.Left * imgRef.current.width - paddingPx),
          width: Math.min(
            imgRef.current.width,
            bb.Width * imgRef.current.width + 2 * paddingPx
          ),
          height: Math.min(
            imgRef.current.height,
            bb.Height * imgRef.current.height + 2 * paddingPx
          ),
        },
      }))
    );
  }, [media]);

  React.useEffect(() => {
    const debounced = _.debounce(computeBoundingBoxPositionAttrs, 250);
    const recomputeBoundingBoxes = () => {
      setBoundingBoxes([]);
      debounced();
    };

    window.addEventListener("resize", recomputeBoundingBoxes);
    return () => window.removeEventListener("resize", recomputeBoundingBoxes);
  }, [computeBoundingBoxPositionAttrs]);

  return (
    <Box
      ref={ref}
      className={cx("wedding-image-container", className)}
      sx={{ position: "relative", ...sx }}
      {...restProps}
    >
      <WeddingImage
        media={media}
        ref={imgRef}
        onLoad={computeBoundingBoxPositionAttrs}
        // for the bounding boxes to show up in the correct positions,
        //   the WeddingImageContainer component and the image itself must be the
        //   same size and be in the same position
        style={{
          maxHeight: "100%",
          maxWidth: "100%",
          ...WeddingImageProps.style,
        }}
        {...(showBoundingBoxes ? { crossOrigin: "anonymous" } : {})}
        {..._.omit(WeddingImageProps, "style")}
      />

      {showBoundingBoxes
        ? boundingBoxes.map((data) => (
            <FaceBoundingBox
              key={`${data.faceId}__${data.recognizedUser?.firstName}`}
              imgRef={imgRef}
              data={data}
              weddingId={media.weddingId}
              media={media}
              sx={{ position: "absolute", ...data.position }}
              {...BoundingBoxProps}
            />
          ))
        : null}

      {showOverlay ? (
        <WeddingMediaOverlay
          media={media}
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 100,
          }}
        />
      ) : null}
    </Box>
  );
}

export default React.forwardRef(WeddingImageContainer);
