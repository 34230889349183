import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import cx from "classnames";
import React from "react";

import { LoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";

export default function WeddingMediaAddedByStatement({
  media,
  className,
  sx,
  ...restProps
}) {
  const loggedInUser = React.useContext(LoggedInUserContext);

  if (!media) return null;

  return (
    <Typography
      variant="caption"
      className={cx("wedding-media-added-by", className)}
      sx={{ ...sx }}
      {...restProps}
    >
      Added by{" "}
      <Box component="span" sx={{ fontWeight: "bold" }}>
        {!media.uploader
          ? "anonymous"
          : media.uploader.id === loggedInUser?.id
          ? "You"
          : media.uploader?.firstName ?? "?"}
      </Box>
    </Typography>
  );
}
