export function setIntersection(a, b) {
  return new Set(Array.from(a.values()).filter((val) => b.has(val)));
}

export function setUnion(a, b) {
  return new Set(Array.from(a.values()).concat(Array.from(b.values())));
}

export function setDiff(a, b) {
  return new Set(Array.from(a.values()).filter((val) => !b.has(val)));
}
