export function atPageBottom(thresholdPx = 100) {
  // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#determine_if_an_element_has_been_totally_scrolled
  const { documentElement: doc } = window.document;
  const pxFromPageBottom =
    doc.scrollHeight - (doc.scrollTop + doc.clientHeight);
  return pxFromPageBottom < thresholdPx;
}

export function atPageTop() {
  const { documentElement: doc } = window.document;
  return doc.scrollTop === 0;
}

export function fileSystemFileEntryToFile(fsFileEntry) {
  return new Promise((resolve, reject) =>
    fsFileEntry.file((file) => {
      // While firefox ensures the file's full path is available
      //   at .webkitRelativePath, chrome doesn't. So we need to do
      //   some extra work to ensure we don't lose it
      // Also, webkitRelativePath is a read-only property of File objects,
      //   so we'll have to create a different location to hold the full path
      file.__meta = { fullPath: fsFileEntry.fullPath };
      resolve(file);
    }, reject)
  );
}

export function readFileSystemDirectoryEntry(fsDirectoryEntry) {
  return new Promise((resolve, reject) => {
    const entries = [];
    const fsdReader = fsDirectoryEntry.createReader();
    readEntries();

    function readEntries() {
      fsdReader.readEntries((entriesInCurIter) => {
        if (entriesInCurIter.length === 0) {
          resolve(entries);
        } else {
          entries.push(...entriesInCurIter);
          readEntries();
        }
      }, reject);
    }
  });
}

export async function dataTransferItemsToFiles(dataTransferItems) {
  const fsFileEntries = [];

  const bfsQueue = [];
  for (let i = 0; i < dataTransferItems.length; i++) {
    bfsQueue.push(dataTransferItems[i].webkitGetAsEntry());
  }
  while (bfsQueue.length > 0) {
    const curItem = bfsQueue.shift();
    if (curItem?.isFile) {
      fsFileEntries.push(curItem);
    } else if (curItem?.isDirectory) {
      bfsQueue.push(...(await readFileSystemDirectoryEntry(curItem)));
    }
  }

  return Promise.all(fsFileEntries.map(fileSystemFileEntryToFile));
}

export function scrollTo(opts) {
  const { documentElement: doc } = window.document;

  if (opts.bottom) {
    opts.top = doc.scrollHeight - (doc.clientHeight + opts.bottom);
    delete opts.bottom;
  }

  if (opts.right) {
    opts.left = doc.scrollWidth - (doc.clientWidth + opts.right);
    delete opts.right;
  }

  window.scroll({ behavior: "smooth", ...opts });
}

export function remToPx(rem) {
  return (
    parseFloat(rem) *
    parseFloat(getComputedStyle(document.documentElement).fontSize)
  );
}

export function pxToRem(px) {
  return parseFloat(px) / remToPx(1);
}

export function blobToBase64(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export function dataUrlToFile(dataUrl) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataUrl.split(",")[0].indexOf("base64") >= 0) {
    byteString = atob(dataUrl.split(",")[1]);
  } else {
    byteString = decodeURIComponent(dataUrl.split(",")[1]);
  }

  // separate out the mime component
  const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new File([ia], "selfie", { type: mimeString });
}

export function extractFaceThumbnailAsDataUrl(htmlImageElement, position) {
  const canvas = document.createElement("canvas");
  canvas.width = "64";
  canvas.height = "64";

  const ctx = canvas.getContext("2d");
  ctx.drawImage(
    htmlImageElement,
    position.left,
    position.top,
    position.width,
    position.height,
    0,
    0,
    64,
    64
  );

  return canvas.toDataURL("image/jpeg");
}

export async function resizeImageFile(imageFile, maxWidthPx) {
  const imageBitmap = await createImageBitmap(imageFile);
  const { height: originalHeight, width: originalWidth } = imageBitmap;
  const aspectRatio = originalWidth / originalHeight;

  const resizedWidth = Math.min(originalWidth, maxWidthPx);
  const resizedHeight = resizedWidth / aspectRatio;

  const canvas = document.createElement("canvas");
  canvas.width = `${resizedWidth}`;
  canvas.height = `${resizedHeight}`;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(imageBitmap, 0, 0, resizedWidth, resizedHeight);

  const blob = await canvasToBlob(canvas, imageFile.type);
  return new File([blob], imageFile.name, { type: imageFile.type });
}

export function canvasToBlob(canvas, ...restArgs) {
  return new Promise((resolve) => canvas.toBlob(resolve, ...restArgs));
}
