import _ from "lodash";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import fscreen from "fscreen";
import IconButton from "@mui/material/IconButton";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import React from "react";

import "animate.css";

import { delayMs } from "@@utils";

const slideshowFrequencyOptionsSeconds = [1, 5, 10, 15, 20];
const slideshowAnimationClasses = {
  ENTRY: ["animate__animated", "animate__fadeIn"],
  EXIT: ["animate__fadeOut"],
};

function Carousel({
  elems,
  initialSelectedElemIdx,
  renderElem,
  sx,
  className,
  SlideshowControlsProps,
  onSlideshowStart,
  ...restProps
}) {
  const carouselRef = React.useRef(null);
  const touchStartCoords = React.useRef({});
  const slideshowIntervalRef = React.useRef(null);
  const renderedElemRef = React.useRef(null);

  const [curElemIdx, setCurElemIdx] = React.useState(initialSelectedElemIdx);
  const [slideshowPlaying, setSlideshowPlaying] = React.useState(false);
  const [
    slideshowPhotoChangeFrequencySeconds,
    setSlideshowPhotoChangeFrequencySeconds,
  ] = React.useState(slideshowFrequencyOptionsSeconds[0]);

  const showPrev = React.useCallback(
    ({ loop } = {}) =>
      setCurElemIdx((idx) =>
        loop ? (idx === 0 ? elems.length - 1 : --idx) : Math.max(--idx, 0)
      ),
    [elems]
  );
  const showNext = React.useCallback(
    async ({ loop, animate } = {}) => {
      if (animate) {
        renderedElemRef.current.classList.add(
          ...slideshowAnimationClasses.EXIT
        );
        await delayMs(250);
        renderedElemRef.current.classList.remove(
          ...slideshowAnimationClasses.EXIT
        );
      }

      setCurElemIdx((idx) =>
        loop
          ? idx === elems.length - 1
            ? 0
            : ++idx
          : Math.min(++idx, elems.length - 1)
      );
    },
    [elems]
  );

  // allow user to browse carousel with left/right arrow keys
  React.useEffect(() => {
    const cur = carouselRef.current;
    cur.addEventListener("keydown", keydownHandler);
    return () => cur.removeEventListener("keydown", keydownHandler);

    function keydownHandler({ key }) {
      if (key === "ArrowLeft") {
        showPrev();
      } else if (key === "ArrowRight") {
        showNext();
      } else {
        // do nothing
      }
    }
  }, [showNext, showPrev]);

  // allow user to browse carousel by swiping
  React.useEffect(() => {
    const cur = carouselRef.current;
    cur.addEventListener("touchstart", touchStartHandler);
    cur.addEventListener("touchend", touchEndHandler);

    return () => {
      cur.removeEventListener("touchstart", touchStartHandler);
      cur.removeEventListener("touchend", touchEndHandler);
    };

    function touchStartHandler(e) {
      touchStartCoords.current.x = e.changedTouches[0].screenX;
      touchStartCoords.current.y = e.changedTouches[0].screenY;
    }

    function touchEndHandler(e) {
      const { x: touchStartX } = touchStartCoords.current;
      const { screenX: touchEndX } = e.changedTouches[0];

      const minDistance = 50;

      if (touchStartX - touchEndX <= -minDistance) {
        // left->right swipe
        showPrev();
      } else if (touchStartX - touchEndX >= minDistance) {
        // right->left swipe
        showNext();
      } else {
        // do nothing
      }
    }
  }, [showNext, showPrev]);

  React.useEffect(() => {
    if (slideshowPlaying) {
      renderedElemRef.current.classList.add(...slideshowAnimationClasses.ENTRY);
      slideshowIntervalRef.current = setInterval(
        () => showNext({ loop: true, animate: true }),
        slideshowPhotoChangeFrequencySeconds * 1000
      );

      if (!fscreen.fullscreenElement) {
        fscreen.requestFullscreen(renderedElemRef.current);
      }
    } else {
      renderedElemRef.current.classList.remove(
        ...slideshowAnimationClasses.ENTRY
      );
      clearInterval(slideshowIntervalRef.current);
    }

    return () => clearInterval(slideshowIntervalRef.current);
  }, [showNext, slideshowPhotoChangeFrequencySeconds, slideshowPlaying]);

  // stop slideshow mode when fullscreen exited
  React.useEffect(() => {
    fscreen.addEventListener("fullscreenchange", fullscreenChangeHandler);
    return () =>
      fscreen.removeEventListener("fullscreenchange", fullscreenChangeHandler);

    function fullscreenChangeHandler() {
      if (!fscreen.fullscreenElement) setSlideshowPlaying(false);
    }
  }, []);

  return (
    <Box
      ref={carouselRef}
      className="carousel"
      // allows elem to be focused, which allows keydown events
      //   to be registered
      tabIndex="0"
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& .carousel-browse-button": {
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 2,
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          color: "grey.light",
          margin: "0.5rem",
          padding: 0,
          "&:hover": { color: "white" },
          "&:disabled": { backgroundColor: "transparent", color: "grey.main" },
        },
        ...sx,
      }}
      {...restProps}
    >
      {renderElem(elems[curElemIdx], {}, renderedElemRef)}

      <IconButton
        disableRipple
        className="carousel-browse-button"
        onClick={(e) => {
          e.stopPropagation();
          showPrev();
        }}
        disabled={curElemIdx === 0}
        sx={{ left: 0 }}
      >
        <ChevronLeftIcon fontSize="large" />
      </IconButton>

      <IconButton
        disableRipple
        className="carousel-browse-button"
        onClick={(e) => {
          e.stopPropagation();
          showNext();
        }}
        disabled={curElemIdx === elems.length - 1}
        sx={{ right: 0 }}
      >
        <ChevronRightIcon fontSize="large" />
      </IconButton>

      <Box
        className="slideshow-controls"
        sx={{
          position: "absolute",
          zIndex: 100, // render above modal backdrop and content
          bottom: "1rem",
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.5rem",
          ...SlideshowControlsProps?.sx,
        }}
        {..._.omit(SlideshowControlsProps, ["sx"])}
      >
        <Button
          className="counter-weight"
          sx={{ visibility: "hidden", minWidth: "2rem" }}
        />

        <IconButton
          size="small"
          onClick={() => {
            if (!slideshowPlaying) onSlideshowStart();
            setSlideshowPlaying(!slideshowPlaying);
          }}
          sx={{
            padding: "0.5rem",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            color: "grey.light",
            "& :hover": { color: "white" },
          }}
        >
          {slideshowPlaying ? (
            <PauseIcon sx={{ fontSize: "3rem" }} />
          ) : (
            <PlayArrowIcon sx={{ fontSize: "3rem" }} />
          )}
        </IconButton>

        <Button
          size="small"
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            borderRadius: "50%",
            color: "grey.light",
            textTransform: "none",
            minWidth: "2rem",
            "& :hover": { color: "white" },
          }}
          onClick={() =>
            setSlideshowPhotoChangeFrequencySeconds(
              (x) =>
                slideshowFrequencyOptionsSeconds[
                  (slideshowFrequencyOptionsSeconds.indexOf(x) + 1) %
                    slideshowFrequencyOptionsSeconds.length
                ]
            )
          }
        >
          {slideshowPhotoChangeFrequencySeconds}s
        </Button>
      </Box>
    </Box>
  );
}

export default Carousel;
