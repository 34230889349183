import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import CustomModal from "@@components/common/CustomModal";
import CustomTextField from "@@components/common/CustomTextField";
import {
  DropTargetFolderContext,
  SetDropTargetFolderContext,
} from "@@contexts/DropTargetFolderContexts";
import { CurrentWeddingFoldersDispatchContext } from "@@contexts/CurrentWeddingFoldersContexts";
import {
  deleteFolder,
  renameFolder,
} from "@@services/wedding-folder.service.js";
import { getHumanFriendlyFolderName } from "@@utils";
import folderImage from "@@img/folder300.png";

const FolderNameForm = React.forwardRef(FolderNameForm_);

export default function WeddingFolder({ href, showOptionsMenu, folder }) {
  const dropTargetFolder = React.useContext(DropTargetFolderContext);
  const setDropTargetFolder = React.useContext(SetDropTargetFolderContext);
  const currentWeddingFoldersDispatch = React.useContext(
    CurrentWeddingFoldersDispatchContext
  );
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  // grep DRAG_COUNTER_REF for explanation
  const dragCounterRef = React.useRef(0);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [nameEditMode, setNameEditMode] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const onCloseDeleteModal = (e) => setOpenDeleteModal(false);

  const { id, folderName } = folder;
  const humanFriendlyFolderName = getHumanFriendlyFolderName(folderName);

  return (
    <Box
      className="wedding-folder"
      sx={{
        width: "10rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
        ...(dropTargetFolder?.id === id
          ? {
              backgroundColor: theme.palette.grey.extralight,
              border: `1px solid ${theme.palette.grey.light}`,
            }
          : {}),
      }}
      onDragEnter={(e) => {
        e.preventDefault();
        setDropTargetFolder(folder);
        dragCounterRef.current++;
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        if (--dragCounterRef.current === 0) {
          setDropTargetFolder((dtFolder) =>
            // when the user moves the mouse from one folder to another quickly,
            //   the onDragEnter handler of the second folder sometimes fires
            //   before the onDragLeave handler of the first one; we don't want
            //   to set dropTargetFolder to null when that happens
            dtFolder.id === id ? null : dtFolder
          );
        }
      }}
    >
      <Box component={Link} to={href}>
        <Box component="img" src={folderImage} sx={{ width: "100%" }} />
      </Box>

      {nameEditMode ? (
        <ClickAwayListener onClickAway={() => setNameEditMode(false)}>
          <FolderNameForm
            weddingFolder={folder}
            afterSubmit={() => setNameEditMode(false)}
          />
        </ClickAwayListener>
      ) : (
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            padding: "0 1.5rem",
          }}
        >
          <Box
            component={Link}
            to={href}
            sx={{
              flexGrow: 1,
              lineHeight: "normal",
              textAlign: "center",
              textDecoration: "none",
            }}
          >
            <Typography
              variant="caption"
              className="folder-name"
              sx={{ color: theme.palette.grey.dark }}
            >
              {humanFriendlyFolderName}
            </Typography>
          </Box>

          <Box
            className="folder-menu"
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              height: "100%",
              display: showOptionsMenu ? "flex" : "none",
              alignItems: "center",
            }}
          >
            <IconButton
              disableRipple
              sx={{ height: "100%", padding: "0.25rem" }}
              onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
            >
              <MoreVertIcon sx={{ width: "1rem", height: "1rem" }} />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={(e) => setAnchorEl(null)}
              MenuListProps={{
                dense: true,
                sx: { color: theme.palette.grey.dark },
              }}
            >
              <MenuItem
                onClick={(e) => {
                  setNameEditMode(true);
                  setAnchorEl(null);
                }}
              >
                <Typography variant="caption">Rename</Typography>
              </MenuItem>

              <MenuItem
                onClick={(e) => {
                  if (folder.otherData.preventDelete) {
                    enqueueSnackbar("This folder cannot be deleted", {
                      variant: "error",
                    });
                  } else {
                    setOpenDeleteModal(true);
                  }
                }}
              >
                <Typography variant="caption">Delete</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      )}

      <CustomModal
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        className="delete-folder-modal"
        BoxProps={{
          sx: { display: "flex", flexDirection: "column", gap: "1rem" },
        }}
      >
        <Typography component="h1" variant="h5">
          Are you sure?
        </Typography>

        <Typography>
          This will delete the folder and all photos/videos inside it
        </Typography>

        <Box
          sx={{ display: "flex", gap: "1rem", "& button": { width: "5rem" } }}
        >
          <Button
            variant="contained"
            onClick={async (e) => {
              currentWeddingFoldersDispatch({ type: "DELETE", id: folder.id });
              try {
                await deleteFolder(folder.weddingId, folder.id);
                setOpenDeleteModal(false);
              } catch (e) {
                currentWeddingFoldersDispatch({
                  type: "CREATE",
                  folderData: folder,
                });
                enqueueSnackbar(e.response?.data?.error ?? e.message, {
                  variant: "error",
                });
              }
            }}
          >
            Yes
          </Button>

          <Button variant="outlined" onClick={onCloseDeleteModal}>
            No
          </Button>
        </Box>
      </CustomModal>
    </Box>
  );
}

function FolderNameForm_({ weddingFolder, afterSubmit }, ref) {
  const currentWeddingFoldersDispatch = React.useContext(
    CurrentWeddingFoldersDispatchContext
  );
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, register } = useForm({
    defaultValues: { folderName: weddingFolder.folderName.split("/").at(-1) },
  });

  return (
    <Box
      component="form"
      ref={ref}
      onSubmit={handleSubmit(onSubmit)}
      sx={{ lineHeight: "normal" }}
    >
      <CustomTextField
        autoFocus
        inputProps={{ style: { textAlign: "center" } }}
        InputProps={{ sx: { height: "1.25rem", fontSize: "0.75rem" } }}
        {...register("folderName", { required: true })}
      />
    </Box>
  );

  async function onSubmit({ folderName }) {
    const oldName = weddingFolder.folderName.split("/").at(-1);
    if (oldName === folderName) return afterSubmit();

    currentWeddingFoldersDispatch({
      type: "RENAME",
      id: weddingFolder.id,
      folderName,
    });
    try {
      const { data } = await renameFolder(
        weddingFolder.weddingId,
        weddingFolder.id,
        folderName
      );
      afterSubmit(data);
    } catch (e) {
      currentWeddingFoldersDispatch({
        type: "RENAME",
        id: weddingFolder.id,
        folderName: oldName,
      });
      enqueueSnackbar(e.response?.data?.error ?? e.message, {
        variant: "error",
      });
    }
  }
}
