import Modal from "@mui/material/Modal";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";
import React from "react";

import CloseButton from "@@components/common/Buttons/CloseButton";
import Carousel from "@@components/common/Carousel";
import Masonry from "@@components/common/MemoizedMasonry";
import { MemoizedWeddingGalleryItem } from "./WeddingGalleryItem";
import useWindowDimensions from "@@hooks/useWindowDimensions";
import { pxToRem, remToPx } from "@@utils/webApiUtils";
import WeddingPhotoCarouselElement from "./WeddingPhotoCarouselElement";

export default function WeddingGallery({
  media,
  mediaFilters,
  pileSimilarPhotos,
  retrieveAllRemainingMedia,
}) {
  const theme = useTheme();
  const { width: windowWidthPx } = useWindowDimensions();

  const [lastClickedMediaId, setLastClickedMediaId] = React.useState(null);
  const [carouselModalOpen, setCarouselModalOpen] = React.useState(false);
  const handleCarouselModalOpen = (clickedMediaId) => {
    setLastClickedMediaId(clickedMediaId);
    setCarouselModalOpen(true);
  };
  const handleCarouselModalClose = () => {
    setLastClickedMediaId(null);
    setCarouselModalOpen(false);
  };

  React.useEffect(() => {
    document.addEventListener("keydown", handleEsc);
    return () => document.removeEventListener("keydown", handleEsc);

    function handleEsc(e) {
      if (e.key === "Escape") handleCarouselModalClose();
    }
  }, []);

  React.useEffect(() => {
    handleCarouselModalClose();
  }, [mediaFilters]);

  // pre-compute img widths so we can specify the images' width and height
  //   attrs up-front, which is necessary for lazy-loading to work properly
  const nCols =
    windowWidthPx >= theme.breakpoints.values.xxl
      ? 5
      : windowWidthPx >= theme.breakpoints.values.lg
      ? 4
      : windowWidthPx >= theme.breakpoints.values.md
      ? 3
      : windowWidthPx >= theme.breakpoints.values.sm
      ? 2
      : 1;
  // on mobile, app bar does not take any width at sides
  const appbarWidthRem = windowWidthPx >= theme.breakpoints.values.md ? 4 : 0;
  const sidePaddingRem = 1;
  const masonrySpacing = 1;
  const colGapRem = pxToRem(+theme.spacing(masonrySpacing).replace("px", ""));
  const imgWidthPx =
    (windowWidthPx -
      remToPx(appbarWidthRem) -
      remToPx(sidePaddingRem * 2) -
      remToPx((nCols - 1) * colGapRem)) /
    nCols;

  // The Masonry component is very expensive to render/re-render (expensive
  //   enough to cause serious jank on page), and it keeps re-rendering when
  //   it doesn't need to (i.e. when user clicks on a photo and
  //   lastClickedMediaId changes), so we're memoizing some things to
  //   prevent that

  const groups = React.useMemo(() => {
    let groups;
    if (pileSimilarPhotos) {
      groups = _.groupBy(media, (m) => m.similarPhotosGroupId);
      const ungrouped = groups[null] ?? [];
      delete groups[null];
      groups = Object.values(groups)
        .concat(ungrouped.map((m) => [m]))
        .sort((a, b) => b[0].id - a[0].id);
    } else {
      groups = media.map((m) => [m]);
    }

    // ensure that a group has the same key even if the photo whose id was
    //   the key is deleted
    // ensure that groups with all photos deleted are not passed as children
    //   to the masonry component
    const idsOfPhotosToHide = new Set(
      media
        .filter(
          (m) =>
            m.__deleteMarker ||
            // if isPublic filter is undefined, can show both
            (mediaFilters.isPublic === true && !m.isPublic) ||
            (mediaFilters.isPublic === false && m.isPublic)
        )
        .map(({ id }) => id)
    );
    groups = groups
      .map((group) => ({
        key: group[0].id,
        photos: group.filter((p) => !idsOfPhotosToHide.has(p.id)),
      }))
      .filter((group) => group.photos.length > 0);

    return groups;
  }, [media, mediaFilters, pileSimilarPhotos]);

  const galleryItems = React.useMemo(() => {
    return groups.map(({ key, photos: photosInGroup }) => (
      <MemoizedWeddingGalleryItem
        key={key}
        photosInGroup={photosInGroup}
        widthPx={imgWidthPx}
        onClick={handleCarouselModalOpen}
        sx={{
          // when there's a single column of photos, the default margins &
          //   widths leave a small bit of space empty that makes the photos
          //   look vertically-misaligned (slightly) with surrouding content
          // we're getting rid of that extra space here
          ...(nCols === 1
            ? {
                margin: `calc(${theme.spacing(
                  masonrySpacing
                )} / 2) 0 !important`,
                width: "100% !important",
              }
            : {}),
          // assigning a randomly high order number that will be over-ridden
          //   within the Masonry component
          // this is so newly added items don't default to 'order: 0', which would
          //   cause them to be visually displayed before existing Masonry items
          //   instead of after them, before the Masonry component assigns them the
          //   correct order value
          // not doing this may be causing issues with scroll anchoring, since
          //   the anchor element (usually the last pre-existing Masonry item)
          //   will have its position changed
          // see screenshot: https://imgur.com/a/t1vLqXV
          order: 99,
        }}
      />
    ));
  }, [groups, imgWidthPx, nCols, theme]);

  return (
    <>
      <Masonry
        className="wedding-media-container"
        columns={nCols}
        spacing={masonrySpacing}
        sx={{
          margin: 0,
          padding: `0 ${sidePaddingRem}rem`,
          ...(nCols === 1
            ? {
                "& .wedding-gallery-item:first-of-type": {
                  marginTop: "0 !important",
                },
              }
            : {}),
        }}
        children={galleryItems}
      />

      <Modal
        className="wedding-photo-carousel-modal"
        open={carouselModalOpen}
        onClose={handleCarouselModalClose}
        BackdropProps={{ style: { backgroundColor: "black", opacity: 0.8 } }}
      >
        <>
          <CloseButton
            onClick={handleCarouselModalClose}
            sx={{
              position: "absolute",
              top: "0.5rem",
              right: "0.5rem",
              zIndex: 100, // render above modal backdrop and content
              padding: "0.25rem",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              color: "grey.light",
              "& :hover": { color: "white" },
            }}
          />

          <Carousel
            elems={groups}
            initialSelectedElemIdx={groups.findIndex(({ photos }) =>
              photos.some((p) => p.id === lastClickedMediaId)
            )}
            SlideshowControlsProps={{ sx: { display: "none" } }}
            onSlideshowStart={retrieveAllRemainingMedia}
            renderElem={({ photos }, props, ref) => (
              <WeddingPhotoCarouselElement
                ref={ref}
                {...props}
                photos={photos}
                selectedMediaId={lastClickedMediaId}
                setSelectedMediaId={setLastClickedMediaId}
                onClose={handleCarouselModalClose}
              />
            )}
          />
        </>
      </Modal>
    </>
  );
}
