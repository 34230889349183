import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import cx from "classnames";
import React from "react";

export default function ChangeableAvatar({
  src,
  alt,
  onChange,
  changeCtaText,
  children,
  sx,
  className,
  InputProps,
  ...restProps
}) {
  const [loading, setLoading] = React.useState(false);
  const inputRefContainer = React.useRef(null);

  return (
    <Box
      className={cx(className, "changeable-avatar-container")}
      sx={{
        position: "relative",
        borderRadius: "100%",
        overflow: "hidden",
        ...sx,
      }}
      {...restProps}
    >
      <Avatar
        className="changeable-avatar"
        alt={alt}
        src={src}
        sx={{
          height: "100%",
          width: "100%",
          border: "2px solid rgba(0, 0, 0, 0.2)",
        }}
      >
        {src ? null : children}
      </Avatar>

      <input
        type="file"
        name="changeable-avatar-input"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleChange}
        ref={inputRefContainer}
        {...InputProps}
      />

      <LoadingButton
        className="changeable-avatar-change-button"
        loading={loading}
        disableRipple
        disableElevation
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          height: "50%",
          display: onChange ? "flex" : "none",
          backgroundColor: "rgba(0, 0, 0, 0.33)",
          borderRadius: 0,
          color: "white",
          ":hover": { backgroundColor: "black" },
          "& .MuiLoadingButton-loadingIndicator": { color: "white" },
        }}
        onClick={() => inputRefContainer.current.click()}
      >
        {changeCtaText ?? (src ? "Change" : "Add")}
      </LoadingButton>
    </Box>
  );

  async function handleChange(e) {
    if (e.target.files.length === 0) return;

    setLoading(true);
    try {
      await onChange(e.target.files[0]);
    } finally {
      setLoading(false);
    }
  }
}
