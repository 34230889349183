import React from "react";

export const BeforeInstallPromptEventContext = React.createContext(null);
BeforeInstallPromptEventContext.displayName = "BeforeInstallPromptEventContext";

export const PromptToInstallPwaContext = React.createContext(() => {});
PromptToInstallPwaContext.displayName = "PromptToInstallPwaContext";

export default function PWAInstallSupportContextsWrapper({ children }) {
  const [beforeInstallPromptEvent, setBeforeInstallPromptEvent] =
    React.useState(null);

  const promptToInstallPwa = React.useCallback(async () => {
    if (!beforeInstallPromptEvent) return;

    // analytics
    window.dataLayer.push({
      event: "pwa_install_prompt_triggered",
    });

    const e = beforeInstallPromptEvent;
    e.prompt();
    // event cannot be reused
    setBeforeInstallPromptEvent(null);
    const { outcome } = await e.userChoice;

    // analytics
    window.dataLayer.push({
      event:
        outcome === "accepted"
          ? "pwa_install_prompt_accepted"
          : "pwa_install_prompt_dismissed",
    });
  }, [beforeInstallPromptEvent]);

  React.useEffect(() => {
    if (!("onbeforeinstallprompt" in window && "onappinstalled" in window)) {
      return;
    }

    window.addEventListener(
      "beforeinstallprompt",
      saveBeforeInstallPromptEvent
    );
    window.addEventListener("appinstalled", discardBeforeInstallPromptEvent);

    return () => {
      if (!("onbeforeinstallprompt" in window && "onappinstalled" in window)) {
        return;
      }

      window.removeEventListener(
        "beforeinstallprompt",
        saveBeforeInstallPromptEvent
      );
      window.removeEventListener(
        "appinstalled",
        discardBeforeInstallPromptEvent
      );
    };

    function saveBeforeInstallPromptEvent(e) {
      e.preventDefault();
      setBeforeInstallPromptEvent(e);
    }

    function discardBeforeInstallPromptEvent(e) {
      setBeforeInstallPromptEvent(null);
    }
  }, []);

  return (
    <BeforeInstallPromptEventContext.Provider value={beforeInstallPromptEvent}>
      <PromptToInstallPwaContext.Provider value={promptToInstallPwa}>
        {children}
      </PromptToInstallPwaContext.Provider>
    </BeforeInstallPromptEventContext.Provider>
  );
}
