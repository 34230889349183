import Link from "@mui/material/Link";
import React from "react";

function AbsoluteLink({ href, children, ...restProps }, ref) {
  return (
    <Link
      ref={ref}
      href={/^https?:\/\//.test(href) ? href : `https://${href}`}
      {...restProps}
    >
      {children}
    </Link>
  );
}

export default React.forwardRef(AbsoluteLink);
