import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import cx from "classnames";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import CustomTextField from "@@components/common/CustomTextField";
import { searchVendors } from "@@services/fotobot-api.service";

const filter = createFilterOptions();

function VendorAutocomplete(
  { onChange, freeSolo, className, ...restProps },
  ref
) {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  const [inputValue, setInputValue] = React.useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refreshOptions = React.useCallback(
    _.debounce(async (inputValue) => {
      setLoading(true);
      try {
        const { data: searchResults } = await searchVendors({
          namePrefix: inputValue,
        });

        setOptions(searchResults);
      } catch (e) {
        const errMsg = e.response?.data?.error;
        enqueueSnackbar(
          `Error fetching vendors` + (errMsg ? `: ${errMsg}` : ""),
          { variant: "error" }
        );
        throw e;
      } finally {
        setLoading(false);
      }
    }, 500),
    [enqueueSnackbar]
  );

  React.useEffect(() => {
    if (inputValue.length < 3) {
      refreshOptions?.cancel();
      setOptions([]);
    } else {
      refreshOptions(inputValue);
    }
  }, [inputValue, refreshOptions]);

  return (
    <Autocomplete
      ref={ref}
      freeSolo={freeSolo}
      clearOnBlur
      size="small"
      className={cx("vendor-input", className)}
      options={options}
      getOptionLabel={(option) => option.name}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (freeSolo) {
          const { inputValue } = params;
          if (inputValue !== "") {
            filtered.push({ name: inputValue });
          }
        }

        return filtered;
      }}
      renderOption={(props, option) => (
        <Box {...props} key={option.id ?? option.name} component="li">
          <Typography variant="caption" sx={{ color: "grey.main" }}>
            {option.id ? option.name : `Add: "${option.name}"`}
          </Typography>
          <Typography
            variant="caption"
            sx={{ marginLeft: "auto", color: "grey.light" }}
          >
            {option.id ? option.adminPhoneNumber : ""}
          </Typography>
        </Box>
      )}
      onChange={(e, v) => onChange(v)}
      inputValue={inputValue}
      onInputChange={(e, v) => setInputValue(v)}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          label="Vendor Name *"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      {...restProps}
    />
  );
}

export default React.forwardRef(VendorAutocomplete);
