import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import LoadingButton from "@mui/lab/LoadingButton";
import cx from "classnames";
import React from "react";
import { useForm } from "react-hook-form";

import CustomTextField from "@@components/common/CustomTextField";

export default function BasicDetailsForm({
  defaultValues,
  onSubmit,
  SubmitButtonProps,
  className,
  sx,
  ...restProps
}) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: { role: "BRIDE", ...defaultValues },
  });

  const { sx: submitButtonSx, ...submitButtonRestProps } = SubmitButtonProps;

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      className={cx(className, "create-wedding-flow-basic-details-form")}
      sx={{ display: "flex", flexDirection: "column", ...sx }}
      {...restProps}
    >
      <CustomTextField
        autoFocus
        size="normal"
        margin="normal"
        label="Gallery name *"
        placeholder="#PriyaRahul2023"
        error={errors?.name}
        helperText={errors?.name?.message ?? ""}
        {...register("name", {
          required: { value: true, message: "This field is required" },
          minLength: {
            value: 3,
            message: "Please enter at least 3 characters",
          },
        })}
      />

      <CustomTextField
        size="normal"
        margin="normal"
        label={`Wedding date *`}
        type="date"
        error={errors?.date}
        helperText={errors?.date?.message ?? ""}
        {...register(`date`, {
          required: { value: true, message: "This field is required" },
        })}
      />

      <CustomTextField
        size="normal"
        margin="normal"
        label="You are the *"
        select
        defaultValue={defaultValues?.role ?? "BRIDE"}
        error={errors?.role}
        helperText={errors?.role?.message ?? ""}
        {...register("role", { required: true })}
      >
        <MenuItem value="BRIDE">Bride</MenuItem>
        <MenuItem value="GROOM">Groom</MenuItem>
        <MenuItem value="RELATIVE_OR_FRIEND">Relative / Friend</MenuItem>
        <MenuItem value="PHOTOGRAPHER">Wedding Photographer</MenuItem>
        <MenuItem value="CLIENT_OTHER">Other</MenuItem>
      </CustomTextField>

      <LoadingButton
        variant="contained"
        type="submit"
        sx={{ alignSelf: "flex-start", ...submitButtonSx }}
        {...submitButtonRestProps}
      >
        Submit
      </LoadingButton>
    </Box>
  );
}

BasicDetailsForm.defaultProps = {
  SubmitButtonProps: {},
};
