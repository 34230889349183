import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";
import React from "react";

import {
  WeddingMediaUploadStatsContext,
  WeddingMediaUploadStatsDispatchContext,
} from "@@contexts/WeddingMediaUploadStatsContextsWrapper";
import { resetWeddingMediaUploadStats } from "@@services/wedding-media.service";

export default function UploadProgress({
  weddingId,
  variant,
  sx,
  ...restProps
}) {
  const uploadStats = React.useContext(WeddingMediaUploadStatsContext);
  const uploadStatsDispatch = React.useContext(
    WeddingMediaUploadStatsDispatchContext
  );
  const [showDetailedStatsModal, setShowDetailedStatsModal] =
    React.useState(false);
  const theme = useTheme();

  const stats = uploadStats[weddingId];
  if (!stats) return null;

  const styles = {
    padding: variant === "minimal" ? "0.25rem 0.75rem" : "0.5rem 1rem",
    backgroundColor: theme.palette.grey.extralight,
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  };

  if (!_.isEmpty(stats.SENDING_IN_PROGRESS)) {
    const uploadOpDetails = Object.values(stats.SENDING_IN_PROGRESS);
    const pctSent =
      (uploadOpDetails.reduce((acc, { sentBytes }) => acc + sentBytes, 0) /
        uploadOpDetails.reduce((acc, { totalBytes }) => acc + totalBytes, 0)) *
      100;
    const nFilesBeingSent = uploadOpDetails.reduce(
      (acc, { nFiles }) => acc + nFiles,
      0
    );

    return (
      <Paper
        className="media-upload-progress"
        elevation={12}
        sx={{ ...styles, ...sx }}
        {...restProps}
      >
        <Typography variant="body2">
          Sending files - {nFilesBeingSent} left
        </Typography>
        <LinearProgress
          variant="determinate"
          value={Math.round(pctSent)}
          sx={{
            minWidth: "3rem",
            flexGrow: 1,
            height: "0.625rem",
            borderRadius: "0.5rem",
          }}
        />
      </Paper>
    );
  }

  const nProcessed = stats.nProcessed ?? 0;
  const pctProcessed = (nProcessed / stats.nReceived) * 100;
  return (
    <>
      {showDetailedStatsModal ? null : (
        <Paper
          className="media-upload-progress"
          elevation={12}
          sx={{ ...styles, ...sx }}
          {...restProps}
        >
          {stats.nReceived === undefined ? (
            <Typography variant="body2">Processing ...</Typography>
          ) : pctProcessed < 100 ? (
            <>
              <Typography variant="body2">Processing</Typography>

              <LinearProgress
                variant="determinate"
                value={Math.round(pctProcessed)}
                sx={{
                  minWidth: "3rem",
                  flexGrow: 1,
                  height: "0.625rem",
                  borderRadius: "0.5rem",
                }}
              />

              {variant === "minimal" ? null : (
                <Typography variant="body2">
                  {nProcessed} / {stats.nReceived}
                </Typography>
              )}
            </>
          ) : (
            <>
              <Typography variant="body2" sx={{ flexGrow: 1 }}>
                {variant === "minimal"
                  ? `Upload complete`
                  : `Processed ${stats.nReceived} files${
                      stats.nErrors > 0 ? " with errors." : "."
                    }`}
              </Typography>

              {variant === "minimal" ? null : (
                <>
                  {stats.nErrors > 0 ? (
                    <Button
                      size="small"
                      sx={{ minWidth: "auto", padding: 0 }}
                      onClick={() => setShowDetailedStatsModal(true)}
                    >
                      Details
                    </Button>
                  ) : null}

                  <Button
                    size="small"
                    sx={{ minWidth: "auto", padding: 0 }}
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </>
              )}
            </>
          )}
        </Paper>
      )}

      <DetailedStatsModal
        stats={stats}
        open={showDetailedStatsModal}
        onHide={() => setShowDetailedStatsModal(false)}
        onClose={handleClose}
      />
    </>
  );

  async function handleClose() {
    await resetWeddingMediaUploadStats("me", { weddingIds: [weddingId] });
    setShowDetailedStatsModal(false);
    uploadStatsDispatch({ type: "DELETE_WEDDING", weddingId });
  }
}

function DetailedStatsModal({ stats, open, onHide, onClose }) {
  const theme = useTheme();

  const categories = [
    { text: "Files uploaded", value: stats.nReceived, alwaysShow: true },
    {
      text: "Added to gallery",
      value: stats.nSuccess,
      alwaysShow: true,
    },
    {
      text: "Rejected - file too large",
      subtext: "Max allowed: 2GB",
      value: stats.errorFileTooLarge,
      files: stats.errorFileTooLarge__files,
    },
    {
      text: "Rejected - invalid file type",
      subtext: "Only jpeg/jpg, png, and mp4 files are supported",
      value:
        stats.errorInvalidContentType +
        stats.errorVideoUploadDisabledForChannel,
      files: []
        .concat(stats.errorInvalidContentType__files ?? [])
        .concat(stats.errorVideoUploadDisabledForChannel__files ?? []),
    },
    {
      text: "Rejected - cannot create subfolder",
      subtext:
        "Please upload these files by drag-and-dropping them directly, rather than their folders",
      value: stats.errorCannotCreateSubfolder,
      files: stats.errorCannotCreateSubfolder__files,
    },
    {
      text: "Rejected - duplicate",
      subtext: "These files are already in the gallery",
      value: stats.errorMediaAlreadyExists,
      files: stats.errorMediaAlreadyExists__files,
    },
    {
      text: "Rejected - free-tier limit reached",
      subtext:
        "These files were not uploaded because this wedding is on the free-tier, and the upload limit was reached",
      value: stats.errorFreeTierLimitReached,
      files: stats.errorFreeTierLimitReached__files,
    },
    {
      text: "Error during processing",
      subtext: "Please try uploading these files again",
      value: stats.errorServerError,
      files: stats.errorServerError__files,
    },
  ];

  return (
    <Modal open={open} onClose={onHide}>
      <Box
        className="detailed-upload-stats abs-centered"
        sx={{
          width: { xs: "21rem", sm: "24rem" },
          padding: "1.5rem",
          borderRadius: "1rem",
          backgroundColor: theme.palette.grey.extralight,
          "& h1,p": { color: theme.palette.grey.dark },
        }}
      >
        <Typography variant="h6" component="h1" sx={{ marginBottom: "1.5rem" }}>
          Upload results
        </Typography>

        <Box
          sx={{
            "& .MuiAccordionDetails-root": {
              maxHeight: "12rem",
              overflowY: "auto",
            },
            "& .MuiAccordionDetails-root p": {
              margin: "0.25rem 0",
              fontSize: "0.875rem",
            },
            "& .MuiAccordionDetails-root p::before": {
              content: `url(${InsertPhotoOutlinedIcon})`,
              display: "inline-block",
              width: "1rem",
              height: "1rem",
            },
          }}
        >
          {categories
            .filter(({ alwaysShow, value }) => alwaysShow || value > 0)
            .map(({ text, subtext, value, files }) => (
              <Accordion key={text} className="upload-result-category">
                <AccordionSummary
                  expandIcon={files ? <ExpandMoreIcon /> : null}
                >
                  <Box
                    sx={{
                      // align with expand icon
                      marginRight: files ? "1rem" : "2.5rem",
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>{text}</Typography>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {value}
                      </Typography>
                    </Box>
                    <Typography
                      variant="caption"
                      sx={{ color: theme.palette.grey.main }}
                    >
                      {subtext}
                    </Typography>
                  </Box>
                </AccordionSummary>

                {files ? (
                  <AccordionDetails>
                    {files.sort().map((filename) => (
                      <Typography key={filename}>{filename}</Typography>
                    ))}
                  </AccordionDetails>
                ) : null}
              </Accordion>
            ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "2rem",
            "& button": { minWidth: "6rem" },
          }}
        >
          <Button
            variant="outlined"
            onClick={onHide}
            sx={{ marginTop: "1.5rem" }}
          >
            Hide
          </Button>

          <Button
            variant="outlined"
            onClick={onClose}
            sx={{ marginTop: "1.5rem" }}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
