import Box from "@mui/material/Box";
import React from "react";

import { fotobotPhoneNumber } from "@@config";

function FotobotWhatsappLink({ children }, ref) {
  return (
    <Box
      ref={ref}
      component="a"
      href={`https://wa.me/${fotobotPhoneNumber.replace("+", "")}?text=Hi`}
      target="_blank"
      rel="noreferrer"
      sx={{ color: "primary.main", textDecoration: "none" }}
    >
      {children ?? "WhatsApp"}
    </Box>
  );
}

export default React.forwardRef(FotobotWhatsappLink);
