import Box from "@mui/material/Box";
import React from "react";

import WeddingName from "./WeddingName";

export default function WeddingHeader({ wedding }) {
  const coverPhotoIsPresent = !!wedding.coverPhotoUrl;

  return (
    <Box
      className="wedding-header"
      sx={{
        position: "relative",
        padding: "1rem",
        ...(coverPhotoIsPresent
          ? { minHeight: { xs: "16rem", sm: "24rem" } }
          : { marginTop: "5rem" }),
      }}
    >
      {coverPhotoIsPresent ? (
        <>
          <Box
            component="img"
            src={wedding.coverPhotoUrl}
            className="overlay cover-parent"
            sx={{ objectFit: "cover", objectPosition: "0 0" }}
          />
          <Box
            className="overlay cover-parent"
            sx={{ background: "linear-gradient(#FFF3 0%, #FFFF 90%)" }}
          />
        </>
      ) : null}

      <WeddingName
        sx={{
          ...(coverPhotoIsPresent
            ? {
                position: "absolute",
                bottom: "1rem",
                left: "1rem",
                right: "1rem",
              }
            : {}),
        }}
      >
        {wedding.name}
      </WeddingName>
    </Box>
  );
}
