import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import cx from "classnames";
import React from "react";

import { PromptToInstallPwaContext } from "@@contexts/PWAInstallSupportContextsWrapper";
import CustomModal from "@@components/common/CustomModal";

export default function PwaInstallModal({
  open,
  onClose,
  className,
  ...restProps
}) {
  const promptToInstallPwa = React.useContext(PromptToInstallPwaContext);

  return (
    <CustomModal
      className={cx(className, "pwa-install-modal")}
      open={open}
      onClose={onClose}
      {...restProps}
    >
      <Typography variant="body2" style={{ marginBottom: "1rem" }}>
        This will add Fotobot to your home screen
      </Typography>

      <Box
        sx={{
          display: "flex",
          gap: "2rem",
          "& button": { width: "6rem" },
        }}
      >
        <Button
          size="large"
          variant="contained"
          onClick={() => {
            onClose();
            promptToInstallPwa();
          }}
        >
          Ok
        </Button>

        <Button size="large" variant="outlined" onClick={() => onClose()}>
          Cancel
        </Button>
      </Box>
    </CustomModal>
  );
}
