import IconButton from "@mui/material/IconButton";
import React from "react";

export default React.forwardRef(SquareIconButton_);

function SquareIconButton_({ sx, children, ...restProps }, ref) {
  return (
    <IconButton
      ref={ref}
      sx={{
        backgroundColor: "white",
        // same border as muiToggleButton default
        border: `1px solid rgba(0, 0, 0, 0.12)`,
        borderRadius: "4px",
        ...sx,
      }}
      {...restProps}
    >
      {children}
    </IconButton>
  );
}
