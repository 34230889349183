import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import cx from "classnames";

export default function ActionsMenuList({
  actions,
  className,
  sx,
  ...restProps
}) {
  return (
    <MenuList
      className={cx(className, "actions-menu-list")}
      sx={{
        color: "grey.main",
        display: "flex",
        flexDirection: "column",
        gap: "0rem",
        ...sx,
        "& .MuiMenuItem-root": {
          width: "100%",
          borderRadius: "10px",
          gap: "1rem",
          padding: "1rem 0",
        },
        "& .MuiMenuItem-root:hover": {
          backgroundColor: "primary.extralight",
          cursor: "pointer",
        },
        "& .MuiListItemIcon-root": {
          minWidth: "auto",
        },
        "& .MuiListItemText-root": {
          margin: 0,
          whiteSpace: "nowrap",
        },
      }}
      {...restProps}
    >
      {actions.map(({ icon, label, onClick, ...restProps }) => (
        <MenuItem key={label} onClick={onClick} {...restProps}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{label}</ListItemText>
        </MenuItem>
      ))}
    </MenuList>
  );
}
