import Typography from "@mui/material/Typography";
import React from "react";

// headline for temporarily-visible surfaces like modals and drawers
function PaneHeading({ sx, ...restProps }, ref) {
  return (
    <Typography
      ref={ref}
      variant="body2"
      sx={{ color: "grey.main", marginBottom: "1rem", ...sx }}
      {...restProps}
    />
  );
}

export default React.forwardRef(PaneHeading);
