import { assign, createMachine } from "xstate";

export const createWeddingFlowFsmStatesOrdered = [
  "askBasicEventDetails",
  "askKeyPeopleDetails",
  "waiting",
  "error",
  "ended",
];

const createWeddingFlowFsm = createMachine(
  {
    // adding this because of a browser console warning
    // see https://xstate.js.org/docs/guides/actions.html#api
    predictableActionArguments: true,

    id: "createWeddingFlowFsm",
    context: { createWeddingData: null, createWeddingError: null },
    initial: "askBasicEventDetails",
    on: {
      RESET: {
        target: ".askBasicEventDetails",
        actions: assign((context) => ({
          createWeddingData: null,
        })),
      },
    },
    states: {
      askBasicEventDetails: {
        on: {
          BASIC_EVENT_DETAILS_PROVIDED: {
            target: "askKeyPeopleDetails",
            actions: ["updateCreateWeddingData"],
          },
        },
      },
      askKeyPeopleDetails: {
        on: {
          BACK_BUTTON_PRESSED: {
            target: "askBasicEventDetails",
            actions: ["updateCreateWeddingData"],
          },
          KEY_PEOPLE_DETAILS_PROVIDED: {
            target: "waiting",
            actions: ["updateCreateWeddingData"],
          },
          KEY_PEOPLE_DETAILS_SKIPPED: {
            target: "waiting",
          },
        },
      },
      waiting: {
        on: {
          ERROR_DURING_WEDDING_CREATION: {
            target: "error",
            actions: assign({
              createWeddingError: (context, event) => event.error,
            }),
          },
          WEDDING_CREATED: { target: "ended" },
        },
      },
      error: {
        on: {
          BACK_BUTTON_PRESSED: {
            target: "askKeyPeopleDetails",
          },
        },
      },
      ended: {},
    },
  },
  {
    actions: {
      updateCreateWeddingData: assign({
        createWeddingData: (context, event) => ({
          ...context.createWeddingData,
          ...event.data,
        }),
      }),
    },
  }
);

export default createWeddingFlowFsm;
