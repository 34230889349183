import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useSnackbar } from "notistack";

import PhoneNumberField from "@@components/common/InputFields/PhoneNumberField";
import OtpField from "@@components/common/InputFields/OtpField";
import { SetLoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";
import { sendVerificationCode, loginWithOTP } from "@@services/auth.service";

export default function LoginFormComponent({ onSuccess }) {
  const setLoggedInUser = React.useContext(SetLoggedInUserContext);
  const [otpSent, setOtpSent] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const formMethods = useForm({
    defaultValues: {
      phoneNumber: "+91",
      verificationCode: "",
    },
  });
  const { handleSubmit, reset } = formMethods;
  const { enqueueSnackbar } = useSnackbar();
  const phoneNumberFieldRef = React.useRef(null);
  const otpFieldRef = React.useRef(null);

  return (
    <FormProvider {...formMethods}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <PhoneNumberField disabled={!!otpSent} ref={phoneNumberFieldRef} />

        {otpSent ? (
          <OtpField
            required={otpSent}
            ref={otpFieldRef}
            ResendButtonProps={{
              onClick: () => {
                trySendingVerificationCode(phoneNumberFieldRef.current.value);

                // analytics
                window.dataLayer.push({
                  event: "otp_requested",
                  otp_request_source: "login_form_resend_button",
                });
              },
            }}
          />
        ) : null}

        <Box style={{ margin: "1rem 0 0 0", display: "flex", gap: "1rem" }}>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submitting}
            size="large"
          >
            {otpSent ? "Continue" : "Get OTP"}
          </LoadingButton>

          <Button disabled={submitting} onClick={onReset} size="large">
            Reset
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );

  async function onSubmit({ phoneNumber, verificationCode }) {
    setSubmitting(true);

    if (!verificationCode) {
      // analytics
      window.dataLayer.push({
        event: "otp_requested",
        otp_request_source: "login_form",
      });

      await trySendingVerificationCode(phoneNumber);
    } else {
      try {
        const { data: user } = await loginWithOTP({
          phoneNumber,
          verificationCode,
        });

        setLoggedInUser(user);

        onSuccess && onSuccess(user);
      } catch (e) {
        setLoggedInUser(null);

        if (
          e.response?.status === 400 &&
          /Token is invalid/.test(e.response.data.error)
        ) {
          enqueueSnackbar("OTP is incorrect, or may have expired", {
            variant: "error",
          });
        } else {
          enqueueSnackbar(
            e.response?.data?.error ??
              "There was an error logging you in. Please try again later.",
            { variant: "error" }
          );
          throw e;
        }
      } finally {
        setSubmitting(false);
      }
    }
  }

  function onReset() {
    setOtpSent(false);
    reset();
  }

  async function trySendingVerificationCode(phoneNumber) {
    try {
      await sendVerificationCode(phoneNumber);
    } catch (e) {
      // do nothing; we'll act like it succeeded
      // the user can retry if they don't get the OTP
    }

    setOtpSent(true);
    otpFieldRef.current.focus();
    enqueueSnackbar(`You will receive an OTP soon on whatsapp`, {
      variant: "info",
    });
    setSubmitting(false);
  }
}
