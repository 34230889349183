import Typography from "@mui/material/Typography";
import cx from "classnames";
import { useHistory } from "react-router-dom";

import CustomModal from "@@components/common/CustomModal";
import LoginForm from "@@components/common/LoginForm";

export default function LoginModal({ open, onClose, className, ...restProps }) {
  const history = useHistory();

  return (
    <CustomModal
      className={cx("login-modal", className)}
      open={open}
      onClose={onClose}
      {...restProps}
    >
      <Typography variant="body2" style={{ marginBottom: "1rem" }}>
        Login with your whatsapp phone number
      </Typography>

      <LoginForm
        onSuccess={() => {
          const urlSearchParams = new URLSearchParams(window.location.search);
          const redirectTo = urlSearchParams.get("from");
          if (redirectTo) {
            history.push(decodeURIComponent(redirectTo));
          }
          onClose(null, "loginSuccess");
        }}
      />
    </CustomModal>
  );
}
