import _ from "lodash";

import { systemUserId } from "@@config";
import { setDiff } from "@@utils/setUtils";

export function streamlineFiltersObject(filters) {
  return _.pickBy(filters, (val, key) =>
    // '' is a valid value for mediaPath
    // false is a valid value for isPublic and includeSimilarMedia
    ["isPublic", "mediaPath", "includeSimilarMedia"].includes(key)
      ? !_.isNil(val)
      : key === "featuring"
      ? val.data.length > 0
      : key === "numPersons"
      ? val.type === "eq" || val.data > 0
      : !!val
  );
}

// "implicit" filters are set elsewhere than in the filters UI
export function countFilters(mediaFilters, includeImplicit = false) {
  return [
    mediaFilters.featuring?.data?.length ?? 0,
    // can't be `mediaFilters.isPublic ? 1 : 0` because isPublic === false
    //   is a valid filter
    mediaFilters.isPublic !== undefined ? 1 : 0,
    (mediaFilters.numPersons.type === "gte" &&
      mediaFilters.numPersons.data > 0) ||
    mediaFilters.numPersons.type === "eq"
      ? 1
      : 0,
    mediaFilters.uploadedBy ? 1 : 0,
  ]
    .concat(
      includeImplicit
        ? [
            // set as the user scrolls
            mediaFilters.lastId ? 1 : 0,
            // set based on the wedding page tab the user is on
            mediaFilters.mediaType ? 1 : 0,
            // set as user navigates the folder structure
            mediaFilters.mediaPath !== undefined ? 1 : 0, // mediaPath === '' is a valid filter
          ]
        : []
    )
    .reduce((acc, n) => acc + n);
}

export function countChangesFromAppliedFiltersToSelectedFilters(
  selected,
  applied
) {
  if (
    selected.savedFilterUuid &&
    selected.savedFilterUuid === applied.savedFilterUuid
  ) {
    return 0;
  }

  let nChanges = 0;

  ["mediaType", "mediaPath", "uploadedBy", "isPublic"].forEach((key) => {
    if (selected[key] !== applied[key]) nChanges++;
  });

  /* featuring filter */

  if (selected.featuring.type !== applied.featuring.type) {
    // changing featuring filter type only counts as a change when selected-
    //   and applied-filters both have one/more persons specified
    // this is because to a featuring filter with no persons specified, the
    //   type makes no difference
    if (
      selected.featuring.data.length > 1 &&
      applied.featuring.data.length > 1
    ) {
      nChanges++;
    }
  }

  const idsInSelected = new Set(
    selected.featuring.data.map(
      ({ id, faceId, tmpId }) => id ?? faceId ?? tmpId
    )
  );
  const idsInApplied = new Set(
    applied.featuring.data.map(({ id, faceId, tmpId }) => id ?? faceId ?? tmpId)
  );
  nChanges +=
    setDiff(idsInSelected, idsInApplied).size +
    setDiff(idsInApplied, idsInSelected).size;

  /* numPersons filter */

  if (selected.numPersons.type !== applied.numPersons.type) nChanges++;
  if (selected.numPersons.data !== applied.numPersons.data) nChanges++;

  return nChanges;
}

export function savedFiltersCmp(a, b, loggedInUser) {
  return getScore(a) - getScore(b) || a.name.localeCompare(b.name);

  function getScore(filter) {
    const scores = {
      PHOTOS_OF_ME: 0,
      CREATED_BY_SYSTEM: 10,
      CREATED_BY_CURRENT_USER: 20,
      CREATED_BY_OTHER_USER: 30,
    };

    const { name, creatorId } = filter;

    return creatorId === systemUserId && name === "Photos of me"
      ? scores.PHOTOS_OF_ME
      : creatorId === systemUserId
      ? scores.CREATED_BY_SYSTEM
      : creatorId === loggedInUser?.id
      ? scores.CREATED_BY_CURRENT_USER
      : scores.CREATED_BY_OTHER_USER;
  }
}
