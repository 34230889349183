import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import cx from "classnames";

export default function NewMediaAvailableCTA({
  mediaType,
  onShowButtonClick,
  className,
  sx,
  ...restProps
}) {
  mediaType ??= "media";

  return (
    <Paper
      elevation={3}
      className={cx("new-media-available-cta flex-center-content", className)}
      sx={{
        position: "sticky",
        top: "2rem",
        margin: "0 auto",
        // make this appear above WeddingMediaOverlays
        zIndex: 101,
        borderRadius: "0.5rem",
        fontSize: "0.875rem",
        padding: "0.5rem 2rem",
        gap: { xs: "1rem", sm: "2rem" },
        ...sx,
      }}
      {...restProps}
    >
      <Typography
        variant="caption"
        sx={{ fontSize: "inherit", whiteSpace: "nowrap" }}
      >
        There {mediaType === "media" ? "is" : "are"} new {mediaType} to view.
      </Typography>
      <Button
        variant="text"
        size="small"
        onClick={() => onShowButtonClick()}
        sx={{
          minWidth: "unset",
          fontSize: "inherit",
          padding: 0,
        }}
      >
        Show
      </Button>
    </Paper>
  );
}
