import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import cx from "classnames";
import React from "react";

const getAngleOfRotation = () => -15 + Math.random() * 30;

export default function Pile({
  elems,
  renderElem,
  onTopElemChange,
  className,
  sx,
  ...restProps
}) {
  const [topElemIdx, setTopElemIdx] = React.useState(0);
  const [anglesOfRotation, setAnglesOfRotation] = React.useState(
    elems.map(getAngleOfRotation)
  );

  const pileRef = React.useRef(null);
  const topElemRef = React.useRef(null);

  // if elems are added to / removed from the pile:
  // - topElemIdx *may* need to be updated
  // - anglesOfRotation will need to be updated
  //   - even then, retain angles that are still applicable so the
  //       untouched elems of the pile don't change their position
  React.useEffect(() => {
    if (elems.length === anglesOfRotation.length) return;

    // TODO: handle the case where elems are added to the top of the pile

    // elems are removed from top of pile
    if (elems.length < anglesOfRotation.length) {
      const nDeletions = anglesOfRotation.length - elems.length;

      // topElemIdx changes when `elems.length - 1 > topElemIdx`, i.e.
      //   a sufficient number of elems are removed
      if (nDeletions > anglesOfRotation.length - 1 - topElemIdx) {
        const newTopElemIdx =
          nDeletions - (anglesOfRotation.length - 1 - topElemIdx) - 1;
        setTopElemIdx(newTopElemIdx);
        onTopElemChange(newTopElemIdx);
      }

      const indexesToRemove = new Array(nDeletions)
        .fill()
        .map((_, idx) => (topElemIdx + idx) % anglesOfRotation.length);
      setAnglesOfRotation(
        anglesOfRotation.filter((_, idx) => !indexesToRemove.includes(idx))
      );
    }
  }, [elems, anglesOfRotation, topElemIdx, onTopElemChange]);

  return (
    <Box
      ref={pileRef}
      className={cx("pile", className)}
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
        ...sx,
      }}
      {...restProps}
    >
      {elems.map((elem, idx) =>
        renderElem(
          elem,
          {
            style: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: [
                `translate(-50%, -50%)`,
                `rotate(${anglesOfRotation[idx]}deg)`,
              ].join(" "),
              boxShadow: "0 0 4px 2px rgba(0, 0, 0, 0.6)",
              zIndex:
                idx >= topElemIdx
                  ? elems.length - (idx - topElemIdx)
                  : topElemIdx - idx,
              transition: "0.15s",
            },
          },
          idx === topElemIdx ? topElemRef : null
        )
      )}

      <Button
        startIcon={<RotateLeftIcon />}
        size="small"
        disableRipple
        sx={{
          position: "absolute",
          top: "50%",
          right: 0,
          zIndex: 100, // needs to be above topmost elem in pile
          transform: `translateY(-50%)`,

          minWidth: "auto",

          color: "grey.main",
          padding: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",

          "& .MuiButton-startIcon": { margin: 0 },
        }}
        onClick={() => {
          const topElem = topElemRef.current;

          // make sure the element clears the pile when it's being
          //   sent to the back
          topElem.style.marginLeft = `${
            pileRef.current.getBoundingClientRect().width + 30
          }px`;

          setTimeout(() => {
            const newTopElemIdx = (topElemIdx + 1) % elems.length;
            setTopElemIdx(newTopElemIdx);
            onTopElemChange(newTopElemIdx);

            setTimeout(() => (topElem.style.marginLeft = 0), 300);
          }, 300);
        }}
      >
        {topElemIdx + 1}/{elems.length}
      </Button>
    </Box>
  );
}
