import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import React from "react";

import {
  LoginPageDesktop,
  LoginPageMobile,
  WeddingsListPage,
  WeddingPage,
} from "./pages";
import SplashContent from "@@components/common/SplashContent";
import TopLevelModalsWrapper from "@@contexts/TopLevelModalsWrapper";
import {
  LoggedInUserInitialFetchDoneContext,
  LoggedInUserContext,
} from "@@contexts/LoggedInUserContextsWrapper";

export default function Router() {
  const theme = useTheme();
  const loggedInUserInitialFetchDone = React.useContext(
    LoggedInUserInitialFetchDoneContext
  );
  const loggedInUser = React.useContext(LoggedInUserContext);

  const isMdPlusScreen = useMediaQuery(theme.breakpoints.up("md"));

  if (!loggedInUserInitialFetchDone) {
    return (
      <Box className="splash-screen fixed-full-screen flex-center-content">
        <SplashContent />
      </Box>
    );
  }

  return (
    <BrowserRouter>
      {/* some top-level modals depend on useHistory and useLocation, which
          are only available inside <BrowserRouter> */}
      <TopLevelModalsWrapper>
        <Switch>
          <Route exact path="/login">
            {loggedInUser ? (
              <Redirect to="/weddings" />
            ) : isMdPlusScreen ? (
              <LoginPageDesktop />
            ) : (
              <LoginPageMobile />
            )}
          </Route>

          <Route exact path="/weddings">
            {loggedInUser ? (
              <WeddingsListPage sx={{ flexGrow: 1 }} />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route
            path="/weddings/:weddingIdOrSlug"
            render={({ match }) => (
              <WeddingPage
                weddingIdOrSlug={match.params.weddingIdOrSlug}
                sx={{ flexGrow: 1 }}
              />
            )}
          />

          <Route>
            <Redirect to="/weddings" />
          </Route>
        </Switch>
      </TopLevelModalsWrapper>
    </BrowserRouter>
  );
}
