export default function weddingFoldersReducer(state, action) {
  const { type, ...data } = action;

  if (type === "SET") {
    const { folders } = data;
    return folders;
  } else if (type === "CREATE") {
    const { folderData } = data;
    return state.concat(folderData);
  } else if (type === "RENAME") {
    const { id, folderName } = data;
    return state.map((f) =>
      f.id === id
        ? {
            ...f,
            folderName: [
              ...f.folderName.split("/").slice(0, -1),
              folderName,
            ].join("/"),
          }
        : f
    );
  } else if (type === "REPLACE") {
    // used to replace temp folder data on receiving persisted folder data
    //   from backend
    const { id, folderData } = data;
    return state.map((f) => (f.id === id ? folderData : f));
  } else if (type === "DELETE") {
    const { id } = data;
    return state.filter((f) => f.id !== id);
  } else {
    throw new Error(
      `Invalid action type '${type}' received by weddingFoldersDispatch`
    );
  }
}
