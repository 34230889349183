import MenuIcon from "@mui/icons-material/Menu";

import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { Link } from "react-router-dom";

import { LoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";
import { getUserFullName } from "@@utils";

import AppBarMenu from "./AppBarMenu";

import logo from "@@img/logo64.png";

export default function AppTopbar({ sx, ...restProps }) {
  const theme = useTheme();

  const loggedInUser = React.useContext(LoggedInUserContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = (event) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  return (
    <AppBar
      sx={{
        // override the default MuiAppBar position of 'fixed'
        position: "static",

        backgroundColor: "transparent",
        boxShadow: "none",
        "& .MuiToolbar-root": {
          padding: "1rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        },
        ...sx,
      }}
      {...restProps}
    >
      <Toolbar>
        <Box
          component={Link}
          to="/"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            textDecoration: "none",
          }}
        >
          <Box
            component="img"
            src={logo}
            alt="Fotobot logo"
            sx={{ width: "3rem", height: "3rem" }}
          />
        </Box>

        <Box
          sx={{
            marginLeft: "auto",
            display: "flex",
            alignItems: "center",
            gap: { xs: "1.5rem", sm: "2rem" },
          }}
        >
          <Box
            onClick={openMenu}
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              [theme.breakpoints.up("md")]: { ":hover": { cursor: "pointer" } },
            }}
          >
            {loggedInUser ? (
              <Avatar
                alt={loggedInUser ? getUserFullName(loggedInUser) : ""}
                src={loggedInUser.profilePicUrl}
                sx={{ width: "3rem", height: "3rem", cursor: "pointer" }}
              />
            ) : (
              <Button
                disableElevation
                variant="contained"
                sx={{
                  minWidth: "unset !important",
                  width: "2.5rem",
                  height: "2.5rem",
                }}
              >
                <MenuIcon />
              </Button>
            )}
          </Box>

          <AppBarMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={closeMenu}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
