import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import cx from "classnames";
import React from "react";

import CloseButton from "@@components/common/Buttons/CloseButton";

function CustomModal(
  { hideCloseButton, onClose, children, sx, className, BoxProps, ...restProps },
  ref
) {
  const {
    sx: boxSx,
    className: boxClassName,
    ...restBoxProps
  } = BoxProps ?? {};

  return (
    <Modal
      ref={ref}
      className={cx("custom-modal", className)}
      sx={sx}
      onClose={onClose}
      {...restProps}
    >
      <Box
        className={cx("custom-modal-content-container", boxClassName)}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "24rem",
          maxHeight: "100vh",
          overflowY: "auto",
          padding: "3rem",
          backgroundColor: "white",
          borderRadius: "1rem",
          display: "flex",
          flexDirection: "column",
          ...boxSx,
        }}
        {...restBoxProps}
      >
        <CloseButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
            zIndex: 10,
            visibility: hideCloseButton ? "hidden" : "visible",
          }}
        />

        {children}
      </Box>
    </Modal>
  );
}

export default React.forwardRef(CustomModal);
