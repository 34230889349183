import { useFormContext } from "react-hook-form";
import cx from "classnames";
import React from "react";

import CustomTextField from "@@components/common/CustomTextField";
import { isValidPhone } from "@@utils/inputValidationUtils";

function PhoneNumberField(
  { required, InputProps, className, ...restProps },
  ref
) {
  const { errors, register } = useFormContext();

  return (
    <CustomTextField
      inputRef={ref}
      className={cx(className, "phone-number-input")}
      label={`Phone Number${required ? " *" : ""}`}
      error={!!errors?.phoneNumber}
      helperText={errors?.phoneNumber?.message ?? ""}
      InputProps={{
        placeholder: "Enter ph. no. with country code",
        ...InputProps,
      }}
      {...register("phoneNumber", {
        required: { value: required, message: "This field is required." },
        validate: isValidPhone,
      })}
      size="normal"
      margin="normal"
      {...restProps}
    />
  );
}

export default React.forwardRef(PhoneNumberField);
