import AddIcon from "@mui/icons-material/Add";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";
import React from "react";

import CloseButton from "@@components/common/Buttons/CloseButton";
import { LoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";
import ChooseFromPeopleAtWedding from "./ChooseFromPeopleAtWedding";
import ImageForFaceSelection from "./ImageForFaceSelection";

export default function PersonFilter({
  wedding,
  mediaFilters,
  mediaFiltersDispatch,
}) {
  const loggedInUser = React.useContext(LoggedInUserContext);
  const word =
    mediaFilters.mediaType === "IMAGE"
      ? "photos"
      : mediaFilters.mediaType === "VIDEO"
      ? "videos"
      : "media";

  // small hack to make the most recently selected item
  //   appear first in the UI; fixes https://trello.com/c/1C64Ondx
  const featuringFilterDataOrdered = []
    .concat(mediaFilters.featuring.data)
    .reverse();

  return (
    <Box className="person-filters">
      <Box sx={{ marginBottom: "0.5rem", display: "flex", gap: "0.375rem" }}>
        <Typography color="grey.dark">Show {word} of </Typography>

        <TextField
          select
          variant="standard"
          size="small"
          value={mediaFilters.featuring.type}
          sx={{
            position: "relative",
            top: "-1px",
            "& .MuiSelect-select": { paddingBottom: 0 },
            "& .MuiMenuItem-root": { color: "grey.dark" },
          }}
          onChange={(e) =>
            mediaFiltersDispatch({
              type: "CHANGE_TYPE_OF_FEATURING_FILTER",
              data: e.target.value,
            })
          }
        >
          <MenuItem value={"anyOf"}>any of</MenuItem>
          <MenuItem value={"allOf"}>all of</MenuItem>
        </TextField>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "0.5rem",
        }}
      >
        <AddFeaturingFilterOption
          wedding={wedding}
          mediaFilters={mediaFilters}
          mediaFiltersDispatch={mediaFiltersDispatch}
        />

        {featuringFilterDataOrdered.map(
          ({ id, faceId, tmpId, name, label, faceThumbUrl, profilePicUrl }) => {
            label = id && id === loggedInUser?.id ? "me" : name || label || "";
            return (
              <Chip
                key={
                  id
                    ? `user-${id}`
                    : `face-${faceId ? `selected-${faceId}` : `drawn-${tmpId}`}`
                }
                variant="outlined"
                label={label}
                avatar={
                  <Avatar
                    src={profilePicUrl ?? faceThumbUrl}
                    alt={label}
                    sx={{
                      width: "2.5rem !important",
                      height: "2.5rem !important",
                      marginLeft: "0 !important",
                    }}
                  />
                }
                sx={{
                  height: "2.5rem",
                  borderRadius: "5rem",
                  color: "grey.dark",
                }}
                onDelete={() =>
                  mediaFiltersDispatch({
                    type: "REMOVE_FROM_FEATURING_FILTER",
                    data: { id, faceId, tmpId },
                  })
                }
              />
            );
          }
        )}
      </Box>
    </Box>
  );
}

function AddFeaturingFilterOption({
  wedding,
  mediaFilters,
  mediaFiltersDispatch,
  ...restProps
}) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [
    objectUrlOfPhotoForFaceSelection,
    setObjectUrlOfPhotoForFaceSelection,
  ] = React.useState(null);

  const photoInputRef = React.useRef(null);

  const revokeObjectUrlOfPhotoForFaceSelection = () => {
    URL.revokeObjectURL(objectUrlOfPhotoForFaceSelection);
    setObjectUrlOfPhotoForFaceSelection(null);

    // reset the input so choosing the same file again does not
    //   fail to trigger the onchange handler of the input
    photoInputRef.current.value = "";
  };

  return (
    <Box className="add-featuring-filter-option-container" {...restProps}>
      <IconButton
        variant="outlined"
        sx={{
          height: "2.5rem",
          width: "2.5rem",
          border: `1px solid ${theme.palette.grey.light}`,
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <AddIcon fontSize="small" />
      </IconButton>

      <Popover
        id={"add-featuring-filter-option-popover"}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            width: "20rem",
            padding: "1.5rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <ChooseFromPeopleAtWedding
            wedding={wedding}
            onChange={(selectedPerson) => {
              mediaFiltersDispatch({
                type: "TOGGLE_IN_FEATURING_FILTER",
                data: _.pick(selectedPerson, [
                  "id",
                  "name",
                  "profilePicUrl",
                  "faceId",
                  "label",
                  "faceThumbUrl",
                ]),
              });

              //analytics
              window.dataLayer.push({
                event: "person_filter_applied",
                user_action: "select-person-at-wedding",
              });
            }}
            selectedPersonIds={
              new Set(
                mediaFilters.featuring.data
                  .filter(({ id, faceId }) => id ?? faceId)
                  .map(({ id, faceId }) => id ?? faceId)
              )
            }
            label="Find by name"
            InputLabelProps={{ shrink: true }}
          />

          <Typography
            variant="caption"
            sx={{ color: "grey.main", textAlign: "center" }}
          >
            OR
          </Typography>

          <input
            type="file"
            accept="image/png, image/jpeg"
            style={{ display: "none" }}
            onChange={(e) => {
              const [file] = e.target.files;
              if (file) {
                enqueueSnackbar(
                  `Please draw a box around the face of the person you want to search for`,
                  { variant: "info", preventDuplicate: true }
                );
                setObjectUrlOfPhotoForFaceSelection(URL.createObjectURL(file));
              }
            }}
            ref={photoInputRef}
          />
          <Button
            variant="outlined"
            onClick={() => photoInputRef.current.click()}
          >
            Find by face
          </Button>
          <Typography variant="caption" sx={{ color: "grey.main" }}>
            Upload a photo containing the person's face so our system knows who
            to look for
          </Typography>
        </Box>
      </Popover>

      <Modal
        open={!!objectUrlOfPhotoForFaceSelection}
        onClose={revokeObjectUrlOfPhotoForFaceSelection}
        sx={{ "& .MuiBackdrop-root": { backgroundColor: "#000C" } }}
      >
        <Box
          sx={{ position: "relative", width: "100vw", height: "100vh" }}
          onClick={revokeObjectUrlOfPhotoForFaceSelection}
        >
          <CloseButton
            sx={{
              position: "absolute",
              top: "0.5rem",
              right: "0.5rem",
              zIndex: 2,
              color: "white",
            }}
            onClick={revokeObjectUrlOfPhotoForFaceSelection}
          />

          <ImageForFaceSelection
            src={objectUrlOfPhotoForFaceSelection}
            onSelect={(data) => {
              mediaFiltersDispatch({ type: "ADD_TO_FEATURING_FILTER", data });

              enqueueSnackbar(
                `Added this person to current filter. Don't forget to apply it from the filters pane!`,
                { variant: "info" }
              );

              //analytics
              window.dataLayer.push({
                event: "person_filter_applied",
                user_action: "select-face-from-photo-outside-gallery",
              });
            }}
            onDeselect={(data) =>
              mediaFiltersDispatch({
                type: "REMOVE_FROM_FEATURING_FILTER",
                data,
              })
            }
          />
        </Box>
      </Modal>
    </Box>
  );
}
