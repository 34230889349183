export default function weddingMediaReducer(state, action) {
  const { type, data } = action;

  if (type === "SET") {
    return data;
  } else if (type === "ADD") {
    const idsOfMediaAlreadyPresent = new Set(state.map((m) => m.id));

    let mediaToAdd = Array.isArray(data) ? data : [data];
    // ensure duplicates are not added
    mediaToAdd = mediaToAdd.filter((m) => !idsOfMediaAlreadyPresent.has(m.id));
    return state.concat(mediaToAdd).sort((a, b) => b.id - a.id);
  } else if (type === "DELETE") {
    // Q: Why place a delete marker, rather than just removing the item?
    // In the WeddingGallery component, we sort the images being shown
    //   in order of descending id
    // When piling these images, we use the highest id in the pile
    //  to determine the position of the pile in the gallery
    // If the user deleted the highest-id image in the pile, and we remove
    //   it from the media array instead of placing a delete marker like we are
    //   now, then the pile the image was in will jump to a different position
    //   in the gallery, since the highest-id in it has changed
    // This is terrible UX
    // The pile should really stay in the same place, and just have the
    //   just-deleted image removed from it
    // By retaining the just-deleted image in the media array, we ensure that
    //   piles continue to retain their original highest-id, and thus stay in
    //   the same position in the gallery
    const { id } = data;
    return weddingMediaReducer(state, {
      type: "UPDATE",
      data: { id, updateData: { __deleteMarker: true } },
    });
  } else if (type === "UNDO_DELETE") {
    const { id } = data;
    return weddingMediaReducer(state, {
      type: "UPDATE",
      data: { id, updateData: { __deleteMarker: false } },
    });
  } else if (type === "UPDATE") {
    const { id, updateData } = data;

    const idxOfMediaToUpdate = state.findIndex((m) => m.id === id);
    if (idxOfMediaToUpdate === -1) {
      throw new Error(`Media with id ${id} not found`);
    }
    const mediaToUpdate = state[idxOfMediaToUpdate];
    const updatedMedia = { ...mediaToUpdate, ...updateData };

    const newState = [].concat(state);
    newState.splice(idxOfMediaToUpdate, 1, updatedMedia);
    return newState;
  } else if (type === "UPDATE_FACE_LABELS") {
    const { mediaAndFaceIdsToUpdate, newLabel } = data;

    const setOfAffectedMediaIds = new Set(
      mediaAndFaceIdsToUpdate.map(({ mediaId }) => mediaId)
    );
    const setOfMediaIdAndFaceIdCombosToUpdate = new Set(
      mediaAndFaceIdsToUpdate.map(
        ({ mediaId, faceId }) => `${mediaId}__${faceId}`
      )
    );

    return state.map((media) =>
      setOfAffectedMediaIds.has(media.id)
        ? {
            ...media,
            usersRecognizedInMedia: media.usersRecognizedInMedia.map((urm) =>
              setOfMediaIdAndFaceIdCombosToUpdate.has(
                `${media.id}__${urm.faceId}`
              )
                ? { ...urm, label: newLabel }
                : urm
            ),
          }
        : media
    );
  } else {
    throw new Error(`Invalid action type: ${type}`);
  }
}
