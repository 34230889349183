import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import cx from "classnames";
import React from "react";
import { useForm } from "react-hook-form";

import CloseButton from "@@components/common/Buttons/CloseButton";
import CustomTextField from "@@components/common/CustomTextField";

function EditableTypography({
  value,
  placeholder,
  onChange,
  sx,
  TextFieldProps,
  TypographyProps,
  disabled,
  ...restProps
}) {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: { value: value ?? "" },
  });

  const [editing, setEditing] = React.useState(false);

  if (editing) {
    return (
      <Box
        component="form"
        className={cx("editable-typography-container")}
        sx={{ display: "flex", gap: "0.5rem", ...sx }}
        onSubmit={handleSubmit(async (data) => {
          await onChange(data);
          setEditing(false);
        })}
        {...restProps}
      >
        <CustomTextField
          size="small"
          autoFocus
          inputProps={{ style: { paddingLeft: "2px", paddingRight: "2px" } }}
          {...register("value", { required: true })}
          {...TextFieldProps}
        />

        <IconButton type="submit" sx={{ padding: 0 }}>
          <DoneIcon sx={{ fontSize: "0.9rem" }} />
        </IconButton>

        <CloseButton
          type="button"
          onClick={() => {
            reset();
            setEditing(false);
          }}
          sx={{ padding: 0 }}
          IconProps={{ sx: { fontSize: "0.9rem" } }}
        />
      </Box>
    );
  }

  return (
    <Box
      className={cx("editable-typography-container")}
      sx={{
        position: "relative",
        top: "2px",
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        ...sx,
      }}
      onClick={() => !disabled && setEditing(true)}
      {...restProps}
    >
      <Typography variant="caption" {...TypographyProps}>
        {value ?? placeholder}
      </Typography>
      <EditIcon sx={{ fontSize: "0.8rem" }} />
    </Box>
  );
}

export default EditableTypography;
