import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm } from "react-hook-form";

import CustomTextField from "@@components/common/CustomTextField";
import { updateWedding } from "@@services/fotobot-api.service";

export default function WeddingDetailsForm({ wedding, setWedding }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      coverPhoto: null,
      ..._.pick(wedding, [
        "name",
        "passcode",
        "slug",
        "revealMediaDone",
        "guestUploadsArePublicByDefault",
        "photographerUploadsArePublicByDefault",
        "anonymousUploadsAreAllowed",
      ]),
    },
  });
  const { enqueueSnackbar } = useSnackbar();

  const [submitting, setSubmitting] = React.useState(false);

  return (
    <Box
      component="form"
      className="change-wedding-details-form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        "& .input-adornment .MuiTypography-root": { fontSize: "0.75rem" },
      }}
    >
      <CustomTextField
        label="Name *"
        error={errors.name}
        {...register("name", {
          required: { value: true, message: "This field is required" },
          minLength: {
            value: 3,
            message: "Please enter at least 3 characters",
          },
        })}
      />

      <CustomTextField
        label="Link *"
        error={errors.slug}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className="input-adornment">
              /weddings/
            </InputAdornment>
          ),
        }}
        {...register("slug", {
          required: true,
          pattern: {
            value: /^[a-z0-9-]+$/,
            message: "Lowercase alphabets, numbers, and hyphens only",
          },
        })}
      />

      <CustomTextField
        label="Passcode *"
        error={errors.passcode}
        {...register("passcode", {
          required: { value: true, message: "This field is required" },
          minLength: {
            value: 3,
            message: "Please enter at least 3 characters",
          },
        })}
      />

      <FormControl variant="standard">
        <InputLabel shrink htmlFor="select-wedding-cover-photo">
          Change cover photo
        </InputLabel>
        <Input
          id="select-wedding-cover-photo"
          type="file"
          accept="image/*"
          {...register("coverPhoto")}
        />
      </FormControl>

      <FormControlLabel
        label="Let guests view public photos/videos"
        slotProps={{ typography: { variant: "caption" } }}
        control={
          <Checkbox
            defaultChecked={wedding.revealMediaDone}
            {...register("revealMediaDone")}
          />
        }
      />

      <FormControlLabel
        label="Make photos/videos uploaded by guests public by default"
        slotProps={{ typography: { variant: "caption" } }}
        control={
          <Checkbox
            defaultChecked={wedding.guestUploadsArePublicByDefault}
            {...register("guestUploadsArePublicByDefault")}
          />
        }
      />

      <FormControlLabel
        label="Make photos/videos uploaded by the wedding photographer public by default"
        slotProps={{ typography: { variant: "caption" } }}
        control={
          <Checkbox
            defaultChecked={wedding.photographerUploadsArePublicByDefault}
            {...register("photographerUploadsArePublicByDefault")}
          />
        }
      />

      <FormControlLabel
        label="Allow guests to upload photos/videos anonymously"
        slotProps={{ typography: { variant: "caption" } }}
        control={
          <Checkbox
            defaultChecked={wedding.anonymousUploadsAreAllowed}
            {...register("anonymousUploadsAreAllowed")}
          />
        }
      />

      <LoadingButton loading={submitting} variant="contained" type="submit">
        Submit
      </LoadingButton>
    </Box>
  );

  async function onSubmit(data) {
    data.coverPhoto = data.coverPhoto?.[0] ?? null;

    setSubmitting(true);

    try {
      const { data: updatedWedding } = await updateWedding(wedding.id, data);
      enqueueSnackbar("Update successful", {
        variant: "success",
      });
      setWedding && setWedding((w) => ({ ...w, ...updatedWedding }));
    } catch (e) {
      enqueueSnackbar(e.response?.data?.error ?? e.message, {
        variant: "error",
      });
      throw e;
    } finally {
      setSubmitting(false);
    }
  }
}
