import React from "react";
import { useSnackbar } from "notistack";

import { SetLoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";
import { getUserWeddings } from "@@services/fotobot-api.service";
import { getMainDateForWedding } from "@@utils";

export default function useWeddingsList() {
  const setLoggedInUser = React.useContext(SetLoggedInUserContext);
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = React.useState({
    weddings: [],
    loading: true,
    error: null,
  });

  React.useEffect(() => {
    (async () => {
      try {
        let { data: weddings } = await getUserWeddings();
        const weddingsSorted = weddings.sort((a, b) => {
          const aMainDate = getMainDateForWedding(a);
          const bMainDate = getMainDateForWedding(b);

          if (!aMainDate) return -1;
          if (!bMainDate) return 1;
          return new Date(bMainDate) - new Date(aMainDate);
        });
        setState((oldState) => ({
          ...oldState,
          loading: false,
          weddings: weddingsSorted,
        }));
      } catch (e) {
        setState((oldState) => ({ ...oldState, loading: false, error: e }));
        if (e.response?.status === 401) {
          setLoggedInUser(null);
        } else {
          enqueueSnackbar("There was an error retrieving your weddings", {
            variant: "error",
          });
          throw e;
        }
      }
    })();
  }, [setLoggedInUser, enqueueSnackbar]);

  return state;
}
