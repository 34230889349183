import { contactUsWhatsappLinkUrl } from "@@config";

export function getWeddingLink(weddingOrWeddingIdOrWeddingSlug) {
  const identifier =
    typeof weddingOrWeddingIdOrWeddingSlug === "object"
      ? weddingOrWeddingIdOrWeddingSlug.slug ??
        weddingOrWeddingIdOrWeddingSlug.id
      : weddingOrWeddingIdOrWeddingSlug;

  const url = new URL(window.location.origin);
  url.pathname = `/weddings/${identifier}`;
  return url.href;
}

export function handleGetHelpButtonClick(e) {
  e.preventDefault();

  // analytics
  window.dataLayer.push({ event: "chat_with_us_button_clicked" });

  window.open(contactUsWhatsappLinkUrl, "_blank").focus();
}
