import { useRouteMatch } from "react-router-dom";

export default function useWeddingMediaFiltersFromUrl() {
  const routeMatch = useRouteMatch(
    "/weddings/:weddingId/:tabName?/:b64Filters?"
  );
  const { tabName, b64Filters } = routeMatch.params;

  if (!["photos", "videos"].includes(tabName)) {
    return {};
  }

  const filtersFromUrl = {
    mediaType: routeMatch.params.tabName === "videos" ? "VIDEO" : "IMAGE",
  };
  if (b64Filters) {
    Object.assign(filtersFromUrl, JSON.parse(atob(b64Filters)));
  }

  return filtersFromUrl;
}
