// wrapper that allows BasicDetailsForm to be independent of
//   createWeddingFlowFsm's state/context

import _ from "lodash";
import React from "react";

import CreateWeddingFlowBasicDetailsForm from "@@components/common/CreateWeddingFlow/BasicDetailsForm";
import { CreateWeddingFlowFsmStateContext } from "@@contexts/CreateWeddingFlowFsmContexts";

export default function BasicDetailsFormWrapper({ ...allProps }) {
  const fsmState = React.useContext(CreateWeddingFlowFsmStateContext);

  const defaultValues = _.pick(fsmState.context.createWeddingData, [
    "name",
    "date",
    "role",
  ]);

  return (
    <CreateWeddingFlowBasicDetailsForm
      {...allProps}
      defaultValues={defaultValues}
    />
  );
}
