import React from "react";
import { LoggedInUserContext } from "./LoggedInUserContextsWrapper";
import { getFolders } from "@@services/wedding-folder.service";
import weddingFoldersReducer from "@@reducers/weddingFoldersReducer";

export const CurrentWeddingFoldersContext = React.createContext([]);
CurrentWeddingFoldersContext.displayName = "CurrentWeddingFoldersContext";

export const CurrentWeddingFoldersDispatchContext = React.createContext(
  () => {}
);
CurrentWeddingFoldersDispatchContext.displayName =
  "CurrentWeddingFoldersDispatchContext";

export default function CurrentWeddingFoldersContextsWrapper({
  wedding,
  onError,
  children,
}) {
  const loggedInUser = React.useContext(LoggedInUserContext);

  const [folders, foldersDispatch] = React.useReducer(
    weddingFoldersReducer,
    []
  );

  React.useEffect(() => {
    (async () => {
      try {
        const { data: folders } = await getFolders(wedding.id);
        foldersDispatch({ type: "SET", folders });
      } catch (e) {
        onError && onError(e);
      }
    })();
  }, [wedding, loggedInUser, onError]);

  return (
    <CurrentWeddingFoldersContext.Provider value={folders}>
      <CurrentWeddingFoldersDispatchContext.Provider value={foldersDispatch}>
        {children}
      </CurrentWeddingFoldersDispatchContext.Provider>
    </CurrentWeddingFoldersContext.Provider>
  );
}
