import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useRouteMatch } from "react-router-dom";

import CustomModal from "@@components/common/CustomModal";
import CustomTextField from "@@components/common/CustomTextField";
import {
  CurrentWeddingFoldersContext,
  CurrentWeddingFoldersDispatchContext,
} from "@@contexts/CurrentWeddingFoldersContexts";
import { createFolder } from "@@services/wedding-folder.service";
import { snakeCaseToSentenceCase } from "@@utils/strUtils";
import WeddingFolder from "./WeddingFolder";

export default function WeddingFolders({ folders, showEditControls, sx }) {
  const routeMatch = useRouteMatch(
    "/weddings/:weddingId/:tabName?/:b64Filters?"
  );
  const theme = useTheme();

  const [openCreateFolderModal, setOpenCreateFolderModal] =
    React.useState(false);

  const { weddingId, tabName, b64Filters } = routeMatch.params;
  const curMediaPath = b64Filters
    ? JSON.parse(atob(b64Filters)).mediaPath ?? ""
    : "";

  const pathParts = curMediaPath.split("/");
  const parentPath = pathParts.slice(0, pathParts.length - 1).join("/");

  const getFolderHref = (mediaPath) =>
    `/weddings/${weddingId}/${tabName}/${btoa(JSON.stringify({ mediaPath }))}`;

  return (
    <Box
      className="wedding-folders"
      sx={{ display: "flex", flexDirection: "column", ...sx }}
    >
      <Box
        className="breadcrumbs"
        sx={{
          width: "100%",
          display: curMediaPath === "" ? "none" : "flex",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <Box
          component={Link}
          to={getFolderHref(parentPath)}
          sx={{
            /* makes the 'a' tag the same size as the children, preventing
               mis-alignment */
            display: "flex",
          }}
        >
          <ArrowBackIosNewIcon sx={{ fontSize: "1rem" }} />
        </Box>

        <Typography
          variant="caption"
          sx={{ color: theme.palette.grey.dark, textAlign: "center" }}
        >
          / {pathParts.map(snakeCaseToSentenceCase).join(" / ")}
        </Typography>

        {showEditControls ? (
          <Button
            variant="outlined"
            size="small"
            startIcon={<AddIcon />}
            onClick={() => setOpenCreateFolderModal(true)}
            sx={{ "& .MuiButton-startIcon": { margin: "0" } }}
          >
            <Box component="span" sx={{ marginTop: "0.0625rem" }}>
              New
            </Box>
          </Button>
        ) : null}
      </Box>

      <Box
        className="folders"
        sx={{
          marginTop: "1rem",
          display: folders.length > 0 ? "flex" : "none",
          gap: "1rem",
          flexWrap: "wrap",
        }}
      >
        {folders.sort(weddingFolderCmp).map((folder) => (
          <WeddingFolder
            key={folder.id}
            showOptionsMenu={showEditControls}
            href={getFolderHref(folder.folderName)}
            folder={folder}
          />
        ))}
      </Box>

      <CustomModal
        open={openCreateFolderModal}
        onClose={() => setOpenCreateFolderModal(false)}
      >
        <Typography component="h1" variant="h5" sx={{ marginBottom: "1rem" }}>
          Create Folder
        </Typography>

        <CreateFolderForm
          weddingId={weddingId}
          parentFolderPath={curMediaPath}
          afterSubmit={() => setOpenCreateFolderModal(false)}
          onCancel={() => setOpenCreateFolderModal(false)}
        />
      </CustomModal>
    </Box>
  );
}

function CreateFolderForm({
  weddingId,
  parentFolderPath,
  afterSubmit,
  onCancel,
}) {
  const currentWeddingFolders = React.useContext(CurrentWeddingFoldersContext);
  const currentWeddingFoldersDispatch = React.useContext(
    CurrentWeddingFoldersDispatchContext
  );
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, register } = useForm();

  const [submitting, setSubmitting] = React.useState(false);

  const parentFolderId = currentWeddingFolders.find(
    (f) => f.folderName === parentFolderPath
  ).id;

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
    >
      <CustomTextField
        label="Folder Name *"
        {...register("folderName", { required: true })}
      />

      <Box sx={{ display: "flex", gap: "1rem" }}>
        <LoadingButton loading={submitting} type="submit" variant="contained">
          Create
        </LoadingButton>
        <Button type="button" onClick={onCancel} disabled={submitting}>
          Cancel
        </Button>
      </Box>
    </Box>
  );

  async function onSubmit({ folderName }) {
    setSubmitting(true);
    try {
      const { data: folderData } = await createFolder(
        weddingId,
        folderName,
        parentFolderId
      );
      currentWeddingFoldersDispatch({ type: "CREATE", folderData });
      afterSubmit();
    } catch (e) {
      enqueueSnackbar(e.response?.data?.error ?? e.message, {
        variant: "error",
      });
    } finally {
      setSubmitting(false);
    }
  }
}

function weddingFolderCmp(a, b) {
  return a.folderName.toLowerCase().includes("photographer")
    ? -1
    : b.folderName.toLowerCase().includes("photographer")
    ? 1
    : a.folderName.localeCompare(b.folderName);
}
