import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import cx from "classnames";
import _ from "lodash";
import React from "react";
import { useForm } from "react-hook-form";

import CustomTextField from "@@components/common/CustomTextField";
import { isValidPhone } from "@@utils/inputValidationUtils";

import VendorAutocomplete from "./VendorAutocomplete";

export default function AddVendorForm({
  onSubmit,
  className,
  sx,
  ...restProps
}) {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: { role: "", id: null, name: "", adminPhoneNumber: "" },
  });
  const watchVendorId = watch("id");

  const [submitting, setSubmitting] = React.useState(false);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmitWrapper)}
      className={cx("add-vendor-form", className)}
      sx={{ display: "flex", flexDirection: "column", gap: "1.5rem", ...sx }}
      {...restProps}
    >
      <CustomTextField
        label="Role *"
        InputProps={{
          placeholder: "Ex: 'Photography', 'Event planning', 'Catering'",
        }}
        error={errors.role}
        {...register("role", { required: true, minLength: 3 })}
      />

      <VendorAutocomplete
        freeSolo
        onChange={(value) => {
          setValue("id", value?.id ? value.id : null);
          setValue("name", value?.name ? value.name : "");
          setValue(
            "adminPhoneNumber",
            value?.adminPhoneNumber ? value.adminPhoneNumber : ""
          );
        }}
      />

      <CustomTextField
        label="Admin Phone *"
        helperText={`The phone number of the person from the vendor's side who should be able to manage the vendor's profile on Fotobot`}
        error={errors.adminPhoneNumber}
        disabled={!!watchVendorId}
        InputProps={{ placeholder: "Phone no. with country code" }}
        {...register("adminPhoneNumber", {
          required: true,
          validate: isValidPhone,
        })}
      />

      <LoadingButton
        type="submit"
        variant="contained"
        loading={submitting}
        sx={{ alignSelf: "flex-start" }}
      >
        Submit
      </LoadingButton>
    </Box>
  );

  async function onSubmitWrapper({ role, ...vendor }) {
    setSubmitting(true);
    try {
      await onSubmit({ role, vendor: _.pickBy(vendor) });
    } finally {
      setSubmitting(false);
    }
  }
}
