import LoadingButton from "@mui/lab/LoadingButton";
import cx from "classnames";
import React from "react";

function ThrottledButton(
  {
    throttleTimeSeconds,
    startThrottled,
    children,
    className,
    sx,
    onClick,
    ...restProps
  },
  ref
) {
  const [loading, setLoading] = React.useState(false);
  const [secondsToReactivation, setSecondsToReactivation] = React.useState(
    startThrottled ? throttleTimeSeconds : 0
  );

  const reactivationInterval = React.useRef(null);

  React.useEffect(() => {
    if (startThrottled) {
      reactivationInterval.current = setInterval(() => {
        setSecondsToReactivation((prev) => prev - 1);
      }, 1000);
    }

    // ensure interval is cleared when component is unmounted
    return () => clearInterval(reactivationInterval.current);
  }, [startThrottled]);

  React.useEffect(() => {
    if (secondsToReactivation <= 0) {
      clearInterval(reactivationInterval.current);
    }
  }, [secondsToReactivation]);

  return (
    <LoadingButton
      ref={ref}
      loading={loading}
      disabled={secondsToReactivation > 0}
      className={cx("throttled-button", className)}
      sx={{ ...sx }}
      onClick={handleClick}
      {...restProps}
    >
      {children}
      {secondsToReactivation > 0 ? ` (${secondsToReactivation})` : ""}
    </LoadingButton>
  );

  async function handleClick() {
    setLoading(true);
    try {
      await onClick();
      setSecondsToReactivation(throttleTimeSeconds);
      reactivationInterval.current = setInterval(() => {
        setSecondsToReactivation((prev) => prev - 1);
      }, 1000);
    } finally {
      setLoading(false);
    }
  }
}

export default React.forwardRef(ThrottledButton);
