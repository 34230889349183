import Button from "@mui/material/Button";
import React from "react";

function PrimaryButton(props, ref) {
  return (
    <Button
      ref={ref}
      size="large"
      variant="contained"
      color="primary"
      {...props}
    />
  );
}

export default React.forwardRef(PrimaryButton);
