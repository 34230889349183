import { generateRandomString as getRandStr } from "@ack_inc/utils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import React from "react";

import WeddingMediaDispatchContext from "@@contexts/WeddingMediaDispatchContext";
import { updateWeddingMedia } from "@@services/wedding-media.service";

export default function WeddingMediaPublicPrivateToggle({
  media,
  onClick,
  sx,
  ...restProps
}) {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const mediaDispatch = React.useContext(WeddingMediaDispatchContext);
  const lastNotifIdRef = React.useRef(null);

  if (!media) return null;

  return (
    <IconButton
      disableRipple
      size="small"
      onClick={togglePubPvtSetting}
      sx={{
        color: theme.palette.primary.light,
        textTransform: "none",
        "& .MuiButton-startIcon .MuiSvgIcon-root": { fontSize: "1.3rem" },
        ...sx,
      }}
      {...restProps}
    >
      {media.isPublic ? <VisibilityIcon /> : <VisibilityOffIcon />}
    </IconButton>
  );

  async function togglePubPvtSetting() {
    if (lastNotifIdRef.current) closeSnackbar(lastNotifIdRef.current);

    const oldData = media;
    const updateData = { isPublic: !media.isPublic };

    mediaDispatch({ type: "UPDATE", data: { id: media.id, updateData } });

    lastNotifIdRef.current = `toggle-pubpvt-${media.id}-${getRandStr(6)}`;
    enqueueSnackbar(
      `This ${media.type === "IMAGE" ? "photo" : "video"} is now ${
        updateData.isPublic ? "public" : "private"
      }`,
      { key: lastNotifIdRef.current, variant: "info" }
    );

    try {
      await updateWeddingMedia(media.weddingId, media.id, updateData);
    } catch (e) {
      mediaDispatch({
        type: "UPDATE",
        data: { id: media.id, updateData: oldData },
      });
      const errMsg = e.response?.data?.error ?? e.message;
      enqueueSnackbar(`Error updating media: ${errMsg}`, { variant: "error" });
    }
  }
}
