import CollectionsIcon from "@mui/icons-material/Collections";
import PeopleIcon from "@mui/icons-material/People";
import StorefrontIcon from "@mui/icons-material/StorefrontRounded";
import TheatersIcon from "@mui/icons-material/Theaters";

export const PRIVILEGED_WEDDING_ROLES = [
  "ADMIN",
  "BRIDE",
  "GROOM",
  "CLIENT_OTHER",
];

export const systemUserId = 0;

export const testAccounts = [
  { name: "Demobride", phoneNumber: "+911111111111" },
  { name: "Demogroom", phoneNumber: "+912222222222" },
];
export const testPhoneNumbers = testAccounts.map(
  ({ phoneNumber }) => phoneNumber
);
export const testBride = testAccounts[0];
export const testGroom = testAccounts[1];

export const contactUsPhoneNumber = "+919916812170";
export const contactUsWhatsappLinkUrl = `https://wa.me/${contactUsPhoneNumber.replace(
  /\D/g,
  ""
)}?text=${encodeURIComponent("Hi, I have a question about Fotobot.")}`;

export const fotobotPhoneNumber = process.env.REACT_APP_FOTOBOT_WHATSAPP_NUMBER;

export const nMediaPerApiRequest = { DEFAULT: 30, MAX: 100 };

export const weddingCoverPhotoDesiredWidthPx = 900;

const weddingRoleSortOrder_ = {
  BRIDE: 0,
  GROOM: 1,
  CLIENT_OTHER: 2,
  PHOTOGRAPHER: 3,
  INVITEE: 4,
  UNINVITED: 9,
};
export const weddingRoleSortOrder = new Proxy(weddingRoleSortOrder_, {
  get(target, prop) {
    return target[prop] ?? Infinity;
  },
});

export const standardMessages = {
  NOT_AUTHENTICATED: "Please log in.",
};

export const demoWedding = { passcode: "demo-wedding", slug: "demo-wedding" };

export const defaultWeddingMediaFilters = {
  savedFilterUuid: null,
  mediaType: undefined,
  mediaPath: undefined,
  featuring: { type: "anyOf", data: [] },
  numPersons: { type: "gte", data: 0 },
  uploadedBy: undefined,
  isPublic: undefined,
};

export const cookies = {
  loggedInUserSelfieUpdated: {
    name: "selfieUpdatedAt",
    maxAge: 120,
  },
};

export const weddingPageTabs = [
  {
    name: "photos",
    icon: <CollectionsIcon />,
  },
  {
    name: "videos",
    icon: <TheatersIcon />,
  },
  {
    name: "people",
    icon: <PeopleIcon />,
  },
  {
    name: "vendors",
    icon: <StorefrontIcon />,
  },
];

export const standardApiResponses = {
  UNAUTHENTICATED_USED_APPLIED_FEATURING_FILTER_ON_SELF:
    "Sorry, you must log in before we can find media featuring you",
};
