import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useMachine } from "@xstate/react";
import cx from "classnames";
import _ from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";

import CustomModal from "@@components/common/CustomModal";
import CreateWeddingFlowBasicDetailsFormWrapper from "@@components/common/CreateWeddingFlow/BasicDetailsFormWrapper";
import CreateWeddingFlowKeyPeopleFormWrapper from "@@components/common/CreateWeddingFlow/KeyPeopleFormWrapper";
import InfoTypography from "@@components/common/InfoTypography";
import SplashContent from "@@components/common/SplashContent";
import createWeddingFlowFsm, {
  createWeddingFlowFsmStatesOrdered,
} from "@@components/common/CreateWeddingFlow/createWeddingFlowFsm";
import { LoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";
import { CreateWeddingFlowFsmStateContext } from "@@contexts/CreateWeddingFlowFsmContexts";
import { createWedding } from "@@services/fotobot-api.service";
import {
  delayMs,
  getUserObjWithFullName,
  getUserObjWithSplitName,
} from "@@utils";

import "animate.css";
import logoFilled from "@@img/logo256.jpeg";

// Questions
// How to reset state machine state when modal is closed / form is submitted?

export default function CreateWeddingModal({
  open,
  onClose,
  className,
  ...restProps
}) {
  const history = useHistory();
  const theme = useTheme();
  const loggedInUser = React.useContext(LoggedInUserContext);
  const [fsmState, fsmSend] = useMachine(createWeddingFlowFsm);

  const keyPeopleFormMethodsRef = React.useRef(null);

  const handleClose = (...args) => {
    fsmSend("RESET");
    onClose(...args);
  };

  let errMsg;
  if (fsmState.value === "error") {
    const { createWeddingError: e } = fsmState.context;
    if (e.isAxiosError && e.response.status < 500) {
      errMsg = e.response.data.error;
    } else {
      errMsg = "Sorry, an error occurred. We're looking into it.";
    }
  }

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      className={cx(className, "create-wedding-modal")}
      {...restProps}
    >
      <IconButton
        className="custom-modal-back-button"
        onClick={() =>
          fsmSend("BACK_BUTTON_PRESSED", {
            data: keyPeopleFormMethodsRef.current.getValues(),
          })
        }
        sx={{
          position: "absolute",
          top: "1rem",
          left: "1rem",
          zIndex: 10,
          visibility:
            fsmState.value === "askKeyPeopleDetails" ? "visible" : "hidden",
        }}
      >
        <ArrowBackIcon sx={{ color: theme.palette.grey.main }} />
      </IconButton>

      <CreateWeddingFlowFsmStateContext.Provider value={fsmState}>
        <Stepper
          activeStep={createWeddingFlowFsmStatesOrdered.indexOf(fsmState.value)}
          alternativeLabel
          sx={{
            margin: "1rem auto",
            minWidth: "15rem",
            "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
              marginTop: "0.5rem",
            },
          }}
        >
          <Step>
            <StepLabel>Basic Details</StepLabel>
          </Step>

          <Step>
            <StepLabel>Bride & Groom</StepLabel>
          </Step>
        </Stepper>

        {fsmState.value === "askBasicEventDetails" ? (
          <>
            <CreateWeddingFlowBasicDetailsFormWrapper
              id="create-wedding-flow-basic-details-form"
              onSubmit={(data) => {
                if (["BRIDE", "GROOM"].includes(data.role)) {
                  data[data.role.toLowerCase()] = _.pick(
                    getUserObjWithFullName(loggedInUser),
                    ["name", "phoneNumber"]
                  );
                }
                fsmSend("BASIC_EVENT_DETAILS_PROVIDED", { data });
              }}
              sx={{ margin: "0.5rem 0 0.75rem 0" }}
              SubmitButtonProps={{ sx: { display: "none" } }}
            />

            <Box className="controls" sx={{ display: "flex", gap: "0.5rem" }}>
              <Button
                type="submit"
                form="create-wedding-flow-basic-details-form"
                size="large"
                variant="contained"
                sx={{ marginLeft: "auto" }}
              >
                Continue
              </Button>
            </Box>
          </>
        ) : fsmState.value === "askKeyPeopleDetails" ? (
          <>
            <ExplanationForBrideAndGroomAsk />

            <CreateWeddingFlowKeyPeopleFormWrapper
              id="create-wedding-flow-key-people-form"
              onSubmit={handleKeyPeopleFormSubmit}
              sx={{ margin: "0.5rem 0 0.75rem 0" }}
              SubmitButtonProps={{ sx: { display: "none" } }}
              formMethodsRef={keyPeopleFormMethodsRef}
            />

            <Box
              className="controls"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                size="large"
                variant="outlined"
                onClick={handleKeyPeopleFormSkip}
              >
                Skip
              </Button>

              <Button
                type="submit"
                form="create-wedding-flow-key-people-form"
                size="large"
                variant="contained"
              >
                Continue
              </Button>
            </Box>
          </>
        ) : fsmState.value === "waiting" ? (
          <Box sx={{ minHeight: "20rem" }} className="flex-center-content">
            <SplashContent />
          </Box>
        ) : fsmState.value === "error" ? (
          <Box
            className="flex-center-content"
            sx={{
              position: "relative",
              minHeight: "20rem",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="body2"
              sx={{ color: "error.main", textAlign: "center" }}
            >
              {errMsg}
            </Typography>

            <Box
              className="controls"
              sx={{ position: "absolute", bottom: 0, width: "100%" }}
            >
              <Button
                size="large"
                variant="outlined"
                onClick={() => fsmSend("BACK_BUTTON_PRESSED")}
              >
                Back
              </Button>
            </Box>
          </Box>
        ) : (
          <Box sx={{ minHeight: "20rem" }} className="flex-center-content">
            <SplashContent
              sx={{
                animationName: "fadeIn",
                animationDuration: "2s",
                animationIterationCount: 1,
              }}
              ImageProps={{ src: logoFilled, sx: { opacity: 1 } }}
              loadingText="Success! We'll take you to the wedding gallery shortly ..."
              TextProps={{ sx: { color: "grey.dark", fontWeight: 500 } }}
            />
          </Box>
        )}
      </CreateWeddingFlowFsmStateContext.Provider>
    </CustomModal>
  );

  async function handleKeyPeopleFormSubmit(keyPeopleData) {
    const { name, date, role } = fsmState.context.createWeddingData;
    const curUserIsBrideOrGroom = ["BRIDE", "GROOM"].includes(role);
    const people = [
      {
        role: "BRIDE",
        ...getUserObjWithSplitName(keyPeopleData.bride),
      },
      {
        role: "GROOM",
        ...getUserObjWithSplitName(keyPeopleData.groom),
      },
      curUserIsBrideOrGroom
        ? null
        : {
            role: role === "RELATIVE_OR_FRIEND" ? "CLIENT_OTHER" : role,
            ..._.pick(loggedInUser, ["firstName", "lastName", "phoneNumber"]),
          },
    ].filter((x) => x);

    if (new Set(people.map((p) => p.phoneNumber)).size < people.length) {
      const { bride, groom } = keyPeopleData;
      const { setError, setFocus } = keyPeopleFormMethodsRef.current;

      if (
        !curUserIsBrideOrGroom &&
        loggedInUser.phoneNumber === bride.phoneNumber
      ) {
        setError("bride.phoneNumber", {
          type: "custom",
          message: "The bride cannot have the same phone number as you",
        });
        setFocus("bride.phoneNumber");
        return;
      }

      if (
        !curUserIsBrideOrGroom &&
        loggedInUser.phoneNumber === groom.phoneNumber
      ) {
        setError("groom.phoneNumber", {
          type: "custom",
          message: "The groom cannot have the same phone number as you",
        });
        setFocus("groom.phoneNumber");
        return;
      }

      if (bride.phoneNumber === groom.phoneNumber) {
        const error = {
          type: "custom",
          message: "The bride and groom cannot have the same phone number",
        };

        if (role === "BRIDE") {
          setError("groom.phoneNumber", error);
          setFocus("groom.phoneNumber");
        } else if (role === "GROOM") {
          setError("bride.phoneNumber", error);
          setFocus("bride.phoneNumber");
        } else {
          setError("groom.phoneNumber", error);
          setFocus("groom.phoneNumber");
        }

        return;
      }
    }

    fsmSend("KEY_PEOPLE_DETAILS_PROVIDED", { data: keyPeopleData });
    await createWeddingHelper(name, date, people);
  }

  async function handleKeyPeopleFormSkip() {
    const { name, date, role } = fsmState.context.createWeddingData;
    const people = [
      {
        role: role === "RELATIVE_OR_FRIEND" ? "CLIENT_OTHER" : role,
        ..._.pick(loggedInUser, ["firstName", "lastName", "phoneNumber"]),
      },
    ];

    fsmSend("KEY_PEOPLE_DETAILS_SKIPPED");
    await createWeddingHelper(name, date, people);
  }

  async function createWeddingHelper(name, date, people) {
    const dataToSubmit = {
      name,
      weddingEvents: [
        {
          name: "Wedding",
          // HACK: making an assumption that the wedding will be at
          //   6pm in the user's time zone
          // TODO: The user should be able to change this
          date: new Date(`${date} 18:00:00`).toISOString(),
        },
      ],
      people,
    };

    try {
      const {
        data: { slug },
      } = await createWedding(dataToSubmit);

      fsmSend("WEDDING_CREATED");

      await delayMs(2000);
      handleClose(null, "success");
      // TODO: nice page/view transition here?
      history.push(`/weddings/${slug}`);
    } catch (e) {
      fsmSend("ERROR_DURING_WEDDING_CREATION", { error: e });
      throw e;
    }
  }
}

function ExplanationForBrideAndGroomAsk({ className, sx, ...restProps }) {
  const btnRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  return (
    <Box
      className={cx(className, "explanation")}
      sx={{ textAlign: "center" }}
      {...restProps}
    >
      <Button
        size="small"
        ref={btnRef}
        sx={{ padding: 0, textDecoration: "underline" }}
        onClick={() => setOpen((x) => !x)}
      >
        Why?
      </Button>

      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={btnRef.current}
      >
        <InfoTypography sx={{ padding: "1rem", whiteSpace: "pre-line" }}>
          We'll reach out to the bride and groom (via whatsapp) to invite them
          to check out the gallery you're creating.{"\n\n"}If you're just trying
          out Fotobot, feel free to skip this step.
        </InfoTypography>
      </Popover>
    </Box>
  );
}
