// mobile-friendly tooltip that shows up on tap (mobile) and hover/click (desktop)
// The MUI-native Tooltip component doesn't work on mobile
// The MUI-native

import Box from "@mui/material/Box";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import React from "react";

export default function Tooltip({
  children,
  tooltipText,
  tooltipProps,
  timeoutMs,
}) {
  const timeoutRef = React.useRef(null);
  const toggleRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const { sx: tooltipSx, ...restTooltipProps } = tooltipProps;

  React.useEffect(() => {
    if (!timeoutMs) return;

    if (open) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => setOpen(false), timeoutMs);
    }
  }, [open, timeoutMs]);

  return (
    <>
      <ClickAwayListener onClickAway={(e) => setOpen(false)}>
        <Box
          ref={toggleRef}
          sx={{ cursor: "help", display: "flex" }}
          onClick={() => setOpen(true)}
          onMouseOver={() => setOpen(true)}
          onMouseOut={() => setOpen(false)}
        >
          {children}
        </Box>
      </ClickAwayListener>

      <Popper
        open={open}
        anchorEl={toggleRef.current}
        placement="top-start"
        disablePortal
      >
        <Paper
          elevation={3}
          sx={{ padding: "0.5rem", fontSize: "0.75rem", ...tooltipSx }}
          {...restTooltipProps}
        >
          {tooltipText}
        </Paper>
      </Popper>
    </>
  );
}

Tooltip.defaultProps = {
  tooltipProps: {},
};
