import Box from "@mui/material/Box";
import cx from "classnames";
import React from "react";

import Pile from "@@components/common/Pile";
import WeddingImage from "./WeddingImage";
import WeddingMediaOverlay from "../WeddingMediaOverlay/WeddingMediaOverlay";

export default function WeddingImagePile({
  images,
  className,
  sx,
  WeddingImageProps,
  ...restProps
}) {
  const [topImageIdx, setTopImageIdx] = React.useState(0);

  const {
    onClick,
    style: styleWeddingImage,
    ...restWeddingImageProps
  } = WeddingImageProps ?? {};
  return (
    <Box
      className={cx("wedding-image-pile", className)}
      sx={{ position: "relative", ...sx }}
      {...restProps}
    >
      <Pile
        elems={images}
        renderElem={(elem, { style, ...restProps }, ref) => (
          <WeddingImage
            ref={ref}
            key={elem.id}
            media={elem}
            style={{
              ...style,
              ...styleWeddingImage,
              maxWidth: "80%",
              maxHeight: "80%",
            }}
            onClick={() => onClick && onClick(elem.id)}
            {...restProps}
            {...restWeddingImageProps}
          />
        )}
        onTopElemChange={(topElemIdx) => setTopImageIdx(topElemIdx)}
      />

      <WeddingMediaOverlay
        media={images[topImageIdx]}
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          minHeight: "2rem",
          zIndex: 100, // should be above the topmost item in the pile
        }}
      />
    </Box>
  );
}
