import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import cx from "classnames";

import "animate.css";
import logo from "@@img/logo_GreyLight.svg";

const sizeConversionMap = {
  small: "4rem",
  medium: "6rem",
  large: "8rem",
};

export default function SplashContent({
  size,
  className,
  sx,
  loadingText,
  ImageProps,
  TextProps,
  ...restProps
}) {
  size = sizeConversionMap[size] ?? size;
  const { sx: imageSx, ...imageRestProps } = ImageProps;
  const { sx: textSx, ...textRestProps } = TextProps;

  return (
    <Box
      className={cx("splash-content", className)}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
        animationName: "flash",
        animationDuration: "3s",
        animationIterationCount: "infinite",
        ...sx,
      }}
      {...restProps}
    >
      <Box
        component="img"
        src={logo}
        sx={{
          width: size,
          height: size,
          opacity: 0.8,
          ...imageSx,
        }}
        {...imageRestProps}
      />
      <Typography
        sx={{
          color: "grey.light",
          fontWeight: "light",
          textAlign: "center",
          ...textSx,
        }}
        {...textRestProps}
      >
        {loadingText}
      </Typography>
    </Box>
  );
}

SplashContent.defaultProps = {
  size: "large",
  loadingText: "Loading",
  ImageProps: {},
  TextProps: {},
};
