import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import FaceRetouchingNaturalIcon from "@mui/icons-material/FaceRetouchingNatural";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FolderIcon from "@mui/icons-material/Folder";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import MovieIcon from "@mui/icons-material/Movie";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PhotoAlbumIcon from "@mui/icons-material/PhotoAlbum";
import SettingsIcon from "@mui/icons-material/Settings";

// we prefer the rounded version because the regular storefront icon
//   looks exactly like the outlined version
import StorefrontIcon from "@mui/icons-material/StorefrontRounded";

import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { Redirect, useHistory, useRouteMatch } from "react-router-dom";
import { useSnackbar } from "notistack";

import AppBar from "@@components/common/AppBar/AppBar";
import { PRIVILEGED_WEDDING_ROLES, weddingPageTabs as tabs } from "@@config";
import {
  CurrentWeddingContext,
  SetCurrentWeddingContext,
  CurrentWeddingMediaUploadInputRefContext,
  ChangeWeddingCoverPhotoInputRefContext,
} from "@@contexts/CurrentWeddingContexts";
import { LoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";
import { WeddingPageModalsStateSettersContext } from "@@contexts/WeddingPageLevelModalContexts";
import { triggerWeddingClientOnboarding } from "@@services/fotobot-api.service";
import { prepareWeddingMediaDownloadLink } from "@@services/wedding-media.service";
import { capitalizeFirstLetter } from "@@utils/strUtils";

import WeddingHeader from "./WeddingHeader";
import WeddingName from "./WeddingName";
import WeddingPageActions from "./WeddingPageActions";
import WeddingPageBottomNavigationBar from "./WeddingPageBottomNavigationBar";

export default function WeddingPageShell() {
  const routeMatch = useRouteMatch("/weddings/:weddingId/:tabName?");

  const wedding = React.useContext(CurrentWeddingContext);
  const setWedding = React.useContext(SetCurrentWeddingContext);
  const loggedInUser = React.useContext(LoggedInUserContext);
  const mediaUploadInputRef = React.useContext(
    CurrentWeddingMediaUploadInputRefContext
  );
  const changeWeddingCoverPhotoInputRef = React.useContext(
    ChangeWeddingCoverPhotoInputRefContext
  );
  const {
    setWeddingSettingsPaneOpen,
    setAddMediaModalOpen,
    setAddGuestsModalOpen,
    setAddVendorModalOpen,
    setMediaFiltersDrawerOpen,
  } = React.useContext(WeddingPageModalsStateSettersContext);
  const history = useHistory();
  const theme = useTheme();
  const viewportIsMdPlus = useMediaQuery(theme.breakpoints.up("md"));
  const { enqueueSnackbar } = useSnackbar();

  const currentTab = tabs.find((t) => t.name === routeMatch.params.tabName);
  if (!currentTab) {
    return (
      <Redirect
        to={`/weddings/${routeMatch.params.weddingId}/${tabs[0].name}`}
      />
    );
  }

  const curUserIsHost = PRIVILEGED_WEDDING_ROLES.includes(wedding.role);
  const mediaUploadIsAllowed =
    wedding.anonymousUploadsAreAllowed || wedding.role;
  const weddingZipStatus = wedding.weddingZip?.status;

  const actions = {
    addPhotosAction: {
      name: "add-photos",
      icon: <AddAPhotoIcon />,
      label: `Add photos`,
      onClick: handleAddMediaActionButtonClick,
    },
    addVideosAction: {
      name: "add-videos",
      icon: <MovieIcon />,
      label: `Add videos`,
      onClick: handleAddMediaActionButtonClick,
    },
    addGuestsAction: {
      name: "add-people",
      icon: <PersonAddIcon />,
      label: "Add guests",
      onClick: () => setAddGuestsModalOpen(true),
    },
    addVendorsAction: {
      name: "add-vendors",
      icon: <StorefrontIcon />,
      label: "Add vendors",
      onClick: () => {
        if (!curUserIsHost) {
          enqueueSnackbar(`Sorry, only hosts can add vendors`, {
            variant: "info",
          });
          return;
        }

        setAddVendorModalOpen(true);
      },
    },
    filterMediaAction: {
      name: "filter-photos",
      icon: (
        <Badge
          className="applied-media-filters-badge"
          invisible={true}
          variant="dot"
          color="primary"
          sx={{
            "& .MuiBadge-badge.hollow": {
              border: `1px solid ${theme.palette.primary.main}`,
              backgroundColor: "transparent",
            },
          }}
        >
          <FilterAltIcon />
        </Badge>
      ),
      label: "Filter photos",
      onClick: () => setMediaFiltersDrawerOpen(true),
    },
    downloadMediaAction: {
      name:
        weddingZipStatus === "READY"
          ? "download-ready"
          : weddingZipStatus === "CREATION_IN_PROGRESS"
          ? "download-creation-in-progress"
          : "download-media",
      icon:
        weddingZipStatus === "CREATION_IN_PROGRESS" ? (
          <HourglassBottomIcon />
        ) : (
          <CloudDownloadIcon />
        ),
      label:
        weddingZipStatus === "READY"
          ? "Download ready"
          : weddingZipStatus === "CREATION_IN_PROGRESS"
          ? "Preparing download ..."
          : "Download gallery",
      onClick: () => {
        if (weddingZipStatus === "READY") {
          window.open(wedding.weddingZip.url, "_blank");
          return;
        }

        if (weddingZipStatus === "CREATION_IN_PROGRESS") {
          enqueueSnackbar("Please wait while we prepare your download", {
            variant: "info",
          });
          return;
        }

        if (!loggedInUser) {
          enqueueSnackbar("You must log in to request a download link", {
            variant: "info",
          });
          return;
        }

        setWedding((w) => ({
          ...w,
          weddingZip: { status: "CREATION_IN_PROGRESS" },
        }));

        try {
          wrap(prepareWeddingMediaDownloadLink, wedding.id)();
        } catch (e) {
          setWedding((w) => ({ ...w, weddingZip: undefined }));
        }
      },
    },
    toggleFoldersViewAction: {
      name: "folders-toggle",
      icon: <FolderIcon />,
      label: "Toggle folder view",
      onClick: () =>
        setWedding((w) => ({
          ...w,
          __userPrefs: { showFolders: !w.__userPrefs.showFolders },
        })),
    },
    toggleWeddingSettingsPaneAction: {
      name: "toggle-wedding-settings-pane",
      icon: <SettingsIcon />,
      label: "Settings",
      onClick: () => setWeddingSettingsPaneOpen((x) => !x),
    },
    triggerClientOnboardingAction: {
      name: "trigger-client-onboarding",
      icon: <FaceRetouchingNaturalIcon />,
      label: "Trigger client onboarding",
      onClick: wrap(triggerWeddingClientOnboarding, wedding.id),
    },
    changeWeddingCoverPhotoAction: {
      name: "change-wedding-cover-photo",
      icon: <PhotoAlbumIcon />,
      label: `${wedding.coverPhotoUrl ? "Change" : "Set"} cover photo`,
      onClick: () => changeWeddingCoverPhotoInputRef.current.click(),
    },
  };

  if (viewportIsMdPlus) {
    return (
      <>
        <AppBar
          actions={tabs.map(({ name, icon, label }) => ({
            icon,
            label: label ?? capitalizeFirstLetter(name),
            onClick: () => history.push(`/weddings/${wedding.slug}/${name}`),
            className: name === currentTab.name ? "selected" : "",
          }))}
          sx={{
            "& .MuiMenuItem-root.selected": {
              backgroundColor: "primary.light",
              color: "grey.extradark",
              fontWeight: 600,
            },
          }}
        />

        <Box
          sx={{
            // extra marginBottom makes wedding page content line-up
            //   nicely with divider below logo in app bar
            margin: "1rem 1rem 1.75rem 1rem",
            height: "3rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* without relative positioning, wedding name appears misaligned
                with buttons  */}
          <WeddingName
            sx={{ position: "relative", top: "2px", textAlign: "left" }}
          >
            {wedding.name}
          </WeddingName>

          <WeddingPageActions
            availableActions={actions}
            currentTab={currentTab}
            curUserIsHost={curUserIsHost}
            folderViewIsOn={wedding.__userPrefs.showFolders}
            sx={{ padding: 0 }}
          />
        </Box>
      </>
    );
  } else {
    return (
      <>
        <AppBar sx={{ position: "absolute" }} />

        <WeddingHeader wedding={wedding} />

        <WeddingPageActions
          availableActions={actions}
          currentTab={currentTab}
          curUserIsHost={curUserIsHost}
          folderViewIsOn={wedding.__userPrefs.showFolders}
          sx={{ marginBottom: "1rem" }}
        />

        <WeddingPageBottomNavigationBar />
      </>
    );
  }

  function wrap(fn, ...args) {
    return async () => {
      try {
        const { message } = await fn(...args);
        enqueueSnackbar(message, { variant: "info" });
      } catch (e) {
        enqueueSnackbar(`Error: ${e.response?.data?.error ?? e.message}`, {
          variant: "error",
        });
      }
    };
  }

  function handleAddMediaActionButtonClick() {
    if (mediaUploadIsAllowed) {
      if (viewportIsMdPlus && !localStorage.getItem("hideAddMediaHints")) {
        setAddMediaModalOpen(true);
      } else {
        mediaUploadInputRef.current.click();
      }
    } else {
      enqueueSnackbar(`You must log in to upload ${currentTab.name}`, {
        variant: "info",
      });
    }
  }
}
