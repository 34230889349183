import React from "react";

import PaneHeading from "@@components/common/Headings/PaneHeading";
import Drawer from "@@components/common/Drawer";
import {
  WeddingPageSettingsPaneOpenContext,
  WeddingPageModalsStateSettersContext,
} from "@@contexts/WeddingPageLevelModalContexts";
import WeddingSettingsForm from "./WeddingSettingsForm";

export default function WeddingSettingsModal({ wedding, setWedding }) {
  const open = React.useContext(WeddingPageSettingsPaneOpenContext);
  const { setWeddingSettingsPaneOpen } = React.useContext(
    WeddingPageModalsStateSettersContext
  );

  return (
    <Drawer
      className="wedding-settings-pane"
      open={open}
      onClose={() => setWeddingSettingsPaneOpen(false)}
    >
      <PaneHeading>Settings</PaneHeading>
      <WeddingSettingsForm wedding={wedding} setWedding={setWedding} />
    </Drawer>
  );
}
