import Box from "@mui/material/Box";
import React from "react";

import InfoTypography from "@@components/common/InfoTypography";
import SplashContent from "@@components/common/SplashContent";
import { PRIVILEGED_WEDDING_ROLES } from "@@config";
import { LoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";
import { CurrentWeddingContext } from "@@contexts/CurrentWeddingContexts";
import { CurrentWeddingFoldersContext } from "@@contexts/CurrentWeddingFoldersContexts";
import WeddingMediaDispatchContext from "@@contexts/WeddingMediaDispatchContext";

import {
  WeddingMediaAppliedFiltersContext,
  WeddingMediaAppliedFiltersDispatchContext,
  WeddingMediaSelectedFiltersContext,
  WeddingMediaSelectedFiltersDispatchContext,
  WeddingMediaSavedFiltersContext,
} from "@@contexts/WeddingMediaFiltersContexts";
import useWeddingMedia from "@@hooks/useWeddingMedia";
import useWeddingMediaFiltersFromUrl from "@@hooks/useWeddingMediaFiltersFromUrl";
import mediaFiltersReducer from "@@reducers/mediaFiltersReducer";
import { getSubfolders } from "@@utils";
import MediaFiltersDrawer from "../MediaFilters/MediaFiltersDrawer";
import SavedFilterPills from "../SavedFilterPills";
import WeddingFolders from "../WeddingFolders";
import WeddingGallery from "./WeddingGallery";
import NoMediaCTA from "../NoMediaCTA";
import NewMediaAvailableCTA from "../NewMediaAvailableCTA";

export default function WeddingPhotos({ onError }) {
  const loggedInUser = React.useContext(LoggedInUserContext);
  const wedding = React.useContext(CurrentWeddingContext);
  const weddingFolders = React.useContext(CurrentWeddingFoldersContext);
  const savedMediaFilters = React.useContext(WeddingMediaSavedFiltersContext);

  const filtersFromUrl = useWeddingMediaFiltersFromUrl();
  const {
    error: errorInUseWeddingMedia,
    media,
    mediaDispatch,
    mediaFilters: appliedMediaFilters,
    mediaFiltersDispatch: appliedMediaFiltersDispatch,
    fetchMoreMedia,
    thereIsNewMedia,
    thereIsMoreMedia,
    refreshMedia,
    fetching: loadingMedia,
  } = useWeddingMedia({
    loggedInUser,
    weddingId: wedding.id,
    initialFilters: filtersFromUrl,
    fetchAllSimilarMediaAtOnce: true,
  });
  const [selectedMediaFilters, selectedMediaFiltersDispatch] = React.useReducer(
    mediaFiltersReducer,
    appliedMediaFilters
  );

  const mediaFiltersDispatchHelper = React.useCallback(
    (action) => {
      appliedMediaFiltersDispatch(action);
      selectedMediaFiltersDispatch(action);
    },
    // don't need either to be in the dep list, but eslint is complaining
    [appliedMediaFiltersDispatch, selectedMediaFiltersDispatch]
  );
  const { showFolders } = wedding.__userPrefs;

  // when user navigates to a different folder, mediaFilters.mediaPath
  //   must be updated
  React.useEffect(() => {
    const desiredMediaPath = showFolders
      ? filtersFromUrl.mediaPath ?? ""
      : undefined;
    if (appliedMediaFilters.mediaPath !== desiredMediaPath) {
      mediaFiltersDispatchHelper({
        type: "UPDATE_FILTERS",
        data: { mediaPath: desiredMediaPath },
      });
    }
  }, [
    mediaFiltersDispatchHelper,
    appliedMediaFilters.mediaPath,
    showFolders,
    filtersFromUrl.mediaPath,
  ]);

  React.useEffect(() => {
    if (errorInUseWeddingMedia && onError) onError(errorInUseWeddingMedia);
  }, [errorInUseWeddingMedia, onError]);

  const subfolders = getSubfolders(filtersFromUrl.mediaPath, weddingFolders);
  const thereIsMedia = media.filter((m) => !m.__deleteMarker).length > 0;

  return (
    <WeddingMediaDispatchContext.Provider value={mediaDispatch}>
      <WeddingMediaAppliedFiltersContext.Provider value={appliedMediaFilters}>
        <WeddingMediaAppliedFiltersDispatchContext.Provider
          value={appliedMediaFiltersDispatch}
        >
          <WeddingMediaSelectedFiltersContext.Provider
            value={selectedMediaFilters}
          >
            <WeddingMediaSelectedFiltersDispatchContext.Provider
              value={selectedMediaFiltersDispatch}
            >
              <Box
                className="wedding-photos-container cover-parent"
                sx={{
                  padding: "0",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <SavedFilterPills
                  mediaType="photos"
                  savedFilters={savedMediaFilters.filter(
                    (sf) => sf.filters.mediaType === "IMAGE"
                  )}
                />

                {PRIVILEGED_WEDDING_ROLES.includes(wedding.role) ||
                wedding.revealMediaDone ? null : (
                  <InfoTypography sx={{ margin: "0 1.5rem" }}>
                    {loggedInUser
                      ? "You can currently only view media uploaded by you. Uploads by other guests will be revealed by the couple after the event."
                      : "All uploads are currently hidden. They will be visible once revealed by the couple after the event."}
                  </InfoTypography>
                )}

                {thereIsNewMedia ? (
                  <NewMediaAvailableCTA
                    mediaType="photos"
                    onShowButtonClick={refreshMedia}
                  />
                ) : null}

                {showFolders ? (
                  <WeddingFolders
                    folders={subfolders}
                    showEditControls={PRIVILEGED_WEDDING_ROLES.includes(
                      wedding.role
                    )}
                    sx={{ margin: "0rem 1rem" }}
                  />
                ) : null}

                {thereIsMedia ? (
                  <WeddingGallery
                    media={media}
                    mediaFilters={appliedMediaFilters}
                    pileSimilarPhotos={true}
                    retrieveAllRemainingMedia={fetchMoreMedia}
                  />
                ) : null}

                {!thereIsMedia &&
                !thereIsNewMedia &&
                !thereIsMoreMedia &&
                !loadingMedia &&
                (!showFolders || (showFolders && subfolders.length === 0)) ? (
                  <NoMediaCTA
                    mediaFilters={appliedMediaFilters}
                    sx={{ flexGrow: 1, padding: "1rem" }}
                  />
                ) : null}

                {loadingMedia || thereIsMoreMedia ? (
                  <SplashContent
                    className="flex-center-content"
                    sx={{
                      margin: "0 auto 1.5rem auto",
                      flexGrow: 1,
                      flexDirection: "column",
                      "& p": { display: "none" },
                    }}
                    size="medium"
                  />
                ) : null}
              </Box>

              {/* without keepMounted, any selected-but-unapplied filters
                    would be lost if MediaFiltersDrawer were closed */}
              <MediaFiltersDrawer MediaFiltersProps={{ wedding }} keepMounted />
            </WeddingMediaSelectedFiltersDispatchContext.Provider>
          </WeddingMediaSelectedFiltersContext.Provider>
        </WeddingMediaAppliedFiltersDispatchContext.Provider>
      </WeddingMediaAppliedFiltersContext.Provider>
    </WeddingMediaDispatchContext.Provider>
  );
}
