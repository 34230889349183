import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";

import AppTopbar from "./AppTopbar";
import AppSidebar from "./AppSidebar";

export default function AppBar(props) {
  const theme = useTheme();
  const viewportIsMdPlus = useMediaQuery(theme.breakpoints.up("md"));

  return viewportIsMdPlus ? (
    <AppSidebar {...props} />
  ) : (
    <AppTopbar {...props} />
  );
}
