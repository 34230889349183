import { generateRandomString } from "@ack_inc/utils";
import _ from "lodash";

import { fotobotApiAxiosInstance as axiosInstance } from "@@utils/axiosInstances";
import { streamlineFiltersObject } from "@@utils/mediaFiltersUtils";

export async function getWeddingMedia(weddingId, params, axiosConfig = {}) {
  // there are two endpts that can be used to getWeddingMedia - a GET endpt,
  //   where fiters are specified in the querystring, and a POST endpt, where
  //   filters are specified in the body

  params = streamlineFiltersObject(params);
  let data;

  if (params.featuring || params.numPersons) {
    // the featuring and numPersons filters are specified as objects, which
    //   cannot be specified in the query string
    // when either is specified, we have to make a POST request to the
    //   alternative list-media endpoint instead of the usual GET endpoint
    ({ data } = await axiosInstance.post(
      `/weddings/${weddingId}/listMedia`,
      params,
      { ...axiosConfig }
    ));
  } else {
    ({ data } = await axiosInstance.get(`/weddings/${weddingId}/media`, {
      params,
      ...axiosConfig,
    }));
  }
  return data;
}

export async function checkForNewMedia(weddingId, params, axiosConfig) {
  params = streamlineFiltersObject(params);

  // see reasoning in getWeddingMedia implementation
  if (params.featuring || params.numPersons) {
    const { data } = await axiosInstance.post(
      `/weddings/${weddingId}/checkForNewMedia`,
      params,
      { ...axiosConfig }
    );
    return data.message === "NEW_MEDIA_AVAILABLE";
  } else {
    const { data } = await axiosInstance.get(
      `/weddings/${weddingId}/checkForNewMedia`,
      { params, ...axiosConfig }
    );
    return data.message === "NEW_MEDIA_AVAILABLE";
  }
}

export async function addWeddingMedia(
  weddingId,
  files,
  { parentFolderId, parentFolderPathBase64 } = {},
  uploadStatsDispatch
) {
  if (files instanceof FileList) files = Array.from(files);

  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append(
      `weddingMedia_${i}`,
      files[i],
      btoa(
        JSON.stringify({
          name:
            files[i].__meta?.fullPath ||
            files[i].webkitRelativePath ||
            files[i].name,
          size: files[i].size,
        })
      )
    );
  }

  const uploadOperationId = generateRandomString(10, { lowercase: true });
  const totalBytes = files.reduce((acc, f) => acc + f.size, 0);
  uploadStatsDispatch({
    type: "SENDING_FILES",
    weddingId,
    data: {
      nFiles: files.length,
      totalBytes,
      uploadOperationId,
    },
  });
  try {
    await axiosInstance.post(`/weddings/${weddingId}/uploadMedia`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      params: _.pickBy({ parentFolderId, parentFolderPathBase64 }),
      onUploadProgress: (e) =>
        uploadStatsDispatch({
          type: "SENDING_FILES_PROGRESS",
          weddingId,
          data: {
            uploadOperationId,
            sentBytes: e.loaded,
            totalBytes: e.total,
          },
        }),
    });
  } finally {
    uploadStatsDispatch({
      type: "FINISHED_SENDING_FILES",
      weddingId,
      data: { uploadOperationId },
    });
  }
}

export async function deleteWeddingMedia(weddingId, mediaId) {
  const { data } = await axiosInstance.delete(
    `/weddings/${weddingId}/media/${mediaId}`
  );

  return data;
}

export async function updateWeddingMedia(weddingId, mediaId, payload) {
  const { data } = await axiosInstance.patch(
    `/weddings/${weddingId}/media/${mediaId}`,
    payload
  );

  return data;
}

export async function prepareWeddingMediaDownloadLink(weddingId) {
  const { data } = await axiosInstance.post(
    `/weddings/${weddingId}/downloadMedia`
  );
  return data;
}

export async function getWeddingMediaUploadStats(
  userId = "me",
  { weddingIds } = {}
) {
  const { data: respBody } = await axiosInstance.get(
    `/users/${userId}/mediaUploadStats`,
    { params: _.pickBy({ weddingIds }) }
  );
  Object.values(respBody).forEach(enhanceStats);
  return respBody;
}

export async function resetWeddingMediaUploadStats(
  userId = "me",
  { weddingIds } = {}
) {
  const { data } = await axiosInstance.delete(
    `/users/${userId}/mediaUploadStats`,
    { params: _.pickBy({ weddingIds }) }
  );
  return data;
}

function enhanceStats(stats) {
  stats.nErrors = Object.entries(stats)
    .filter(([key]) => key.startsWith("error") && !key.endsWith("__files"))
    .reduce((acc, [, val]) => acc + val, 0);
  stats.nSuccess = stats.success ?? 0;
  stats.nProcessed = stats.nErrors + stats.nSuccess;
  return stats;
}

export async function updateFaceLabel(weddingId, mediaId, payload) {
  const { data } = await axiosInstance.post(
    `/weddings/${weddingId}/media/${mediaId}/updateFaceLabel`,
    payload
  );
  return data;
}

export async function saveMediaFilters(weddingId, name, filters) {
  const { data } = await axiosInstance.post(
    `/weddings/${weddingId}/savedMediaFilters`,
    { name, appliedMediaFilters: streamlineFiltersObject(filters) }
  );
  return data;
}

export async function getSavedMediaFilters(weddingId, sharedFilterUuids) {
  const { data } = await axiosInstance.get(
    `/weddings/${weddingId}/savedMediaFilters`,
    { params: { sharedFilterUuids } }
  );
  return data;
}

export async function getSavedMediaFilter(weddingId, filterUuid) {
  const { data } = await axiosInstance.get(
    `/weddings/${weddingId}/savedMediaFilters/${filterUuid}`
  );
  return data;
}
