export function snakeCaseToSentenceCase(str) {
  return capitalizeFirstLetter(str.replace(/_(.)/g, (match, p1) => ` ${p1}`));
}

export function capitalizeFirstLetter(str) {
  return str.length === 0 ? "" : str[0].toUpperCase() + str.slice(1);
}

export function prettyPhoneNumber(phoneNumber) {
  return phoneNumber.replace(/^(.+?)(\d{5})(\d{5})$/, "$1 $2 $3");
}

export function findIndexesAtWhichSubstringFoundInString(str, substr) {
  const retval = [];

  if (str.length < substr.length) return retval;
  if (substr.length === 0) throw new Error("substr cannot be empty string");

  let startCheckFrom = 0;
  while (true) {
    const substrFoundAt = str.slice(startCheckFrom).indexOf(substr);
    if (substrFoundAt === -1) return retval;

    retval.push(startCheckFrom + substrFoundAt);

    // why `substrFoundAt + 1` instead of `substrFoundAt + substr.length`?
    // because we don't want to miss the second 'AA' in 'AAA' when this
    //   function is called as `find...String('AAA', 'AA')`
    startCheckFrom += substrFoundAt + 1;
  }
}
