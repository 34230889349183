import React from "react";

export const WeddingMediaAppliedFiltersContext = React.createContext({});
WeddingMediaAppliedFiltersContext.displayName =
  "WeddingMediaAppliedFiltersContext";

export const WeddingMediaAppliedFiltersDispatchContext = React.createContext(
  () => {}
);
WeddingMediaAppliedFiltersDispatchContext.displayName =
  "WeddingMediaAppliedFiltersDispatchContext";

export const WeddingMediaSelectedFiltersContext = React.createContext({});
WeddingMediaSelectedFiltersContext.displayName =
  "WeddingMediaSelectedFiltersContext";

export const WeddingMediaSelectedFiltersDispatchContext = React.createContext(
  () => {}
);
WeddingMediaSelectedFiltersDispatchContext.displayName =
  "WeddingMediaSelectedFiltersDispatchContext";

export const WeddingMediaSavedFiltersContext = React.createContext([]);
WeddingMediaSavedFiltersContext.displayName = "WeddingMediaSavedFiltersContext";

export const SetWeddingMediaSavedFiltersContext = React.createContext(() => {});
SetWeddingMediaSavedFiltersContext.displayName =
  "SetWeddingMediaSavedFiltersContext";
