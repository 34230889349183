import ConstructionIcon from "@mui/icons-material/Construction";
import DownloadIcon from "@mui/icons-material/Download";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import cx from "classnames";
import React from "react";

import Tooltip from "@@components/common/Tooltip";
import { LoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";
import { CurrentWeddingContext } from "@@contexts/CurrentWeddingContexts";
import { PRIVILEGED_WEDDING_ROLES } from "@@config";
import WeddingMediaAddedByStatement from "./WeddingMediaAddedByStatement";
import WeddingMediaDeleteButton from "./WeddingMediaDeleteButton";
import WeddingMediaPublicPrivateToggle from "./WeddingMediaPublicPrivateToggle";
import { prettyDate } from "@@utils/dateUtils";

export default function WeddingMediaOverlay({
  media,
  className,
  sx,
  ...restProps
}) {
  const theme = useTheme();
  const loggedInUser = React.useContext(LoggedInUserContext);
  const wedding = React.useContext(CurrentWeddingContext);

  return (
    <Box
      className={cx("wedding-media-overlay", className)}
      sx={{
        minHeight: "2rem",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        color: "white",
        padding: "0 0.5rem",
        display: "flex",
        gap: "0.5rem",
        alignItems: "center",
        ...sx,
      }}
      onClick={(e) => e.stopPropagation()}
      {...restProps}
    >
      <WeddingMediaPublicPrivateToggle
        className="wedding-media-control public-private-toggle-button"
        media={media}
        sx={{
          display: PRIVILEGED_WEDDING_ROLES.includes(wedding.role)
            ? "flex"
            : "none",
        }}
      />

      <Box
        className="wedding-media-control download-button"
        component="a"
        href={`${media.url}?response-content-disposition=attachment`}
        target="_blank"
        download // ignored for cross-origin links
        sx={{ position: "relative", top: "1px", color: "primary.light" }}
      >
        <IconButton disableRipple size="small" sx={{ color: "inherit" }}>
          <DownloadIcon />
        </IconButton>
      </Box>

      {!media.otherData.faceIndexingDone ? (
        <Tooltip
          tooltipText="Facial recognition for this photo is in progress ..."
          tooltipProps={{ sx: { width: "10rem" } }}
        >
          <ConstructionIcon
            sx={{ color: theme.palette.grey.extralight, marginLeft: "0.25rem" }}
          />
        </Tooltip>
      ) : null}

      <WeddingMediaAddedByStatement
        className="wedding-media-metadata added-by-statement"
        media={media}
        sx={{ flexShrink: 0, marginLeft: "auto" }}
      />

      <Typography
        variant="caption"
        className="wedding-media-metadata added-date"
      >
        {prettyDate(media.createdAt, new Date())}
      </Typography>

      <WeddingMediaDeleteButton
        className="wedding-media-control delete-button"
        media={media}
        disabled={
          !PRIVILEGED_WEDDING_ROLES.includes(wedding.role) &&
          media.uploaderId !== loggedInUser?.id
        }
        sx={{ display: loggedInUser ? "flex" : "none" }}
      />
    </Box>
  );
}
