import Button from "@mui/material/Button";
import cx from "classnames";
import React from "react";

function ReactionButton(
  {
    children,
    className,
    sx,
    onClick,
    onClickText,
    onClickProps,
    clickTimeoutSeconds,
    ...restProps
  },
  ref
) {
  const [clicked, setClicked] = React.useState(false);
  const clickTimeoutRef = React.useRef(null);

  React.useEffect(() => {
    if (clicked) {
      clearTimeout(clickTimeoutRef.current);

      clickTimeoutRef.current = setTimeout(
        () => setClicked(false),
        clickTimeoutSeconds * 1000
      );
    }

    return () => clearTimeout(clickTimeoutRef.current);
  }, [clicked, clickTimeoutSeconds]);

  return (
    <Button
      ref={ref}
      className={cx("reaction-button", className)}
      onClick={() => {
        setClicked(true);
        onClick();
      }}
      children={children}
      sx={sx}
      {...restProps}
      {...(clicked ? onClickProps : {})}
    />
  );
}

export default React.forwardRef(ReactionButton);
