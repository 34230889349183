import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

export default function PublicPrivateFilter({
  mediaFilters,
  mediaFiltersDispatch,
}) {
  const word =
    mediaFilters.mediaType === "IMAGE"
      ? "photos"
      : mediaFilters.mediaType === "VIDEO"
      ? "videos"
      : "media";

  return (
    <TextField
      select
      label="Public/Private"
      defaultValue="all"
      value={
        mediaFilters.isPublic === undefined
          ? "all"
          : mediaFilters.isPublic === true
          ? "publicOnly"
          : mediaFilters.isPublic === false
          ? "privateOnly"
          : undefined
      }
      fullWidth
      size="small"
      variant="standard"
      onChange={(e) =>
        mediaFiltersDispatch({
          type: "UPDATE_FILTERS",
          data: {
            isPublic:
              e.target.value === "publicOnly"
                ? true
                : e.target.value === "privateOnly"
                ? false
                : /* e.target.value === 'all' */ undefined,
          },
        })
      }
      sx={{ "& p": { color: "grey.main" } }}
    >
      <MenuItem value={"all"} disableRipple>
        <Typography>Show all {word}</Typography>
      </MenuItem>

      <MenuItem value={"publicOnly"} disableRipple>
        <Typography>Show only public {word}</Typography>
      </MenuItem>

      <MenuItem value={"privateOnly"} disableRipple>
        <Typography>Show only private {word}</Typography>
      </MenuItem>
    </TextField>
  );
}
