import cx from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";

import ChangeableAvatar from "./ChangeableAvatar";
import {
  LoggedInUserContext,
  SetLoggedInUserContext,
} from "@@contexts/LoggedInUserContextsWrapper";
import { updateUserPic } from "@@services/fotobot-api.service";
import { getUserFullName } from "@@utils";

export default function LoggedInUserChangeableAvatar({
  picType,
  sx,
  ...restProps
}) {
  const loggedInUser = React.useContext(LoggedInUserContext);
  const setLoggedInUser = React.useContext(SetLoggedInUserContext);

  const { enqueueSnackbar } = useSnackbar();

  const attrName = `${picType}Url`;

  return (
    <ChangeableAvatar
      className={cx("logged-in-user-changeable-avatar", picType)}
      src={loggedInUser[attrName]}
      alt={getUserFullName(loggedInUser)}
      sx={{ height: "6rem", width: "6rem", ...sx }}
      onChange={async (file) => {
        try {
          const { data: user } = await updateUserPic(
            loggedInUser.id,
            file,
            picType
          );
          setLoggedInUser({ ...loggedInUser, [attrName]: user[attrName] });
        } catch (e) {
          enqueueSnackbar(
            e.response?.status < 500
              ? e.response.data.error
              : `There was a problem updating your ${
                  picType === "selfiePic" ? "selfie" : "profile picture"
                }. Please try again.`,
            { variant: "error" }
          );
          throw e;
        }
      }}
      {...(picType === "selfiePic" ? { InputProps: { capture: "user" } } : {})}
      {...restProps}
    />
  );
}

LoggedInUserChangeableAvatar.propTypes = {
  picType: PropTypes.oneOf(["selfiePic", "profilePic"]).isRequired,
};
