import MuiBottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";

function BottomNavigation({ actions, renderAction, PaperProps, ...restProps }) {
  const theme = useTheme();

  return (
    <Paper
      className="fixed-to-bottom bottom-navigation-bar"
      sx={{
        // without this, the contents of the weddings list page and wedding page render
        //   above the bottom navigation bar
        zIndex: 1,
        [theme.breakpoints.up("md")]: { display: "none" },
      }}
      elevation={6}
      {...PaperProps}
    >
      <MuiBottomNavigation
        showLabels
        sx={{
          "& .MuiBottomNavigationAction-root": {
            gap: "0.25rem",
          },
          "& .MuiSvgIcon-root": {
            // minor positioning adjustment to make navigationActions
            //   look centered
            position: "relative",
            top: "2px",
            fontSize: "1.4rem !important",
          },
          "& .MuiBottomNavigationAction-label": {
            // the !important below ensures the font-size doesn't
            //   get larger for the selected action
            fontSize: "0.75rem !important",
            textTransform: "capitalize",
          },
        }}
        {...restProps}
      >
        {actions.map(renderAction)}
      </MuiBottomNavigation>
    </Paper>
  );
}
BottomNavigation.defaultProps = {
  renderAction: (props) => (
    <BottomNavigationAction key={props.key ?? props.label} {...props} />
  ),
};

export default BottomNavigation;
