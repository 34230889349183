import React from "react";

export const CurrentWeddingContext = React.createContext(null);
CurrentWeddingContext.displayName = "CurrentWeddingContext";

export const SetCurrentWeddingContext = React.createContext(() => {});
SetCurrentWeddingContext.displayName = "SetCurrentWeddingContext";

export const CurrentWeddingMediaUploadStateContext = React.createContext(null);
CurrentWeddingMediaUploadStateContext.displayName =
  "CurrentWeddingMediaUploadStateContext";

export const SetCurrentWeddingMediaUploadStateContext = React.createContext(
  () => {}
);
SetCurrentWeddingMediaUploadStateContext.displayName =
  "SetCurrentWeddingMediaUploadStateContext";

export const CurrentWeddingMediaUploadInputRefContext =
  React.createContext(null);
CurrentWeddingMediaUploadInputRefContext.displayName =
  "CurrentWeddingMediaUploadInputRefContext";

export const ChangeWeddingCoverPhotoInputRefContext = React.createContext(null);
ChangeWeddingCoverPhotoInputRefContext.displayName =
  "ChangeWeddingCoverPhotoInputRefContext";
