import CheckIcon from "@mui/icons-material/Check";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";
import React from "react";

import { extractFaceThumbnailAsDataUrl } from "@@utils/webApiUtils";

const blankPixelImg = document.createElement("img");
blankPixelImg.src =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

// We want to show the user feedback (in the form of a bounding
//   box that shows them the drag-area) as a user does the drag
//   operation to select a face in the image
// However, there's a browser bug (?) that causes dragend to be fired
//   immediately after dragstart if the dragstart event handler
//   does DOM manipulation (for example: adding the bounding box showing
//   the drag area)
// To avoid this, we avoid all DOM updates inside the event handler(s)
//   for dragstart (and other drag events), and instead set up an
//   interval in a React Effect that redraws the bounding box every 10ms
// https://stackoverflow.com/q/14203734/3112241
export default function ImageForFaceSelection({ src, onSelect }) {
  const imgRef = React.useRef(null);
  const faceCoordinatesRef = React.useRef({
    top: null,
    left: null,
    width: null,
    height: null,
  });

  const [faceCoordinates, setFaceCoordinates] = React.useState(null);
  const [dragOperationStatus, setDragOperationStatus] =
    React.useState("notStarted");

  React.useEffect(() => {
    const updateFaceBoundingBoxInterval = setInterval(() => {
      if (dragOperationStatus === "notStarted") {
        setFaceCoordinates(null);
      } else {
        setFaceCoordinates(faceCoordinatesRef.current);
      }
    }, 10);

    return () => clearInterval(updateFaceBoundingBoxInterval);
  }, [dragOperationStatus]);

  return (
    <>
      <Box
        component="img"
        ref={imgRef}
        className="abs-centered"
        src={src}
        sx={{ maxWidth: "100vw", maxHeight: "100vh", zIndex: 1 }}
        onClick={(e) => {
          e.stopPropagation();
          setDragOperationStatus("notStarted");
        }}
        onDragStart={(e) => {
          // prevents incongruent pointer icon from being shown
          //   as user is dragging
          e.dataTransfer.effectAllowed = "none";
          e.dataTransfer.setDragImage(blankPixelImg, 0, 0);

          faceCoordinatesRef.current = {
            top: e.clientY,
            left: e.clientX,
            width: 0,
            height: 0,
          };
          setDragOperationStatus("started");
        }}
        onDrag={(e) => {
          updateFaceCoordinates(e);
        }}
        onDragEnd={(e) => {
          updateFaceCoordinates(e);
          setDragOperationStatus("finished");
        }}
        // because drag events don't fire on mobile
        onTouchStart={(e) => {
          if (e.touches.length > 1) {
            // don't react if user is using multiple
            //   fingers
            return;
          }

          const touch = e.touches.item(0);
          faceCoordinatesRef.current = {
            top: touch.clientY,
            left: touch.clientX,
            width: 0,
            height: 0,
          };
          setDragOperationStatus("started");
        }}
        onTouchMove={(e) => {
          const touch = e.targetTouches.item(0);
          updateFaceCoordinates(touch);
        }}
        onTouchEnd={(e) => {
          const touch = e.changedTouches.item(0);
          updateFaceCoordinates(touch);

          if (
            Math.max(
              Math.abs(faceCoordinatesRef.current.width),
              Math.abs(faceCoordinatesRef.current.height)
            ) < 5
          ) {
            // allows user to remove visible bounding boxes by
            //   tapping the screen
            setDragOperationStatus("notStarted");
          } else {
            setDragOperationStatus("finished");
          }
        }}
        onTouchCancel={(e) => {
          setDragOperationStatus("notStarted");
        }}
      />

      {["started", "finished"].includes(dragOperationStatus) &&
      faceCoordinates ? (
        <FaceBoundingRect
          coordinates={normalizeCoordinates(faceCoordinates)}
          operationStatus={dragOperationStatus}
          onSelect={handleFaceSelect}
        />
      ) : null}
    </>
  );

  function updateFaceCoordinates(touchItemOrDragEvent) {
    const e = touchItemOrDragEvent;

    // WARNING
    // There's also a weird bug (?) in firefox where the drag event
    //   always has clientX, clientY, ... etc. set to 0.
    // See https://stackoverflow.com/a/63365865/3112241
    //   and https://bugzilla.mozilla.org/show_bug.cgi?id=505521
    // Doesn't look like there's any interest from the maintainers
    //   to fix this

    // There's a weird bug in chrome where the last drag event fired
    //   before a dragend event carries incorrect data for all the position
    //   attrs
    if (
      e.type === "drag" &&
      !_.isEmpty(_.pickBy(faceCoordinatesRef.current)) &&
      Math.max(e.clientX, e.clientY, e.pageX, e.pageY, e.screenX, e.screenY) ===
        0
    ) {
      return;
    }

    // ensure bounding box coords are within the bounds of the image
    const imgBCR = imgRef.current.getBoundingClientRect();

    const cur = faceCoordinatesRef.current;
    faceCoordinatesRef.current = {
      ...cur,
      width: _.clamp(e.clientX, imgBCR.left, imgBCR.right) - cur.left,
      height: _.clamp(e.clientY, imgBCR.top, imgBCR.bottom) - cur.top,
    };
  }

  function handleFaceSelect(coordinates) {
    const scalingFactor = imgRef.current.naturalWidth / imgRef.current.width;

    const imgBCR = imgRef.current.getBoundingClientRect();

    const positionRelativeToTopLeftOfImage = {
      left: (+coordinates.left.replace("px", "") - imgBCR.left) * scalingFactor,
      top: (+coordinates.top.replace("px", "") - imgBCR.top) * scalingFactor,
      width: +coordinates.width.replace("px", "") * scalingFactor,
      height: +coordinates.height.replace("px", "") * scalingFactor,
    };

    const dataUrl = extractFaceThumbnailAsDataUrl(
      imgRef.current,
      positionRelativeToTopLeftOfImage
    );

    onSelect({ faceThumbUrl: dataUrl, tmpId: String(+new Date()) });
  }
}

function FaceBoundingRect({ operationStatus, coordinates, onSelect }) {
  const theme = useTheme();

  return (
    <Box
      className="face-bounding-rect-container"
      sx={{
        position: "absolute",
        top: coordinates.top,
        left: coordinates.left,
        zIndex: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "0.5rem",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Box
        className="face-bounding-rect"
        sx={{
          width: coordinates.width,
          height: coordinates.height,
          border: `1px ${operationStatus === "finished" ? "solid" : "dashed"} ${
            theme.palette.grey.extralight
          }`,
        }}
      />

      {operationStatus === "finished" ? (
        <IconButton
          size="medium"
          sx={{
            alignSelf: "center",
            backgroundColor: `${theme.palette.grey.extradark}66`,
            color: "grey.extralight",
          }}
          onClick={() => onSelect(coordinates)}
        >
          <CheckIcon fontSize="inherit" />
        </IconButton>
      ) : null}
    </Box>
  );
}

// adjusts for negative width/height, and adds units
function normalizeCoordinates(coords, unit = "px") {
  return {
    top: `${coords.top + Math.min(0, coords.height)}${unit}`,
    left: `${coords.left + Math.min(0, coords.width)}${unit}`,
    width: `${Math.abs(coords.width)}${unit}`,
    height: `${Math.abs(coords.height)}${unit}`,
  };
}
