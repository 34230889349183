import Box from "@mui/material/Box";
import React from "react";

import CloseButton from "@@components/common/Buttons/CloseButton";
import InfoTypography from "@@components/common/InfoTypography";
import SplashContent from "@@components/common/SplashContent";
import { PRIVILEGED_WEDDING_ROLES } from "@@config";
import { CurrentWeddingContext } from "@@contexts/CurrentWeddingContexts";
import { CurrentWeddingFoldersContext } from "@@contexts/CurrentWeddingFoldersContexts";
import {
  WeddingMediaAppliedFiltersContext,
  WeddingMediaAppliedFiltersDispatchContext,
  WeddingMediaSelectedFiltersContext,
  WeddingMediaSelectedFiltersDispatchContext,
  WeddingMediaSavedFiltersContext,
} from "@@contexts/WeddingMediaFiltersContexts";
import { LoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";
import WeddingMediaDispatchContext from "@@contexts/WeddingMediaDispatchContext";
import useWeddingMedia from "@@hooks/useWeddingMedia";
import useWeddingMediaFiltersFromUrl from "@@hooks/useWeddingMediaFiltersFromUrl";
import mediaFiltersReducer from "@@reducers/mediaFiltersReducer";
import { getSubfolders } from "@@utils";
import WeddingFolders from "../WeddingFolders";
import WeddingVideoCard from "./WeddingVideoCard";
import SavedFilterPills from "../SavedFilterPills";
import NoMediaCTA from "../NoMediaCTA";
import NewMediaAvailableCTA from "../NewMediaAvailableCTA";
import WeddingVideoPlayerContainer from "./WeddingVideoPlayerContainer";

export default function WeddingVideos({ onError }) {
  const wedding = React.useContext(CurrentWeddingContext);
  const weddingFolders = React.useContext(CurrentWeddingFoldersContext);
  const savedMediaFilters = React.useContext(WeddingMediaSavedFiltersContext);
  const loggedInUser = React.useContext(LoggedInUserContext);

  const filtersFromUrl = useWeddingMediaFiltersFromUrl();
  const {
    error: errorInUseWeddingMedia,
    media,
    mediaDispatch,
    mediaFilters: appliedMediaFilters,
    mediaFiltersDispatch: appliedMediaFiltersDispatch,
    thereIsMoreMedia,
    thereIsNewMedia,
    refreshMedia,
    fetching: loadingMedia,
  } = useWeddingMedia({
    loggedInUser,
    weddingId: wedding.id,
    initialFilters: filtersFromUrl,
  });
  const [selectedMediaFilters, selectedMediaFiltersDispatch] = React.useReducer(
    mediaFiltersReducer,
    appliedMediaFilters
  );

  const { showFolders } = wedding.__userPrefs;

  const [currentlySelectedVideoId, setCurrentlySelectedVideoId] =
    React.useState(null);

  // when user navigates to a different folder, mediaFilters.mediaPath
  //   must be updated
  React.useEffect(() => {
    const desiredMediaPath = showFolders ? filtersFromUrl.mediaPath : undefined;
    if (appliedMediaFilters.mediaPath !== desiredMediaPath) {
      appliedMediaFiltersDispatch({
        type: "UPDATE_FILTERS",
        data: { mediaPath: desiredMediaPath },
      });
    }
  }, [
    appliedMediaFiltersDispatch,
    showFolders,
    filtersFromUrl.mediaPath,
    appliedMediaFilters.mediaPath,
  ]);

  React.useEffect(() => {
    if (errorInUseWeddingMedia && onError) onError(errorInUseWeddingMedia);
  }, [errorInUseWeddingMedia, onError]);

  // scroll so player is in-view when a video is selected
  const videoRef = React.useRef(null);
  React.useEffect(() => {
    if (currentlySelectedVideoId) {
      videoRef.current.scrollIntoView({
        behavior: "smooth",
        inline: "center",
      });
    }
  }, [currentlySelectedVideoId]);

  const subfolders = getSubfolders(
    appliedMediaFilters.mediaPath,
    weddingFolders
  );
  const thereIsMedia = media.filter((m) => !m.__deleteMarker).length > 0;

  return (
    <WeddingMediaDispatchContext.Provider value={mediaDispatch}>
      <WeddingMediaAppliedFiltersContext.Provider value={appliedMediaFilters}>
        <WeddingMediaAppliedFiltersDispatchContext.Provider
          value={appliedMediaFiltersDispatch}
        >
          <WeddingMediaSelectedFiltersContext.Provider
            value={selectedMediaFilters}
          >
            <WeddingMediaSelectedFiltersDispatchContext.Provider
              value={selectedMediaFiltersDispatch}
            >
              <Box
                className="wedding-videos-container cover-parent"
                sx={{
                  padding: "0",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <SavedFilterPills
                  mediaType="videos"
                  savedFilters={savedMediaFilters.filter(
                    (sf) => sf.filters.mediaType === "VIDEO"
                  )}
                />

                {PRIVILEGED_WEDDING_ROLES.includes(wedding.role) ||
                wedding.revealMediaDone ? null : (
                  <InfoTypography sx={{ margin: "0 1.5rem" }}>
                    {loggedInUser
                      ? "You can currently only view media uploaded by you. Uploads by other guests will be revealed by the couple after the event."
                      : "All uploads are currently hidden. They will be visible once revealed by the couple after the event."}
                  </InfoTypography>
                )}

                {thereIsNewMedia ? (
                  <NewMediaAvailableCTA
                    mediaType="videos"
                    onShowButtonClick={refreshMedia}
                  />
                ) : null}

                {showFolders ? (
                  <WeddingFolders
                    folders={subfolders}
                    showEditControls={PRIVILEGED_WEDDING_ROLES.includes(
                      wedding.role
                    )}
                    sx={{ margin: "0rem 1rem" }}
                  />
                ) : null}

                {currentlySelectedVideoId ? (
                  <Box
                    className="floating-video-player-container-container"
                    // sx={{ position: "fixed", top: 0, width: "100%", zIndex: 2000 }}
                    sx={{
                      position: "relative",
                      margin: { xs: "2rem 0", sm: "2rem 1.5rem" },
                    }}
                  >
                    <WeddingVideoPlayerContainer
                      video={media.find(
                        (m) => m.id === currentlySelectedVideoId
                      )}
                      videoRef={videoRef}
                    />

                    <CloseButton
                      disableRipple
                      onClick={() => setCurrentlySelectedVideoId(null)}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: "grey.extralight",
                      }}
                    />
                  </Box>
                ) : null}

                {thereIsMedia ? (
                  <Box
                    className="wedding-videos"
                    sx={{
                      margin: { xs: "0", sm: "0 1.5rem" },
                      display: "grid",
                      gridTemplateColumns: {
                        xs: "1fr",
                        sm: "repeat(2, 1fr)",
                        md: "repeat(3, 1fr)",
                      },
                      justifyItems: "stretch",
                      gap: { xs: "1.5rem", md: "2rem 1.5rem" },
                    }}
                  >
                    {media
                      .filter((m) => !m.__deleteMarker)
                      .map((video) => (
                        <WeddingVideoCard
                          key={video.id}
                          video={video}
                          onClick={setCurrentlySelectedVideoId}
                          isSelected={currentlySelectedVideoId === video.id}
                        />
                      ))}
                  </Box>
                ) : null}

                {!thereIsMedia &&
                !thereIsNewMedia &&
                !thereIsMoreMedia &&
                !loadingMedia &&
                (!showFolders || (showFolders && subfolders.length === 0)) ? (
                  <NoMediaCTA
                    mediaFilters={appliedMediaFilters}
                    sx={{ flexGrow: 1, padding: "1rem" }}
                  />
                ) : null}

                {loadingMedia || thereIsMoreMedia ? (
                  <SplashContent
                    className="flex-center-content"
                    sx={{
                      margin: "0 auto 1.5rem auto",
                      flexGrow: 1,
                      flexDirection: "column",
                      "& p": { display: "none" },
                    }}
                    size="medium"
                  />
                ) : null}
              </Box>
            </WeddingMediaSelectedFiltersDispatchContext.Provider>
          </WeddingMediaSelectedFiltersContext.Provider>
        </WeddingMediaAppliedFiltersDispatchContext.Provider>
      </WeddingMediaAppliedFiltersContext.Provider>
    </WeddingMediaDispatchContext.Provider>
  );
}
