import React from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

export default function useWindowDimensions() {
  const [dims, setDims] = React.useState(getWindowDimensions());

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);

    function handleResize() {
      setDims(getWindowDimensions());
    }
  }, []);

  return dims;
}
