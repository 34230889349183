import _ from "lodash";
import { defaultWeddingMediaFilters as defaultFilters } from "@@config";

export default function mediaFiltersReducer(state, action) {
  const { type, data } = action;

  // any time the filters are changed, we want savedFilterUuid
  //   to be set to null; if a saved filter has indeed been applied,
  //   it has to be specified in the payload

  if (type === "RESET_FILTERS") {
    const { retainThese } = data;
    return { ...defaultFilters, ..._.pick(state, retainThese) };
  } else if (type === "SET_FILTERS") {
    return { ...defaultFilters, savedFilterUuid: null, ...data };
  } else if (type === "UPDATE_FILTERS") {
    return { ...state, savedFilterUuid: null, ...data };
  } else if (type === "CHANGE_TYPE_OF_FEATURING_FILTER") {
    if (!["anyOf", "allOf"].includes(data)) {
      throw new Error(`type must be 'anyOf' or 'allOf'; received '${data}'`);
    }
    return {
      ...state,
      savedFilterUuid: null,
      featuring: { type: data, data: state.featuring.data },
    };
  } else if (type === "ADD_TO_FEATURING_FILTER") {
    const toAdd = data;
    const field = toAdd.id
      ? "id"
      : toAdd.faceId
      ? "faceId"
      : toAdd.tmpId
      ? "tmpId"
      : null;
    if (!field) {
      throw new Error(`id, faceId, or tmpId must be present in payload`);
    }
    if (["faceId", "tmpId"].includes(field) && !toAdd.faceThumbUrl) {
      throw new Error(`faceThumbUrl must be present in payload`);
    }

    const alreadyPresent = state.featuring.data.find(
      (obj) => obj[field] === toAdd[field]
    );
    return {
      ...state,
      savedFilterUuid: null,
      featuring: alreadyPresent
        ? state.featuring
        : {
            type: state.featuring.type,
            data: state.featuring.data.concat(toAdd),
          },
    };
  } else if (type === "REMOVE_FROM_FEATURING_FILTER") {
    const toRemove = data;
    const field = toRemove.id
      ? "id"
      : toRemove.faceId
      ? "faceId"
      : toRemove.tmpId
      ? "tmpId"
      : null;
    if (!field) {
      throw new Error(`id, faceId, or tmpId must be present in payload`);
    }

    return {
      ...state,
      savedFilterUuid: null,
      featuring: {
        type: state.featuring.type,
        data: state.featuring.data.filter(
          (obj) => obj[field] !== toRemove[field]
        ),
      },
    };
  } else if (type === "TOGGLE_IN_FEATURING_FILTER") {
    const field = data.id
      ? "id"
      : data.faceId
      ? "faceId"
      : data.tmpId
      ? "tmpId"
      : null;
    if (!field) {
      throw new Error(`id, faceId, or tmpId must be present in payload`);
    }

    const alreadyPresent = state.featuring.data.find(
      (obj) => obj[field] === data[field]
    );

    return mediaFiltersReducer(state, {
      type: alreadyPresent
        ? "REMOVE_FROM_FEATURING_FILTER"
        : "ADD_TO_FEATURING_FILTER",
      data,
    });
  } else {
    throw new Error(`Invalid action type: ${type}`);
  }
}
