import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom";

import SentryContext from "@@contexts/SentryContext";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./fonts/YoungSerif-Regular.ttf";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_TARGET_ENV,
  integrations: [new BrowserTracing(), new Sentry.Replay()],

  // no need to send errors happening in dev to Sentry
  beforeSend: (event) =>
    process.env.REACT_APP_TARGET_ENV === "development" ? null : event,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate:
    process.env.REACT_APP_TARGET_ENV === "development" ? 0 : 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate:
    process.env.REACT_APP_TARGET_ENV === "development"
      ? 0
      : process.env.REACT_APP_TARGET_ENV === "staging"
      ? 1
      : 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <SentryContext.Provider value={Sentry}>
      <App />
    </SentryContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if ("serviceWorker" in window.navigator) {
  window.navigator.serviceWorker
    .register("/service-worker.js")
    .then(() => {
      console.log("Service worker registration succeeded");
    })
    .catch((e) => {
      console.error(
        `Service worker registration failed with error: ${e.message}`
      );
    });
}
