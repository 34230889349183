import { validate as validateEmail } from "email-validator";
import { phone } from "phone";

import { testPhoneNumbers } from "@@config";

export function isValidPhone(v) {
  if (!v.startsWith("+")) return "Please specify country code";

  const { isValid } = phone(v);
  return isValid || testPhoneNumbers.includes(v) || "Not a valid phone number";
}

export function isValidEmail(e) {
  return validateEmail(e) || "Not a valid email address";
}

export function isValidEmailOrPhone(v) {
  return /^(\d|\s|\+)+$/.test(v) ? isValidPhone(v) : isValidEmail(v);
}
