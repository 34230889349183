import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { useForm } from "react-hook-form";

import { updateUser } from "@@services/fotobot-api.service";
import { getUserFullName } from "@@utils";
import { isValidPhone } from "@@utils/inputValidationUtils";

export default function UserProfileForm({
  user,
  onUserUpdated,
  submitButtonText,
  sx,
  ...restProps
}) {
  const [state, setState] = React.useState({
    loading: false,
    message: "",
    error: "",
  });
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    defaultValues: {
      phoneNumber: user.phoneNumber,
      name: getUserFullName(user),
    },
  });

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onProfileFormSubmit)}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "1.5rem",
        ...sx,
      }}
      {...restProps}
    >
      <TextField
        className="name-field"
        autoComplete="off"
        name="name"
        label="Name *"
        fullWidth
        error={!!errors.name}
        helperText={errors.name?.message ?? ""}
        sx={{ margin: 0 }}
        {...register("name", {
          required: { value: true, message: "This field is required." },
        })}
      />

      <TextField
        className="phone-number-field"
        autoComplete="off"
        name="phoneNumber"
        label="Phone Number *"
        fullWidth
        error={!!errors.phoneNumber}
        helperText={errors.phoneNumber?.message ?? ""}
        disabled
        defaultValue={user.phoneNumber}
        sx={{ margin: 0 }}
        {...register("phoneNumber", {
          required: { value: true, message: "This field is required." },
          validate: (v) => isValidPhone,
        })}
      />

      <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <LoadingButton
          loading={state.loading}
          type="submit"
          variant="outlined"
          size="large"
          sx={{ flexShrink: 0 }}
        >
          {submitButtonText || "Update"}
        </LoadingButton>

        <Typography component="p" variant="body2" sx={{ color: "#8d8d8d" }}>
          {state.message || state.error}
        </Typography>
      </Box>
    </Box>
  );

  async function onProfileFormSubmit(data) {
    setState({ loading: true, message: "", error: "" });

    try {
      let [firstName, ...lastName] = data.name.split(" ");
      lastName = lastName.join(" ");

      const { data: updatedUser } = await updateUser(user.id, {
        firstName,
        lastName,
      });
      setState({ loading: false, message: "Update successful", error: "" });
      setTimeout(() => setState((s) => ({ ...s, message: "" })), 2000);
      onUserUpdated(updatedUser);
    } catch (e) {
      setState({
        loading: false,
        message: "",
        error: `Update failed: ${e.response?.data?.error ?? e.message}`,
      });
    }
  }
}
