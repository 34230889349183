import { Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import React from "react";

import WeddingMediaOverlay from "../WeddingMediaOverlay/WeddingMediaOverlay";

export default function WeddingVideoCard({ video, onClick, isSelected }) {
  return (
    <Card
      className="wedding-video-card"
      sx={{
        position: "relative",
        borderRadius: 0,
        boxShadow: "none",
        "& .MuiCardContent-root": { padding: "0.25rem 0" },
        "& .MuiCardContent-root:last-child": { paddingBottom: "0rem" },
      }}
    >
      <CardMedia
        image={video.otherData.thumbnailUrl}
        sx={{
          height: { xs: "14rem", sm: "12rem" },
          border: { xs: 0, sm: "1px solid rgba(0,0,0,0.1)" },
          borderRadius: { xs: 0, sm: "1rem" },
          cursor: "pointer",
        }}
        onClick={() => onClick(video.id)}
      />

      {isSelected ? (
        <CardContent
          className="now-playing-overlay flex-center-content"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: { xs: "14rem", sm: "12rem" },
            backgroundColor: "grey.dark",
            opacity: 0.8,
            borderRadius: { xs: 0, sm: "1rem" },
            color: "white",
            cursor: "pointer",
            fontWeight: "bold",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <Typography variant="h5">Playing</Typography>
        </CardContent>
      ) : null}

      <CardContent sx={{ backgroundColor: "theme.grey.light" }}>
        <WeddingMediaOverlay
          media={video}
          sx={{
            display: "grid",
            gap: "0rem 0.25rem",
            gridTemplateColumns: "auto auto 1fr 1fr auto",
            gridTemplateRows: "auto auto",
            backgroundColor: "transparent",
            "& .wedding-media-control": { gridRow: 2, color: `primary.main` },
            "& .wedding-media-control.delete-button": { gridColumn: 5 },
            "& .wedding-media-metadata": { gridRow: 1, color: "grey.dark" },
            "& .wedding-media-metadata.added-by-statement": {
              gridColumn: "1 / 4",
              margin: 0,
              paddingLeft: "0.25rem",
            },
            "& .wedding-media-metadata.added-date": {
              gridColumn: "4 / 6",
              margin: 0,
              paddingRight: "0.25rem",
              textAlign: "right",
            },
          }}
        />
      </CardContent>
    </Card>
  );
}
