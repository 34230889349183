import _ from "lodash";
import React from "react";

import weddingMediaUploadStatsReducer from "@@reducers/mediaUploadStatsReducer";
import { getWeddingMediaUploadStats } from "@@services/wedding-media.service";

// The UploadStats object looks like this:
// ({
//   LATEST_UPLOAD_REQUEST_TIME: +new Date(),
//   // stats for wedding
//   weddingId_1: {
//     SENDING_IN_PROGRESS: {
//       uploadOpId_1: { sent: 0, total: 10 },
//       uploadOpId_2: { sent: 5, total: 9 },
//     },
//     latestMediaUploadRequestTime: +new Date(),
//     latestMediaUploadProcessTime: +new Date(),
//     nProcessed: 100,
//     nSuccess: 60,
//     nErrors: 40,
//     errorMediaAlreadyExists: 5,
//     errorMediaAlreadyExists__files: ["a.jpg", "b.jpeg"],
//   },
//   // stats for wedding
//   weddingId_2: {},
// });

export const WeddingMediaUploadStatsContext = React.createContext(null);
WeddingMediaUploadStatsContext.displayName = "WeddingMediaUploadStatsContext";

export const WeddingMediaUploadStatsDispatchContext = React.createContext(
  () => {}
);
WeddingMediaUploadStatsDispatchContext.displayName =
  "WeddingMediaUploadStatsDispatchContext";

export default function WeddingMediaUploadStatsContextsWrapper({ children }) {
  const [state, dispatch] = React.useReducer(
    weddingMediaUploadStatsReducer,
    {}
  );
  const statsRefreshIntervalRef = React.useRef(null);
  const refreshStats = React.useCallback(async () => {
    const data = await getWeddingMediaUploadStats();
    dispatch({ type: "REFRESH_STATS", data });
  }, []);

  // no need to keep pinging the backend if all uploads are complete
  React.useEffect(() => {
    const weddingUploadStats = Object.entries(state)
      .filter(([key]) => Number.isInteger(+key))
      .map(([, stats]) => stats);
    if (
      weddingUploadStats.every(
        (stats) =>
          stats.nProcessed === stats.nReceived &&
          _.isEmpty(stats.SENDING_IN_PROGRESS)
      )
    ) {
      clearInterval(statsRefreshIntervalRef.current);
    }
  }, [state]);

  // re-running this effect whenever state.LATEST...TIME changes
  //   allows us to restart the statsRefreshInterval if it was stopped earlier
  React.useEffect(() => {
    statsRefreshIntervalRef.current = setInterval(refreshStats, 5_000);
    return () => clearInterval(statsRefreshIntervalRef.current);
  }, [refreshStats, state.LATEST_UPLOAD_REQUEST_TIME]);

  return (
    <WeddingMediaUploadStatsContext.Provider value={state}>
      <WeddingMediaUploadStatsDispatchContext.Provider value={dispatch}>
        {children}
      </WeddingMediaUploadStatsDispatchContext.Provider>
    </WeddingMediaUploadStatsContext.Provider>
  );
}
