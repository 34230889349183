import { generateRandomString } from "@ack_inc/utils";
import MuiToggleButton from "@mui/material/ToggleButton";
import { useSnackbar } from "notistack";
import React from "react";

const ToggleButton = React.forwardRef(ToggleButton_);
ToggleButton.defaultProps = {
  defaultSelected: false,
  onSelectMessage: "",
  onDeselectMessage: "",
  onErrorMessage: "",
};
export default ToggleButton;

function ToggleButton_(
  {
    defaultSelected,
    onChange,
    onSelectMessage,
    onDeselectMessage,
    onErrorMessage,
    ...restProps
  },
  ref
) {
  const [selected, setSelected] = React.useState(defaultSelected);

  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const lastNotifIdRef = React.useRef(null);

  const handleChange = async (event, value) => {
    const newVal = !selected;
    setSelected(newVal);

    if (lastNotifIdRef.current) closeSnackbar(lastNotifIdRef.current);

    try {
      await onChange(event, value);

      const msgToShow = newVal ? onSelectMessage : onDeselectMessage;
      if (msgToShow) {
        lastNotifIdRef.current = generateRandomString(12);
        enqueueSnackbar(msgToShow, {
          variant: "info",
          key: lastNotifIdRef.current,
        });
      }
    } catch (e) {
      const oldVal = selected;
      setSelected(oldVal);
      if (onErrorMessage) {
        enqueueSnackbar(onErrorMessage, { variant: "error" });
      }
      throw e;
    }
  };

  return (
    <MuiToggleButton
      ref={ref}
      selected={selected}
      onChange={handleChange}
      {...restProps}
    />
  );
}
