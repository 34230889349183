import cx from "classnames";
import React from "react";

import Drawer from "@@components/common/Drawer";
import {
  WeddingPageMediaFiltersDrawerOpenContext,
  WeddingPageModalsStateSettersContext,
} from "@@contexts/WeddingPageLevelModalContexts";
import MediaFilters from "./MediaFilters";

export default function MediaFiltersDrawer({
  className,
  MediaFiltersProps,
  ...restProps
}) {
  const open = React.useContext(WeddingPageMediaFiltersDrawerOpenContext);
  const { setMediaFiltersDrawerOpen } = React.useContext(
    WeddingPageModalsStateSettersContext
  );
  const onClose = () => setMediaFiltersDrawerOpen(false);

  return (
    <Drawer
      className={cx(className, "media-filters-drawer")}
      anchor="left"
      open={open}
      onClose={onClose}
      {...restProps}
    >
      <MediaFilters {...MediaFiltersProps} />
    </Drawer>
  );
}

MediaFiltersDrawer.defaultProps = {
  MediaFiltersProps: {},
};
