import ContactsIcon from "@mui/icons-material/Contacts";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import { phone } from "phone";
import React from "react";

import FotobotWhatsappLink from "../FotobotWhatsappLink";
import CustomModal from "@@components/common/CustomModal";
import { CurrentWeddingContext } from "@@contexts/CurrentWeddingContexts";
import { LoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";
import { addUsersToWedding } from "@@services/fotobot-api.service";

export default function AddGuestsModal({ open, onClose, ...restProps }) {
  const { enqueueSnackbar } = useSnackbar();
  const wedding = React.useContext(CurrentWeddingContext);
  const loggedInUser = React.useContext(LoggedInUserContext);
  const loggedInUserPhoneCountryCode =
    phone(loggedInUser?.phoneNumber).countryCode ?? "";

  const [loading, setLoading] = React.useState(false);

  const contactPickerApiIsSupported =
    "contacts" in navigator && "ContactsManager" in window;

  return (
    <CustomModal open={open} onClose={onClose} {...restProps}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
        <Typography component="h1" variant="h5">
          Add guests
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.25rem" }}>
          <LoadingButton
            variant="contained"
            sx={{ fontSize: "0.8rem", padding: "0.5rem 1rem" }}
            disabled={!contactPickerApiIsSupported}
            startIcon={<ContactsIcon />}
            loading={loading}
            onClick={async () => {
              let contacts;
              try {
                contacts = await navigator.contacts.select(["name", "tel"], {
                  multiple: true,
                });

                contacts = contacts
                  .map((c) => ({
                    ...c,
                    tel: c.tel
                      .map((pnum) =>
                        pnum.startsWith("+")
                          ? pnum
                          : `${loggedInUserPhoneCountryCode}${pnum}`
                      )
                      .map(phone)
                      .filter(({ isValid }) => isValid)
                      .map(({ phoneNumber }) => phoneNumber),
                  }))
                  .filter((c) => c.name.length > 0 && c.tel.length > 0)
                  .map((c) => ({
                    name: c.name[0],
                    phoneNumber: c.tel[0],
                  }));

                if (contacts.length === 0) {
                  enqueueSnackbar(
                    `Please select at least one contact with a name and mobile number`,
                    { variant: "info" }
                  );
                  return;
                }
              } catch (e) {
                enqueueSnackbar(`An error occurred`, { variant: "error" });
                throw e;
              }

              try {
                setLoading(true);
                const { data } = await addUsersToWedding(wedding.id, {
                  users: contacts,
                });
                enqueueSnackbar(
                  `Added ${data.added} guest(s)!${
                    data.alreadyPresent > 0
                      ? " The remaining were already present."
                      : ""
                  }`,
                  { variant: "success" }
                );
              } catch (e) {
                if (e.response?.status < 500) {
                  enqueueSnackbar(`Error: ${e.response.data.error}`, {
                    variant: "error",
                  });
                } else {
                  enqueueSnackbar(`An error occurred`, { variant: "error" });
                }

                throw e;
              } finally {
                setLoading(false);
              }
            }}
          >
            Select from phone contacts
          </LoadingButton>
          {!contactPickerApiIsSupported ? (
            <Typography
              variant="caption"
              color="error"
              sx={{ textAlign: "center" }}
            >
              Not supported in this browser
            </Typography>
          ) : null}
        </Box>

        <Box
          sx={{
            display: contactPickerApiIsSupported ? "none" : "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Divider sx={{ flexGrow: 1 }} />
          <Typography variant="caption">OR</Typography>
          <Divider sx={{ flexGrow: 1 }} />
        </Box>

        <Box
          sx={{
            display: contactPickerApiIsSupported ? "none" : "flex",
            flexDirection: "column",
            gap: "0.25rem",
          }}
        >
          <FotobotWhatsappLink wedding={wedding}>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<WhatsAppIcon />}
              sx={{ fontSize: "0.8rem", padding: "0.5rem 1rem" }}
            >
              Share contacts on whatsapp
            </Button>
          </FotobotWhatsappLink>

          <Typography variant="caption" color="grey">
            We will redirect you to a whatsapp chat with Fotobot.
          </Typography>

          <Typography variant="caption" color="grey">
            Any contacts you share after sending the pre-filled message will be
            added as guests to this wedding.
          </Typography>
        </Box>
      </Box>
    </CustomModal>
  );
}
