import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import React from "react";

import { splitBy } from "@@utils";

import UploadProgress from "@@components/common/UploadProgress";

export default function WeddingCard({ wedding }) {
  const theme = useTheme();

  // separate events within the wedding from other events, like engagement
  const eventGroups = splitBy(
    wedding.weddingEvents.sort((a, b) => new Date(a.date) - new Date(b.date)),
    (a, b) => dayjs(b.date).diff(dayjs(a.date), "day") > 7
  );
  const eventGroupsDatesAndNames = eventGroups.map((events) => {
    const eventGroupName = events
      .map(({ name }) => name.toLowerCase())
      .includes("wedding")
      ? "Wedding"
      : events[0].name;
    const startDate = dayjs(events[0].date);
    const endDate = dayjs(events[events.length - 1].date);

    return {
      name: eventGroupName,
      dates: startDate.isSame(endDate, "day")
        ? endDate.format(
            `D MMM${dayjs().isSame(endDate, "year") ? "" : ", YYYY"}`
          )
        : `${startDate.format(
            `D${startDate.isSame(endDate, "month") ? "" : " MMM"}`
          )} - ${endDate.format(
            `D MMM${dayjs().isSame(endDate, "year") ? "" : ", YYYY"}`
          )}`,
    };
  });

  return (
    <ButtonBase
      className="wedding-card"
      sx={{
        position: "relative",
        width: "100%",
        height: { xs: "14rem", sm: "18rem" },
        background: wedding.coverPhotoUrl
          ? `0 0 / cover url(${wedding.coverPhotoUrl})`
          : "none",
        color: "white",
        margin: "0 auto",
        ":hover": {
          // couldn't use the 'overlay' class because that adds
          //   a `position: absolute`, and I don't want that
          "& .black-overlay": { opacity: 0.15 },
        },
      }}
    >
      <Box
        className="cover-parent black-overlay"
        sx={{
          backgroundColor: "black",
          opacity: wedding.coverPhotoUrl ? 0.4 : 0.25,
          transition: "opacity 250ms",
        }}
      />

      <UploadProgress
        weddingId={wedding.id}
        variant="minimal"
        sx={{
          position: "absolute",
          top: "0.375rem",
          right: "0.375rem",
          display: "flex",
          backgroundColor: theme.palette.grey.light,
          borderRadius: "1rem",
        }}
      />

      <Box
        className="abs-centered wedding-details"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          className="wedding-name"
          variant="h1"
          sx={{
            borderBottom: "3px solid white",
            fontSize: "2.5rem",
            padding: "0.5rem 0",
            marginBottom: "0.75rem",
          }}
        >
          {wedding.name}
        </Typography>

        {eventGroupsDatesAndNames.length > 0 ? (
          eventGroupsDatesAndNames.map(({ name, dates }) => (
            <Typography
              key={name}
              className="wedding-dates"
              variant="subtitle2"
              component="h2"
            >
              {dates} ({name})
            </Typography>
          ))
        ) : (
          <Typography
            className="wedding-dates"
            variant="subtitle2"
            component="h2"
          >
            Details TBD
          </Typography>
        )}
      </Box>
    </ButtonBase>
  );
}
